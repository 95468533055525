export namespace CodeEnvModel {
    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$StandardPythonInterpreter
     */
    export enum StandardPythonInterpreter {
        PYTHON27 = 'PYTHON27',
        PYTHON34 = 'PYTHON34',
        PYTHON35 = 'PYTHON35',
        PYTHON36 = 'PYTHON36',
        PYTHON37 = 'PYTHON37',
        PYTHON38 = 'PYTHON38',
        PYTHON39 = 'PYTHON39',
        PYTHON310 = 'PYTHON310',
        CUSTOM = 'CUSTOM'
    }

    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$UsedCodeEnvRef
     */
    export interface UsedCodeEnvRef {
        envLang: CodeEnvModel.EnvLang;
        envName: string;
    }

    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$EnvLang
     */
    export enum EnvLang {
        PYTHON = 'python',
        R = 'R',
        JULIA = 'julia'
    }

    /**
     * Generated from com.dataiku.dip.code.CodeEnvModel$EnvImportSpecificationMode
     */
    export enum EnvImportSpecificationMode {
        SPECIFIED = 'SPECIFIED',
        ACTUAL = 'ACTUAL'
    }
}