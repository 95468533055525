<header class="workspace-header">

    <workspace-icon class="workspace-header__icon" [name]="workspace.displayName" [color]="workspace.color" [size]="64"></workspace-icon>

    <div class="workspace-header__metadata">
        <!-- Name -->
        <section class="workspace-header__top">
            <span
                class="workspace-header__name"
                [textContent]="workspace.displayName">
            </span>

            <span *ngIf="workspace.currentUserPermissions.admin" class="workspace-header__top-actions-btns">
                <a class="dropdown-toggle" [matMenuTriggerFor]="actionMenu">
                    <i class="workspace-header__edit dku-ellipsis-vertical dku-ellipsis-vertical--circle-on-hover"></i>
                </a>

                <mat-menu #actionMenu>
                    <ng-template matMenuContent>
                        <button mat-menu-item (click)="openEditWorkspaceModal()">
                            <i class="workspace-header__top-actions-icon icon-pencil"></i>Edit
                        </button>
                        <button mat-menu-item (click)="openDeleteWorkspaceModal()" class="text-error">
                            <i class="workspace-header__top-actions-icon icon-trash"></i>Delete
                        </button>
                    </ng-template>
                </mat-menu>
            </span>

        </section>

        <!-- Members -->
        <div class="workspace-header__user-badge"
            toggle="tooltip"
            title="Click to see workspace users"
            (click)="openMembersModal()">
            <i *ngIf="hasGroups" class="icon-group"></i>
            <user-picture class="workspace-header__user-badge-avatar" *ngFor="let avatar of avatars" [user]="avatar" [size]="24"></user-picture>
            <span class="mleft4 dku-text-sb" [textContent]="workspace.permissions.length"></span>
        </div>

        <!-- Description -->
        <section #description class="workspace-header__description-container" 
            [class]="{
                'workspace-header__description-container--expanded': (isExpanded$ | async),
                'workspace-header__description-container--can-expand': (canExpand$ | async) && (isExpanded$ | async) === false
            }">
            <markdown
                class="workspace-header__description-content markdown-std"
                [from]="workspace.description || 'No description for this workspace'"
            ></markdown>
        </section>
        <div class="workspace-header__description-show-action">
            <button *ngIf="(canExpand$ | async)" class="btn btn--text btn--primary" (click)="setExpanded(!isExpanded$.value)" >
                {{ (isExpanded$ | async) === true ? 'Show Less' : 'Show More' }}
            </button>
        </div>

    </div>
</header>
