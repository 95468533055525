import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, Inject, OnInit, ViewChild, Type } from '@angular/core';
import { APIError } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { ITaggingService } from '@model-main/server/services/itagging-service';
import { ProjectsService } from '@model-main/server/services/projects-service';
import { Workspace } from '@model-main/workspaces/workspace';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProjectSummary } from '@shared/models';
import { Observable, BehaviorSubject, combineLatest, of, throwError } from 'rxjs';
import { distinct, distinctUntilChanged, map, tap, switchMap, catchError } from 'rxjs/operators';
import { DKURootScope } from '../../../shared';
import {
    ObjectViewerHostDirective,
    ObjectViewer,
    InsightViewer,
    DashboardViewerComponent,
    DashboardInsightViewerComponent,
    DatasetViewerComponent,
    ArticleViewerComponent,
    WebAppViewerComponent,
    AppViewerComponent,
    ObjectViewerOptions,
    ObjectViewerInputs,
    DetailedObjectViewerOutputs
} from './shared';

@UntilDestroy()
@Component({
    selector: 'workspace-object-viewer',
    templateUrl: './workspace-object-viewer.component.html',
    styleUrls: ['./workspace-object-viewer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceObjectViewerComponent implements OnInit {
    private ERRORS = {
        MISSING_REFERENCE: 'Please provide a reference.',
        MISSING_REFERENCE_OR_INSIGHT: 'Expected either an insight id or an object reference.',
        MISSING_OBJECT: 'Please define at least an object.'
    };

    workspace$ = new BehaviorSubject<Workspace | null | undefined>(undefined);
    object$ = new BehaviorSubject<Workspace.WorkspaceObject | null | undefined>(undefined);
    project$ = new BehaviorSubject<ProjectsService.UIProject | undefined>(undefined);
    insightId$ = new BehaviorSubject<string | null | undefined>(undefined);

    showRightPanel$ = new BehaviorSubject<boolean>(false);
    editable$ = new BehaviorSubject<boolean>(false);

    @ViewChild(ObjectViewerHostDirective, { static: true }) objectViewerHost: ObjectViewerHostDirective;

    @Input()
    set workspace(value: Workspace | null | undefined) {
        this.workspace$.next(value);
    }

    @Input()
    set object(value: Workspace.WorkspaceObject | null | undefined) {
        this.object$.next(value);
    }

    @Input()
    set insightId(value: string | null | undefined) {
        this.insightId$.next(value);
    }

    @Output() fail = new EventEmitter<APIError>();

    constructor(
        @Inject('$rootScope') private $rootScope: DKURootScope,
        private dataikuApiService: DataikuAPIService
    ) { }

    private handleError(err: APIError) {
        this.fail.next(err);
    }

    private getInsightType(type?: ITaggingService.TaggableType): string | undefined {
        if (type) {
            const insightType = type.toLowerCase();
            if (insightType === 'dataset') {
                return `${insightType}_table`;
            }
            return insightType;
        }

        return;
    }

    private getProjectSummary(projectKey: string): Observable<ProjectSummary> {
        return this.dataikuApiService.projects.getSummary(projectKey)
            .pipe(
                catchError((err: APIError) => {
                    this.handleError(err);
                    return throwError(() => err);
                })
            );
    }

    private loadProject(object: Workspace.WorkspaceObject): Observable<ProjectsService.UIProject> {
        if (!object.reference) {
            return throwError(() => ({ message: this.ERRORS.MISSING_REFERENCE }));
        }

        return this.getProjectSummary(object.reference.projectKey)
            .pipe(
                map(data => data.object),
                tap(project => {
                    const editable = object.reference?.type === 'DASHBOARD' ? project.canWriteDashboards : project.canWriteProjectContent;

                    this.project$.next(project);
                    this.editable$.next(editable);
                    this.showRightPanel$.next(true);

                    this.$rootScope.projectSummary = project;
                    this.$rootScope.topNav.isProjectAnalystRO = !editable;
                    this.$rootScope.topNav.isProjectAnalystRW = editable;
                    this.$rootScope.topNav.isCurrentProjectAdmin = project.isProjectAdmin;
                })
            );
    }

    private loadObjectComponent(
        component: Type<ObjectViewer>,
        inputs?: ObjectViewerInputs
    ) {
        const outputs: DetailedObjectViewerOutputs = {
            fail: (error: APIError) => this.fail.next(error)
        };

        this.objectViewerHost.createComponent(
            component,
            inputs,
            outputs
        );
    }

    private loadInsightComponent(
        component: Type<InsightViewer>,
        inputs?: ObjectViewerInputs
    ) {
        this.$rootScope.topNav.isProjectAnalystRO = false;
        this.loadObjectComponent(component, inputs);
    }

    private initProject(object: Workspace.WorkspaceObject, workspace: Workspace, insightId?: string | null): Observable<Workspace.WorkspaceObject> {
        return this.loadProject(object)
            .pipe(
                switchMap(project => {
                    if (!object?.reference) {
                        return throwError(() => ({ message: this.ERRORS.MISSING_REFERENCE }));
                    }

                    const objectInputs: {options: ObjectViewerOptions} = {
                        options: {
                            id: object.reference.id,
                            name: object.displayName,
                            projectKey: object.reference.projectKey,
                            type: this.getInsightType(object.reference.type)
                        }
                    };

                    switch(object.reference.type) {
                        case ITaggingService.TaggableType.DASHBOARD:
                            if (insightId) {
                                this.showRightPanel$.next(false);
                                this.objectViewerHost.createComponent(DashboardInsightViewerComponent);
                            } else {
                                this.loadObjectComponent(
                                    DashboardViewerComponent,
                                    {
                                        ...objectInputs,
                                        appConfig: this.$rootScope.appConfig,
                                        project,
                                        insightId
                                    }
                                );
                            }
                            break;
                        case ITaggingService.TaggableType.DATASET:
                            this.loadInsightComponent(
                                DatasetViewerComponent,
                                {
                                    ...objectInputs,
                                    appConfig: this.$rootScope.appConfig,
                                    workspaceKey: workspace.workspaceKey,
                                    projectKey: project.projectKey,
                                    object
                                }
                            );
                            break;
                        case ITaggingService.TaggableType.ARTICLE:
                            this.loadInsightComponent(
                                ArticleViewerComponent,
                                objectInputs
                            );
                            break;
                        case ITaggingService.TaggableType.WEB_APP:
                            this.loadInsightComponent(
                                WebAppViewerComponent,
                                objectInputs
                            );
                            break;
                        default:
                            return throwError(() => ({ message: `Unsupported type ${this.getInsightType(object.reference?.type) || ''}` }));
                    }

                    return of(object);
                })
            );
    }

    private initApp(object: Workspace.WorkspaceObject): Observable<Workspace.WorkspaceObject> {
        this.objectViewerHost.createComponent(AppViewerComponent);
        this.showRightPanel$.next(true);
        return of(object);
    }

    ngOnInit(): void {
        combineLatest([
            this.object$.pipe(distinct(object => object?.appId || object?.reference?.id)),
            this.workspace$.pipe(distinct(workspace => workspace?.workspaceKey)),
            this.insightId$.pipe(distinctUntilChanged())
        ]).pipe(
            switchMap(([object, workspace, insightId]) => {
                if (object && workspace) {
                    if (object.appId) {
                        return this.initApp(object);
                    } else {
                        return this.initProject(object, workspace, insightId);
                    }
                }

                return of(object);
            }),
            untilDestroyed(this)
        ).subscribe();
    }
}
