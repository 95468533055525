import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, ContentChild, Directive, ElementRef, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { NgOptgroupTemplateDirective, NgOption, NgOptionTemplateDirective, NgSelectComponent } from "@ng-select/ng-select";
import { NgControl } from '@angular/forms';
import { debounceTime, EMPTY, filter, fromEvent, merge, Observable, Subject } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { WindowService } from "dku-frontend-core";
import _ from "lodash";
import { DOCUMENT } from "@angular/common";
import { HostBinding } from "@angular/core";

export enum ControlKeyCodes {
    Tab = 9,
    Enter = 13,
    Esc = 27,
    Space = 32,
    ArrowUp = 38,
    ArrowDown = 40,
    Backspace = 8
}

interface PatchedHTMLElement extends HTMLElement {
    __NG_SELECT_MONKEY_PATCH__: boolean;
}

/**
 * Monkey-patch the <ng-select> to work around two issues:
 * - Position of the dropdown does not always follow the position of the trigger. The patch relies on heuristic to reposition the dropdown when certain events are triggered (window resized, selection changed, ...)
 * - Auto-adjust the width of the dropdown. The patch relies on an heuristic to determine the potentially largest item and simulate a rendering to determine its width.
 * - Prevent propagation of [Esc] keyboard events to prevent surrounding modals from being closed when the user only wants to hide the menu.
 */
@UntilDestroy()
@Directive({
    selector: 'ng-select' // Target the <ng-select> component directly
})
export class NgSelectMonkeyPatchDirective implements OnChanges, OnInit, OnDestroy {
    // Watch the same @Input() as the underlying <ng-select>
    @Input() virtualScroll = false;
    @Input() items: (object | string)[] | null;
    @Input() groupBy: string | ((value: any) => any);
    @Input() bindLabel: string | null = null;

    // Enable a monkey-patch to reposition the menu on some events (page resize, etc)
    @Input() dkuAdjustPosition = true;

    // Enable a monkey-patch to determine the width of the dropdown based on the largest item
    @Input() dkuAdjustWidth = true;

    // Enable a monkey-patch to avoid [Esc] keydown/keyup events from bubbling out <ng-select> when it is opened
    @Input() dkuKeyboardEventStopPropagation = true;

    // Enable a monkey-patch to disable the default action of click events escaping <ng-select>
    // (this allows <ng-select-search-input> to capture the focus properly, even if <ng-select> is inside a <label>)
    @Input() dkuClickEventPreventDefault = true;

    // Option template (if custom template is provided)
    @ContentChild(NgOptionTemplateDirective, { read: TemplateRef }) optionTemplate: TemplateRef<unknown> | undefined;

    // Group template (if custom template is provided)
    @ContentChild(NgOptgroupTemplateDirective, { read: TemplateRef }) optgroupTemplate: TemplateRef<unknown> | undefined;

    // Fired when ngOnChanges() is called
    inputChange$ = new Subject<void>();

    // Fired when this work around needs to be re-applied
    adjustTrigger$: Observable<unknown>;

    // The largest item in the list (approximation based on length of labels)
    largestItem: NgOption | undefined;

    // The simulator component used to render the provided option template
    simulator: ComponentRef<NgSelectPanelSimulatorComponent>;

    upcomingEscKeyUp: boolean;

    constructor(
        private ngSelect: NgSelectComponent,
        private ngControl: NgControl,
        private element: ElementRef,
        private windowService: WindowService,
        private viewContainerRef: ViewContainerRef,
        @Inject(DOCUMENT) private document: Document
    ) {
        // Re-run the monkey patch every time "something has changed". This is an heuristic, we might need to listen to additional events in the future.
        this.adjustTrigger$ = merge(
            this.windowService.resize$,
            this.ngControl.valueChanges || EMPTY,
            this.inputChange$,
            this.ngSelect.openEvent
        ).pipe(debounceTime(10));
    }

    @HostListener('click', ['$event']) onClick(event: Event) {
        if (this.dkuClickEventPreventDefault) {
            event.preventDefault();
        }
    }

    ngOnInit() {
        this.adjustTrigger$.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.dkuAdjustPosition) {
                this.adjustDropdownPosition();
            }
            if (this.dkuAdjustWidth) {
                this.adjustDropdownWidth();
            }
        });

        this.setupEscapeKeyHandler();

        // Create a simulator that re-create a DOM structure similar to the "ng-dropdown-panel-items" of <ng-select>'s dropdown panel
        this.simulator = this.viewContainerRef.createComponent(NgSelectPanelSimulatorComponent);

        // Move the simulator to the <body> element to avoid cluttering local DOM
        this.document.body.appendChild(this.simulator.location.nativeElement);
    }

    // Monkey-patch native keyboard handling of <ng-select> so that we can prevent [Esc] keyboard events to propagate out of <ng-select> when they shouldn't
    setupEscapeKeyHandler() {
        // Listen 'keydown' events in capture phase to make sure we catch them before <ng-select>
        // (otherwise it is impossible to tell whether the menu was opened or closed when the event is caught here, because <ng-select> would have reacted already)
        const keydown$ = fromEvent<KeyboardEvent>(this.element.nativeElement, 'keydown', { capture: true });

        // While 'keyup' is not used by <ng-select>, DSS uses it to close modals and we also need to stop its propagation (e.g. if the <ng-select> menu was opened inside a modal)
        const keyup$ = fromEvent<KeyboardEvent>(this.element.nativeElement, 'keyup', { capture: true });

        // Dealing with 'keyup' is trickier because when this event is fired, one or several 'keydown' events have already been dispatched and the menu has already been closed at this point.
        // This flag helps remembering whether the menu was opened during a previous 'keydown' event, so that we can intercept the next 'keyup' event and stop its propagation.
        this.upcomingEscKeyUp = false;

        // Note that 'keypress' events are not handled (not needed at the moment - but can be done if needed)
        const escapeKeyEvents$ = merge(keydown$, keyup$).pipe(filter(event => event.which === ControlKeyCodes.Esc));

        escapeKeyEvents$.pipe(untilDestroyed(this)).subscribe(event => {
            const isMenuConsideredOpened = this.ngSelect.isOpen || this.upcomingEscKeyUp;

            if (this.ngSelect.isOpen && event.type === 'keydown') {
                this.upcomingEscKeyUp = true;
            }

            if (this.upcomingEscKeyUp && event.type === 'keyup') {
                this.upcomingEscKeyUp = false;
            }

            if (this.dkuKeyboardEventStopPropagation && isMenuConsideredOpened) {
                // Stop event propagation if the menu was opened
                event.stopPropagation();

                // Close the menu ourselves since <ng-select> won't see the event
                this.ngSelect.close();
            }
        });
    }

    // Dispatch a fake event to <ng-select> directly, without going through DOM
    // It is used to forward events from our custom search input <ng-select-search-input>
    dispatchSyntheticKeyboardEvent(event: KeyboardEvent) {
        if (event.type === 'keydown' && event.which === ControlKeyCodes.Esc && this.ngSelect.isOpen) {
            // Story:
            // -> 'ESC keydown' fired in the dropdown is redirected here
            // -> Event is forwarded to ngSelect.handleKeyDown()
            // -> Menu closes itself
            // -> Closing the menu moves the focus back to <ng-select>
            // -> A 'keyup' event will fire on <ng-select> when the [Esc] key is released
            // -> We need to prevent it from propagating (to prevent modal from being closed)
            this.upcomingEscKeyUp = true;
        }
        this.ngSelect.handleKeyDown(event);
    }

    adjustDropdownPosition() {
        this.ngSelect.dropdownPanel?.adjustPosition();
    }

    adjustDropdownWidth() {
        if (!this.virtualScroll) {
            // The width does not need to be adjusted when there is no virtual scroll since all options are present in the DOM
            return;
        }

        // Find the dropdown element (WARNING: this relies on <ng-select> implementation details and might break on updates)
        const dropdownElement = (this.ngSelect.dropdownPanel?.contentElementRef as ElementRef<HTMLElement>)
            ?.nativeElement.parentElement?.parentElement as HTMLElement | undefined;
        if (!dropdownElement) {
            // Dropdown is not on the page: there is no point trying to resize
            return;
        }

        // Find the largest item (approximation based on length of labels)
        // This can be either a regular item or a group item
        this.largestItem = _.maxBy(this.ngSelect.itemsList.items, (item) => item.label?.length ?? 0);

        // Render the option template in the simulator
        this.simulator.instance.item = this.largestItem;
        this.simulator.instance.optgroupTemplate = this.optgroupTemplate;
        this.simulator.instance.optionTemplate = this.optionTemplate;
        this.simulator.instance.bindLabel = this.bindLabel;
        this.simulator.instance.changeDetectorRef.detectChanges();

        // Clear the previously copied sizer element
        const injectedElement = dropdownElement.lastChild as PatchedHTMLElement | undefined;
        if (injectedElement && injectedElement?.__NG_SELECT_MONKEY_PATCH__) {
            injectedElement.remove();
        }

        // Copy the rendered option item to the dropdown. It will act as a sizer element and will grow menu's width if needed
        const renderedOption = this.simulator.instance.sizerElement.nativeElement.cloneNode(true) as PatchedHTMLElement;
        renderedOption.__NG_SELECT_MONKEY_PATCH__ = true;
        dropdownElement.appendChild(renderedOption.cloneNode(true));
    }

    ngOnChanges() {
        this.inputChange$.next();
    }

    ngOnDestroy() {
        this.simulator.destroy();
    }
}


@Component({
    selector: 'ng-select-panel-simulator',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        /*
            Ensure the option rendering simulator is never displayed
            Specifying the tag name (ng-select-panel-simulator) is needed to ensure the specifity is larger than '.ng-dropdown-panel'
        */
        ng-select-panel-simulator:host {
            display: none !important;
        }
        /* The sizer will grow according to its content + margin, but its height is always 0px */
        .ng-select-invisible-sizer {
            height: 0px !important;
            overflow: hidden;
            margin-right: 10px;
        }
    `],
    template: `
    <div class="ng-select-invisible-sizer ng-dropdown-panel-items" #sizerElement>
        <div *ngIf="item" [class.ng-optgroup]="item.children" [class.ng-option]="!item.children">
            <ng-template #defaultOptionTemplate>
                <!-- Default template used when no <ng-template ng-option-tmp> is provided -->
                <span class="ng-option-label" *ngIf="item">{{ item.label }}</span>
            </ng-template>
            <ng-template
                *ngIf="item"
                [ngTemplateOutlet]="item.children ? (optgroupTemplate || defaultOptionTemplate) : (optionTemplate || defaultOptionTemplate)"
                [ngTemplateOutletContext]="{ item: item.value, item$:item, index: 0, searchTerm: '' }">
                <!--
                    Render user-defined template
                    See https://github.com/ng-select/ng-select/blob/master/src/ng-select/lib/ng-select.component.html
                -->
            </ng-template>
        </div>
    </div>
    `
})
export class NgSelectPanelSimulatorComponent {
    @Input() optionTemplate: TemplateRef<unknown> | undefined;
    @Input() bindLabel: string | null = null;
    @Input() item: NgOption | undefined;
    @Input() optgroupTemplate: TemplateRef<unknown> | undefined;
    @ViewChild('sizerElement') sizerElement: ElementRef<HTMLDivElement>;
    @HostBinding('attr.class') classList = 'ng-dropdown-panel';

    constructor(public changeDetectorRef: ChangeDetectorRef) { }
}
