import { Injectable } from "@angular/core";
import { UIAnnotation } from "@features/labeling/models/annotation";
import { UILabel } from "@features/labeling/models/label";
import { AnnotationFactory } from "@features/labeling/services/annotation.factory";
import { ReviewStatus } from "@features/labeling/services/labeling-review-data-fetcher.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { cloneDeep } from "lodash";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from "rxjs";
import { distinctUntilChanged, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ColorMeaning, LabelingColorService } from "../../services/labeling-color.service";
import { LabelingReviewAnnotationGroupService } from "./labeling-review-annotation-group.service";
import { LabelingReviewImagePathService } from "./labeling-review-image-path.service";

@UntilDestroy()
@Injectable()
export class LabelingReviewUpdateService {
    currentReview$ = new ReplaySubject<UILabel>(1);
    fetchedReview$ = new ReplaySubject<UILabel>(1);
    updatingReview$ = new BehaviorSubject<boolean>(false);
    resetReviewTrigger$ = new Subject<void>();
    isDirty$: Observable<boolean>;
    reviewStatus$: Observable<ReviewStatus>;

    constructor(
        private labelingReviewImagePathService: LabelingReviewImagePathService, 
        private labelingColorService: LabelingColorService,
        private annotationFactory: AnnotationFactory,
        private labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService
    ) {
        this.labelingReviewImagePathService.currentReviewRecord$.pipe(
            untilDestroyed(this)
        ).subscribe((record) => {
            if (record) {
                const answer = record.verifiedAnswer || null;
                const review = this.annotationFactory.fromAnswer(answer);
                this.currentReview$.next(review);
                this.fetchedReview$.next(cloneDeep(review));
                this.updatingReview$.next(answer !== null);
                if (answer !== null) {
                    this.labelingColorService.setCurrentColorMeaning(ColorMeaning.CLASS);
                }
            }
        });

        this.reviewStatus$ = this.updatingReview$.pipe(
            switchMap(updatingReview => {
                if (updatingReview) {
                    return of(ReviewStatus.VALIDATED);
                }

                return this.labelingReviewAnnotationGroupService.isConflicting$.pipe(
                    map(isConflicting => isConflicting ? ReviewStatus.CONFLICTING : ReviewStatus.CONSENSUS)
                )
            })
        );

        this.isDirty$ = combineLatest([
            this.currentReview$,
            this.fetchedReview$
        ]).pipe(
            distinctUntilChanged(),
            map(([annotation, fetchedAnnotation]) => {
                return !annotation.equals(fetchedAnnotation);
            }),
        );
        
        this.resetReviewTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.fetchedReview$)
        ).subscribe(([_trigger, fetchedReview]) => {
            this.currentReview$.next(cloneDeep(fetchedReview));
        })
    }

    updateReview(annotations: UIAnnotation[]) {
        this.currentReview$.next(this.annotationFactory.fromAnnotations([...annotations]));
    }

    resetReview() {
        this.resetReviewTrigger$.next();
    }
}