import type {PT2DDensityRequest} from './density/pt2-ddensity-request';
import type {PTBoxplotsRequest} from './boxplots/ptboxplots-request';
import type {PTMapAggrAdminRequest} from './maps/ptmap-aggr-admin-request';
import type {PTMapAggrGridRequest} from './maps/ptmap-aggr-grid-request';
import type {PTMapRawGeometryRequest} from './maps/ptmap-raw-geometry-request';
import type {PTMapScatterRequest} from './maps/ptmap-scatter-request';
import type {PTScatterRequest} from './scatter/ptscatter-request';
import type {PivotTableFiltersRequest} from './maps/pivot-table-filters-request';
import type {PivotTableTensorRequest} from './pivot-table-tensor-request';
import type {PivotTableWebappRequest} from './maps/pivot-table-webapp-request';
import type {RowFilter} from './row-filter';
import type {SimpleAggregatedRequest} from './simple-aggregated-request';

/**
 * Generated from com.dataiku.dip.pivot.backend.model.PivotTableRequest
 */
export interface _PivotTableRequest {
    computeUsableColumns: boolean;
    filters: RowFilter[];
    maxDataBytes: number;
    maxDrawableD0Elements: number;
    maxDrawableTotalElements: number;
    type: PivotTableRequest.RequestType;
    useLiveProcessingIfAvailable: boolean;
}

export type PivotTableRequest = PivotTableTensorRequest | SimpleAggregatedRequest | PTBoxplotsRequest | PT2DDensityRequest | PTMapAggrAdminRequest | PTMapAggrGridRequest | PTMapRawGeometryRequest | PTMapScatterRequest | PivotTableFiltersRequest | PivotTableWebappRequest | PTScatterRequest;

export namespace PivotTableRequest {
    /**
     * Generated from com.dataiku.dip.pivot.backend.model.PivotTableRequest$RequestType
     */
    export enum RequestType {
        AGGREGATED_ND = 'AGGREGATED_ND',
        AGGREGATED_GEO_ADMIN = 'AGGREGATED_GEO_ADMIN',
        AGGREGATED_GEO_GRID = 'AGGREGATED_GEO_GRID',
        BOXPLOTS = 'BOXPLOTS',
        SCATTER_NON_AGGREGATED = 'SCATTER_NON_AGGREGATED',
        MAP_SCATTER_NON_AGGREGATED = 'MAP_SCATTER_NON_AGGREGATED',
        DENSITY_2D = 'DENSITY_2D',
        RAW_GEOMETRY = 'RAW_GEOMETRY',
        NO_PIVOT_AGGREGATED = 'NO_PIVOT_AGGREGATED',
        WEBAPP = 'WEBAPP',
        FILTERS = 'FILTERS'
    }
}