import type {ACF} from './timeseries/acf';
import type {AvailableResult} from './available-result';
import type {Chi2IndTest} from './bivariate/chi2-ind-test';
import type {CorrelationFunction} from './timeseries/correlation-function';
import type {CountDistinct} from './univariate/count-distinct';
import type {Count} from './common/count';
import type {Covariance} from './bivariate/covariance';
import type {DummyComputation} from './common/dummy-computation';
import type {DurbinWatson} from './timeseries/durbin-watson';
import type {EmpiricalCDF} from './univariate/empirical-cdf';
import type {Entropy} from './univariate/entropy';
import type {FailedResult} from './failed-result';
import type {FetchValues} from './multivariate/fetch-values';
import type {Fit2DDistribution} from './bivariate/fit2-ddistribution';
import type {FitCurve} from './bivariate/fit-curve';
import type {FitDistribution} from './univariate/fit-distribution';
import type {GroupedComputation} from './common/grouped-computation';
import type {GuessTimeStep} from './univariate/guess-time-step';
import type {KendallTau} from './bivariate/kendall-tau';
import type {KsTest2Samp} from './univariate/ks-test2-samp';
import type {Kurtosis} from './univariate/kurtosis';
import type {MannKendallTest} from './timeseries/mann-kendall-test';
import type {MatchTimeStep} from './univariate/match-time-step';
import type {MaxTime} from './univariate/max-time';
import type {Max} from './univariate/max';
import type {Mean} from './univariate/mean';
import type {MinTime} from './univariate/min-time';
import type {Min} from './univariate/min';
import type {MoodTestNSamp} from './univariate/mood-test-nsamp';
import type {MultiComputation} from './common/multi-computation';
import type {MutualInformation} from './bivariate/mutual-information';
import type {NotComputedResult} from './not-computed-result';
import type {OneWayAnova} from './univariate/one-way-anova';
import type {PACF} from './timeseries/pacf';
import type {PCA} from './multivariate/pca';
import type {PairwiseMoodTest} from './univariate/pairwise-mood-test';
import type {PairwiseTTest} from './univariate/pairwise-ttest';
import type {Pearson} from './bivariate/pearson';
import type {Quantiles} from './univariate/quantiles';
import type {ResampledComputation} from './common/resampled-computation';
import type {Sem} from './univariate/sem';
import type {Shapiro} from './univariate/shapiro';
import type {SignTest1Samp} from './univariate/sign-test1-samp';
import type {Skewness} from './univariate/skewness';
import type {Spearman} from './bivariate/spearman';
import type {StdDev} from './univariate/std-dev';
import type {Sum} from './univariate/sum';
import type {TTest1Samp} from './univariate/ttest1-samp';
import type {TestDistribution} from './univariate/test-distribution';
import type {UnavailableResult} from './unavailable-result';
import type {UnitRootTestADF} from './timeseries/unit-root-test-adf';
import type {UnitRootTestKPSS} from './timeseries/unit-root-test-kpss';
import type {UnitRootTestZA} from './timeseries/unit-root-test-za';
import type {UnitRootTest} from './timeseries/unit-root-test';
import type {Variance} from './univariate/variance';
import type {ZTest1Samp} from './univariate/ztest1-samp';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.ComputationResult
 */
export interface _ComputationResult {
    computationCounts: ComputationResult.ComputationCounts;
}

export type ComputationResult = FailedResult | NotComputedResult | Chi2IndTest.Chi2IndTestResult | Covariance.CovarianceResult | Fit2DDistribution.Fit2DDistributionResult | FitCurve.FitCurveResult | KendallTau.KendallTauResult | MutualInformation.MutualInformationResult | Pearson.PearsonResult | Spearman.SpearmanResult | Count.CountResult | DummyComputation.DummyComputationResult | GroupedComputation.GroupedComputationResult | MultiComputation.MultiComputationResult | ResampledComputation.ResampledComputationResult | FetchValues.FetchValuesResult | PCA.PCAResult | ACF.ACFResult | DurbinWatson.DurbinWatsonResult | MannKendallTest.MannKendallTestResult | PACF.PACFResult | UnitRootTestADF.UnitRootTestADFResult | UnitRootTestKPSS.UnitRootTestKPSSResult | UnitRootTestZA.UnitRootTestZAResult | CountDistinct.CountDistinctResult | EmpiricalCDF.EmpiricalCDFResult | Entropy.EntropyResult | FitDistribution.FitDistributionResult | GuessTimeStep.GuessTimeStepResult | KsTest2Samp.KsTest2SampResult | Kurtosis.KurtosisResult | MatchTimeStep.MatchTimeStepResult | Max.MaxResult | MaxTime.MaxTimeResult | Mean.MeanResult | Min.MinResult | MinTime.MinTimeResult | MoodTestNSamp.MoodTestNSampResult | OneWayAnova.OneWayAnovaResult | PairwiseMoodTest.PairwiseMoodTestResult | PairwiseTTest.PairwiseTTestResult | Quantiles.QuantilesResult | Sem.SemResult | Shapiro.ShapiroResult | SignTest1Samp.SignTest1SampResult | Skewness.SkewnessResult | StdDev.StdDevResult | Sum.SumResult | TTest1Samp.TTest1SampResult | TestDistribution.TestDistributionResult | Variance.VarianceResult | ZTest1Samp.ZTest1SampResult;

export namespace ComputationResult {
    export const type = ['failed', 'not_computed', 'chi2_ind_test', 'covariance', 'fit_2d_distribution', 'fit_curve', 'kendall_tau', 'mutual_information', 'pearson', 'spearman', 'count', 'dummy', 'grouped', 'multi', 'resampled', 'fetch_values', 'pca', 'acf', 'durbin_watson', 'mann_kendall', 'pacf', 'unit_root_adf', 'unit_root_kpss', 'unit_root_za', 'count_distinct', 'empirical_cdf', 'entropy', 'fit_distribution', 'guess_time_step', 'ks_test_2samp', 'kurtosis', 'match_time_step', 'max', 'max_time', 'mean', 'min', 'min_time', 'mood_test_nsamp', 'one_way_anova', 'pairwise_mood_test', 'pairwise_ttest', 'quantiles', 'sem', 'shapiro', 'sign_test_1samp', 'skewness', 'std_dev', 'sum', 'ttest_1samp', 'test_distribution', 'variance', 'ztest_1samp'] as const;
    export function isAvailableResult(toTest: ComputationResult): toTest is AvailableResult { return ['chi2_ind_test', 'covariance', 'fit_2d_distribution', 'fit_curve', 'kendall_tau', 'mutual_information', 'pearson', 'spearman', 'count', 'dummy', 'grouped', 'multi', 'resampled', 'fetch_values', 'pca', 'acf', 'durbin_watson', 'mann_kendall', 'pacf', 'unit_root_adf', 'unit_root_kpss', 'unit_root_za', 'count_distinct', 'empirical_cdf', 'entropy', 'fit_distribution', 'guess_time_step', 'ks_test_2samp', 'kurtosis', 'match_time_step', 'max', 'max_time', 'mean', 'min', 'min_time', 'mood_test_nsamp', 'one_way_anova', 'pairwise_mood_test', 'pairwise_ttest', 'quantiles', 'sem', 'shapiro', 'sign_test_1samp', 'skewness', 'std_dev', 'sum', 'ttest_1samp', 'test_distribution', 'variance', 'ztest_1samp'].includes(toTest['type']); }
    export function isFailedResult(toTest: ComputationResult): toTest is FailedResult { return toTest['type'] === 'failed'; }
    export function isNotComputedResult(toTest: ComputationResult): toTest is NotComputedResult { return toTest['type'] === 'not_computed'; }
    export function isUnavailableResult(toTest: ComputationResult): toTest is UnavailableResult { return ['failed', 'not_computed'].includes(toTest['type']); }
    export function isChi2IndTestResult(toTest: ComputationResult): toTest is Chi2IndTest.Chi2IndTestResult { return toTest['type'] === 'chi2_ind_test'; }
    export function isCovarianceResult(toTest: ComputationResult): toTest is Covariance.CovarianceResult { return toTest['type'] === 'covariance'; }
    export function isFit2DDistributionResult(toTest: ComputationResult): toTest is Fit2DDistribution.Fit2DDistributionResult { return toTest['type'] === 'fit_2d_distribution'; }
    export function isFitCurveResult(toTest: ComputationResult): toTest is FitCurve.FitCurveResult { return toTest['type'] === 'fit_curve'; }
    export function isKendallTauResult(toTest: ComputationResult): toTest is KendallTau.KendallTauResult { return toTest['type'] === 'kendall_tau'; }
    export function isMutualInformationResult(toTest: ComputationResult): toTest is MutualInformation.MutualInformationResult { return toTest['type'] === 'mutual_information'; }
    export function isPearsonResult(toTest: ComputationResult): toTest is Pearson.PearsonResult { return toTest['type'] === 'pearson'; }
    export function isSpearmanResult(toTest: ComputationResult): toTest is Spearman.SpearmanResult { return toTest['type'] === 'spearman'; }
    export function isCountResult(toTest: ComputationResult): toTest is Count.CountResult { return toTest['type'] === 'count'; }
    export function isDummyComputationResult(toTest: ComputationResult): toTest is DummyComputation.DummyComputationResult { return toTest['type'] === 'dummy'; }
    export function isGroupedComputationResult(toTest: ComputationResult): toTest is GroupedComputation.GroupedComputationResult { return toTest['type'] === 'grouped'; }
    export function isMultiComputationResult(toTest: ComputationResult): toTest is MultiComputation.MultiComputationResult { return toTest['type'] === 'multi'; }
    export function isResampledComputationResult(toTest: ComputationResult): toTest is ResampledComputation.ResampledComputationResult { return toTest['type'] === 'resampled'; }
    export function isFetchValuesResult(toTest: ComputationResult): toTest is FetchValues.FetchValuesResult { return toTest['type'] === 'fetch_values'; }
    export function isPCAResult(toTest: ComputationResult): toTest is PCA.PCAResult { return toTest['type'] === 'pca'; }
    export function isACFResult(toTest: ComputationResult): toTest is ACF.ACFResult { return toTest['type'] === 'acf'; }
    export function isCorrelationFunctionResult(toTest: ComputationResult): toTest is CorrelationFunction.CorrelationFunctionResult { return ['acf', 'pacf'].includes(toTest['type']); }
    export function isDurbinWatsonResult(toTest: ComputationResult): toTest is DurbinWatson.DurbinWatsonResult { return toTest['type'] === 'durbin_watson'; }
    export function isMannKendallTestResult(toTest: ComputationResult): toTest is MannKendallTest.MannKendallTestResult { return toTest['type'] === 'mann_kendall'; }
    export function isPACFResult(toTest: ComputationResult): toTest is PACF.PACFResult { return toTest['type'] === 'pacf'; }
    export function isUnitRootTestResult(toTest: ComputationResult): toTest is UnitRootTest.UnitRootTestResult { return ['unit_root_adf', 'unit_root_kpss', 'unit_root_za'].includes(toTest['type']); }
    export function isUnitRootTestADFResult(toTest: ComputationResult): toTest is UnitRootTestADF.UnitRootTestADFResult { return toTest['type'] === 'unit_root_adf'; }
    export function isUnitRootTestKPSSResult(toTest: ComputationResult): toTest is UnitRootTestKPSS.UnitRootTestKPSSResult { return toTest['type'] === 'unit_root_kpss'; }
    export function isUnitRootTestZAResult(toTest: ComputationResult): toTest is UnitRootTestZA.UnitRootTestZAResult { return toTest['type'] === 'unit_root_za'; }
    export function isCountDistinctResult(toTest: ComputationResult): toTest is CountDistinct.CountDistinctResult { return toTest['type'] === 'count_distinct'; }
    export function isEmpiricalCDFResult(toTest: ComputationResult): toTest is EmpiricalCDF.EmpiricalCDFResult { return toTest['type'] === 'empirical_cdf'; }
    export function isEntropyResult(toTest: ComputationResult): toTest is Entropy.EntropyResult { return toTest['type'] === 'entropy'; }
    export function isFitDistributionResult(toTest: ComputationResult): toTest is FitDistribution.FitDistributionResult { return toTest['type'] === 'fit_distribution'; }
    export function isGuessTimeStepResult(toTest: ComputationResult): toTest is GuessTimeStep.GuessTimeStepResult { return toTest['type'] === 'guess_time_step'; }
    export function isKsTest2SampResult(toTest: ComputationResult): toTest is KsTest2Samp.KsTest2SampResult { return toTest['type'] === 'ks_test_2samp'; }
    export function isKurtosisResult(toTest: ComputationResult): toTest is Kurtosis.KurtosisResult { return toTest['type'] === 'kurtosis'; }
    export function isMatchTimeStepResult(toTest: ComputationResult): toTest is MatchTimeStep.MatchTimeStepResult { return toTest['type'] === 'match_time_step'; }
    export function isMaxResult(toTest: ComputationResult): toTest is Max.MaxResult { return toTest['type'] === 'max'; }
    export function isMaxTimeResult(toTest: ComputationResult): toTest is MaxTime.MaxTimeResult { return toTest['type'] === 'max_time'; }
    export function isMeanResult(toTest: ComputationResult): toTest is Mean.MeanResult { return toTest['type'] === 'mean'; }
    export function isMinResult(toTest: ComputationResult): toTest is Min.MinResult { return toTest['type'] === 'min'; }
    export function isMinTimeResult(toTest: ComputationResult): toTest is MinTime.MinTimeResult { return toTest['type'] === 'min_time'; }
    export function isMoodTestNSampResult(toTest: ComputationResult): toTest is MoodTestNSamp.MoodTestNSampResult { return toTest['type'] === 'mood_test_nsamp'; }
    export function isOneWayAnovaResult(toTest: ComputationResult): toTest is OneWayAnova.OneWayAnovaResult { return toTest['type'] === 'one_way_anova'; }
    export function isPairwiseMoodTestResult(toTest: ComputationResult): toTest is PairwiseMoodTest.PairwiseMoodTestResult { return toTest['type'] === 'pairwise_mood_test'; }
    export function isPairwiseTTestResult(toTest: ComputationResult): toTest is PairwiseTTest.PairwiseTTestResult { return toTest['type'] === 'pairwise_ttest'; }
    export function isQuantilesResult(toTest: ComputationResult): toTest is Quantiles.QuantilesResult { return toTest['type'] === 'quantiles'; }
    export function isSemResult(toTest: ComputationResult): toTest is Sem.SemResult { return toTest['type'] === 'sem'; }
    export function isShapiroResult(toTest: ComputationResult): toTest is Shapiro.ShapiroResult { return toTest['type'] === 'shapiro'; }
    export function isSignTest1SampResult(toTest: ComputationResult): toTest is SignTest1Samp.SignTest1SampResult { return toTest['type'] === 'sign_test_1samp'; }
    export function isSkewnessResult(toTest: ComputationResult): toTest is Skewness.SkewnessResult { return toTest['type'] === 'skewness'; }
    export function isStdDevResult(toTest: ComputationResult): toTest is StdDev.StdDevResult { return toTest['type'] === 'std_dev'; }
    export function isSumResult(toTest: ComputationResult): toTest is Sum.SumResult { return toTest['type'] === 'sum'; }
    export function isTTest1SampResult(toTest: ComputationResult): toTest is TTest1Samp.TTest1SampResult { return toTest['type'] === 'ttest_1samp'; }
    export function isTestDistributionResult(toTest: ComputationResult): toTest is TestDistribution.TestDistributionResult { return toTest['type'] === 'test_distribution'; }
    export function isVarianceResult(toTest: ComputationResult): toTest is Variance.VarianceResult { return toTest['type'] === 'variance'; }
    export function isZTest1SampResult(toTest: ComputationResult): toTest is ZTest1Samp.ZTest1SampResult { return toTest['type'] === 'ztest_1samp'; }
}

export namespace ComputationResult {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.ComputationResult$ComputationCounts
     */
    export interface ComputationCounts {
        computationsCount: number;
        failedComputationsCount: number;
        notComputedCount: number;
    }
}