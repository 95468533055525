import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { tap, map, switchMap, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { APIError } from '@core/dataiku-api/api-error';
import { Workspace } from '@model-main/workspaces/workspace';
import { ITaggingService } from '@model-main/server/services/itagging-service';
import { ATSurveyService } from '@features/surveys/surveys-migration';
import { WorkspaceObjectKey } from '@shared/models';
import {
    ErrorMessage,
    WorkspaceDisplayService,
    WorkspacesNavigationService,
    WorkspacesService
} from '../../shared';
import { DkuActivatedRouteService } from '@migration/dku-activated-route';

@UntilDestroy()
@Component({
    selector: 'workspace-object-page',
    templateUrl: './workspace-object-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceObjectPageComponent implements OnInit, OnDestroy {
    workspace$ = new BehaviorSubject<Workspace | undefined>(undefined);
    object$ = new BehaviorSubject<Workspace.WorkspaceObject | undefined>(undefined);
    insightId$ = new BehaviorSubject<string | null | undefined>(undefined);

    readonly hasError$ = new BehaviorSubject<boolean>(false);
    readonly apiError$ = new BehaviorSubject<APIError | null>(null);
    readonly errorMessage$ = new BehaviorSubject<ErrorMessage | null>(null);

    constructor(
        private activatedRoute: DkuActivatedRouteService,
        private workspacesService: WorkspacesService,
        private workspaceDisplayService: WorkspaceDisplayService,
        private workspacesNavigation: WorkspacesNavigationService,
        @Inject('ATSurveyService') private ATSurveyService: ATSurveyService
    ) { }

    ngOnInit(): void {
        this.workspacesService.getError().error$
            .pipe(
                withLatestFrom(this.activatedRoute.paramMap),
                tap(([error, paramMap]) => this.extractError(error, paramMap.get('workspaceKey'))),
                untilDestroyed(this)
            )
            .subscribe();

        this.activatedRoute.paramMap
            .pipe(
                tap(paramMap => this.insightId$.next(paramMap.get('insightId'))),
                map(paramMap => {
                    return {
                        workspaceKey: paramMap.get('workspaceKey'),
                        appId: paramMap.get('appId'),
                        projectKey: paramMap.get('projectKey'),
                        objectType: paramMap.get('objectType'),
                        objectId: paramMap.get('objectId')
                    };
                }),
                distinctUntilChanged((prev: { [key: string]: string | null }, curr: { [key: string]: string | null }) => isEqual(prev, curr)),
                switchMap(params => {
                    const objectKey = this.getObjectKeyFromParams(params);

                    if (params.workspaceKey && objectKey) {
                        return this.workspacesService.fetchWorkspaceObject(params.workspaceKey, objectKey);
                    }

                    return of(null);
                }),
                switchMap(() => combineLatest([this.workspacesService.getCurrentWorkspace(), this.workspacesService.getCurrentObject()])),
                tap(([workspace, object]) => {
                    this.workspace$.next(workspace);
                    this.object$.next(object);
                    this.onUpdateObject(workspace, object);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.workspacesService.pushError();
    }

    private getObjectKeyFromParams({ workspaceKey, appId, projectKey, objectType, objectId }: {[key: string]: string | null}): WorkspaceObjectKey | null {
        if (appId) {
            return { appId };
        } else if (workspaceKey && projectKey && objectType && objectId) {
            return {
                reference: {
                    projectKey,
                    workspaceKey,
                    type: objectType as ITaggingService.TaggableType,
                    id: objectId
                }
            };
        }
        return null;
    }

    private onUpdateObject(workspace?: Workspace, object?: Workspace.WorkspaceObject): void {
        const title = object?.displayName
            ? `${object.displayName} - ${this.workspaceDisplayService.getObjectTypeUI(object)}`
            : 'Workspace Object';
        this.workspacesNavigation.updateTopNavTitle(workspace, title);

        if (object) {
            this.ATSurveyService.updateCounter('WorkspacesOpenEditObject');
        }
    }

    private mapError(error: APIError, workspaceKey?: string | null): ErrorMessage | null {
        const message: ErrorMessage = { title: 'Sorry', message: '', instructions: '' };

        if (workspaceKey) {
            message.redirect = {
                href: `/workspaces/${workspaceKey}`,
                label: 'Go back to workspace'
            };
        }

        if (error.httpCode === 403 || error.httpCode === 404 || (error.errorType?.includes('UnauthorizedException'))) {
            if (error.message.includes('does not exist')) {
                message.message = 'This object does not exist in this workspace.';
            } else {
                const currentObject = this.workspacesService.getCurrentObjectSnapshot();

                message.message = currentObject
                    ? `You don't have access to ${this.workspaceDisplayService.getObjectTypeUI(currentObject).toLowerCase()} ${currentObject.displayName}.`
                    : 'You don\'t have access to this object.';
                message.instructions = 'Please ask the workspace administrator to grant you access.';
            }
        } else if (error.httpCode >= 500 && error.message.includes('not allowed to instantiate')) {
            message.message = 'You don\'t have the permission to execute this application.';
            message.instructions = 'Please ask the application administrator to grant you the permission.';
        } else if (!error.httpCode) {
            message.message = error.message;
            message.instructions = 'Try to reload the page, if the problem persists, please contact the support.';
        } else {
            return null;
        }

        return message;
    }

    private extractError(error?: APIError, workspaceKey?: string | null) {
        let errorMessage: ErrorMessage | null;
        if (error) {
            errorMessage = this.mapError(error, workspaceKey);

            if (errorMessage) {
                this.errorMessage$.next(errorMessage);
            } else {
                this.apiError$.next(error);
            }

            this.hasError$.next(true);
        } else {
            this.hasError$.next(false);
        }
    }

    closeError(): void {
        this.apiError$.next(null);
    }

    onError(error: APIError): void {
        this.workspacesService.pushError(error);
    }
}
