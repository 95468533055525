import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';
import { EMPTY_WORKSPACE, WORKSPACE_STOCK_COLORS } from '../../constants';

@Component({
    selector: 'workspace-general-edition',
    templateUrl: './workspace-general-edition.component.html',
    styleUrls: ['./workspace-general-edition.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceGeneralEditionComponent {
    private DEFAULT_WORKSPACE: Workspace = {
        ...EMPTY_WORKSPACE,
        color: WORKSPACE_STOCK_COLORS[Math.floor(Math.random() * WORKSPACE_STOCK_COLORS.length)]
    };

    readonly stockColors = WORKSPACE_STOCK_COLORS;

    editedWorkspace: Workspace;

    @Input()
    set workspace(value: Workspace | null) {
        if (value) {
            this.editedWorkspace = { ...value };
        } else {
            this.editedWorkspace = { ...this.DEFAULT_WORKSPACE };
        }
    }

    @Input() editionMode?: boolean;
    @Output() workspaceChange = new EventEmitter<Workspace>();

    onDisplayNameChange(): void {
        if (!this.editionMode) {
            this.updateWorkspaceKey();
        }
        this.onWorkspaceChange();
    }

    onWorkspaceChange(): void {
        this.workspaceChange.emit({ ...this.editedWorkspace });
    }

    updateWorkspaceKey(): void {
        this.editedWorkspace.workspaceKey = this.editedWorkspace.displayName ? this.editedWorkspace.displayName.toUpperCase().replace(/\W+/g, '_') : '';
    }

    pickStockColor(color: string): void {
        this.editedWorkspace.color = color;
        this.onWorkspaceChange();
    }
}
