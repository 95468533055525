import { Injectable } from '@angular/core';
import { APIError, catchAPIError, ErrorContext } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { Details } from '@core/dataiku-api/definitions/private-feature-store-api';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InfoMessage } from 'dku-frontend-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccessibleObjectsService, ProjectsService } from 'src/generated-sources';
import { FacetMap } from './feature-store-models';
import { Hit, Result } from './query-result-models';

@UntilDestroy()
@Injectable()
export class FeatureStoreService implements ErrorContext {

  public error$: BehaviorSubject<APIError | undefined>;
  
  constructor(
    private DataikuAPI: DataikuAPIService,
  ) {
    this.error$ = new BehaviorSubject<APIError | undefined>(undefined);
  }

  pushError(error?: APIError): void {
    this.error$.next(error);
  }

  resetError(): void {
    this.pushError();
  }

  search(query: string, facets: FacetMap): Observable<Result> {
    return this.DataikuAPI.featureStore
      .search(query, facets)
      .pipe(catchAPIError(this));
  }

  listUsers(): Observable<any> {
    return this.DataikuAPI.security
      .listUsers()
      .pipe(catchAPIError(this));
  }

  listProjects(): Observable<ProjectsService.UIProject[]> {
    return this.DataikuAPI.projects
      .list()
      .pipe(catchAPIError(this));
  }

  listTags(): Observable<any> {
    return this.DataikuAPI.taggableObjects
      .listAllTags(true)
      .pipe(catchAPIError(this));
  }

  getDetails(projectKey: string, datasetName: string): Observable<Details> {
    return this.DataikuAPI.featureStore
      .getDetails(projectKey, datasetName)
      .pipe(catchAPIError(this));
  }

  removeFromFeatureStore(item: Hit): Observable<any> {
    return this.DataikuAPI.featureStore
      .setFeatureGroup(item._source.projectKey, item._source.name, false)
      .pipe(catchAPIError(this));
  }

  flush(): Observable<void> {
    return this.DataikuAPI.catalog
      .flush()
      .pipe(catchAPIError(this));
  }

  exposeToProject(sourceProjectKey: string, type: string, objectId: string, targetProjectKey: string): Observable<InfoMessage> {
    return this.DataikuAPI.projects
      .addExposedObject(sourceProjectKey, type, objectId, targetProjectKey)
      .pipe(catchAPIError(this));
  }

  listAccessibleDatasetsInProject(projectKey: string): Observable<AccessibleObjectsService.AccessibleObject[]> {
    return this.DataikuAPI.taggableObjects
      .listAccessibleObjects(projectKey, "DATASET", "READ")
      .pipe(catchAPIError(this));
  }
}
