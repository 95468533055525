import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { WT1Service } from 'dku-frontend-core';
import { TaggableObjectsService } from '@model-main/server/services/taggable-objects-service';
import { Observable } from 'rxjs';
import { UIState, WatchInterestsState } from '../models';

// Duplicated from user-profile.js
@Injectable({
    providedIn: 'root'
})
export class InterestsService {

    constructor(
        private WT1: WT1Service,
        private dataikuAPI: DataikuAPIService,
        @Inject('$state') private $state: UIState,
    ) {}

    watch(taggableObjects: TaggableObjectsService.TaggableObjectRef[], watch: boolean | WatchInterestsState): Observable<void> {
        // Note: watch is not a boolean, please see WatchInterestState
        let w;
        if (watch === true) {
            w = WatchInterestsState.YES;
        } else if (watch === false) {
            w = WatchInterestsState.ENO;
        } else {
            w = watch;
        }

        this.WT1.event('watch-object', { watch: w, state: this.$state.current.name, objects: taggableObjects.length });
        return this.dataikuAPI.interests.watch(taggableObjects, w);
    }

    star(taggableObjects: TaggableObjectsService.TaggableObjectRef[], star: boolean) : Observable<void> {
        this.WT1.event('star-object', { star, state: this.$state.current.name, objects: taggableObjects.length });
        return this.dataikuAPI.interests.star(taggableObjects, star);
    }
}
