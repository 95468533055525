<div class="h100 vertical-flex run-inference-info">
    <div class="run-inference-info__title">
        <h3 class="dku-medium-title-sb mtop0 mbot0">Run Inference Info</h3>
    </div>
    <div *ngIf="(predictionType || codeEnv || modelClasses?.length || target); else showHelp"
         class="run-inference-info__table-container oh">
        <table class="table run-inference-info__table">
            <tbody>
                <tr>
                    <th>Model type</th>
                    <td>{{ predictionType || '-' }}</td>
                </tr>
                <tr>
                    <th>Code Env</th>
                    <td>{{ codeEnv || '-' }}</td>
                </tr>
                <tr>
                    <th>Target</th>
                    <td>{{ target || '-' }}</td>
                </tr>
                <tr *ngIf="(predictionType === PredictionType.BINARY_CLASSIFICATION) || (predictionType === PredictionType.MULTICLASS)">
                    <th class="vat">Classes</th>
                    <td class="vat">
                        <div class="mh100 oys">
                            <div *ngFor="let modelClass of modelClasses">
                                {{ modelClass }}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-template #showHelp>
        <span>
            Use the <code>set_run_inference_info</code> method while executing a run to attach useful information to deploy and evaluate the model.
            <a target="_blank" href="https://doc.dataiku.com/dss/latest/mlops/experiment-tracking/index.html">Read the documentation</a> to learn
            more.
        </span>
    </ng-template>
</div>
