import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Dashboard } from '@model-main/dashboards/model/dashboard';

@Injectable({providedIn: 'root'})
export class PrivateDashboardsAPI {
    constructor(private dkuHttp: DkuHttpService) {
    }

    get(projectKey: string, dashboardId: string): Observable<Dashboard> {
        return this.dkuHttp.request<any>('GET', '/dashboards/get', { projectKey, dashboardId });
    }

    getInsightWithPayload(projectKey: string, insightId: string): Observable<any> {
        return this.dkuHttp.request<any>('GET', '/dashboards/insights/get-with-payload', { projectKey, insightId });
    }
}
