import { Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { DeephubImagesDataService, ResolvedDeepHubPredictionCoreParams, SerializedMemTableV2, SerializedTableChunk } from 'src/generated-sources';
import { DeephubReport, DeephubReportService } from './abstract-deephub-report.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { resolveSmartName } from '@utils/loc';
import { SampleDataFetcherService } from '@shared/services/image-feed/sample-data-fetcher.service';
import { SampleDataFormatterService } from '@shared/services/image-feed/sample-data-formatter.service';
@UntilDestroy()
@Injectable()
export abstract class AbstractDeephubReportDataFetcherService<R extends DeephubReport, F extends DeephubImagesDataService.ComputerVisionPredictedFilter> extends SampleDataFetcherService {
    coreParams: ResolvedDeepHubPredictionCoreParams;
    categories: string[];

    report$: Observable<R>;
    filter$ = new BehaviorSubject<Partial<F>>(this.getDefaultFilter());
    fetchTrigger$: Observable<[R, Partial<F>]>;

    constructor(
        private DataikuAPI: DataikuAPIService,
        private reportService: DeephubReportService<R>,
        currentRoute: CurrentRouteService,
        private colorMapService: ColorMapContextService,
        dataFormatter: SampleDataFormatterService,
        private COLOR_PALETTES: { [palette: string]: string[]; },
    ) {
        super(dataFormatter);
        this.projectKey = currentRoute.projectKey;
        this.report$ = this.reportService.getReport().pipe(
            debounceTime(400)
        );

        this.report$.
            pipe(
                untilDestroyed(this)
            ).subscribe((report) => {
                this.coreParams = report.coreParams;
                this.categories = report.categories;
                this.managedFolderLoc = resolveSmartName(this.projectKey, report.coreParams.managedFolderSmartId);
                this.colorMapService.setMapping(report.categories, this.COLOR_PALETTES.highContrast);

                this.refetchData();
            });

        this.fetchTrigger$ = combineLatest([this.report$, this.filter$])
            .pipe(
                map(([report, filter]) => [report, filter ? this.combineReportFilter(report, filter as F) : filter]),
            );
    }

    abstract combineReportFilter(report: R, filter: F): F;

    abstract getDefaultFilter(): Partial<F>;

    refreshSample(): Observable<SerializedMemTableV2> {
        return this.fetchTrigger$
            .pipe(switchMap(([report, filter]) => this.DataikuAPI.analysis.refreshPredictedImagesDataSample(report.fullModelId, this.CHUNK_SIZE, filter)));
    }

    getSampleChunk(firstRow: number, nbRows: number): Observable<SerializedTableChunk> {
        return this.fetchTrigger$
            .pipe(
                switchMap(([report, filter]) => this.DataikuAPI.analysis.getPredictedImagesDataChunk(report.fullModelId, firstRow, nbRows, filter))
            );
    }

    setFilteredPair(filter: Partial<F>) {
        this.filter$.next(filter);
        this.refetchData();
    }
}
