import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from "@app/widgets/widgets.module";
import { HeatmapComponent } from './heatmap/heatmap.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalsModule } from '@shared/modals/modals.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommaSeparatedViewDirective } from '@shared/directives/comma-separated-view/comma-separated-view.directive';
import { ForceIntegerDirective } from '@shared/directives/force-integer/force-integer.directive';
import { NgxEchartsModule } from 'ngx-echarts';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { ClickStopDirective } from '@shared/directives/click-stop/click-stop.directive';
import { SimpleNumberListComponent } from './simple-number-list/simple-number-list.component';
import { ContainerSelectionFormComponent } from './container-selection-form/container-selection-form.component';
import { DoclinkWrapperComponent } from './doclink-wrapper/doclink-wrapper.component';
import { DatasetErrorCtaComponent } from './dataset-error-cta/dataset-error-cta.component';
import { SidebarNavComponent } from './sidebar-nav/sidebar-nav.component';
import { SidebarNavItemComponent } from './sidebar-nav/sidebar-nav-item/sidebar-nav-item.component';
import { ImageFeedComponent } from './image-feed/image-feed.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { ConfusionMatrixComponent } from './confusion-matrix/confusion-matrix.component';
import { ImageMetadataComponent } from './feed-image-modal/image-metadata/image-metadata.component';
import { UserPictureComponent } from './user-picture/user-picture.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PipesModule } from '../pipes/pipes.module';
import { ProjectSelectorComponent } from './project-selector/project-selector.component';
import { AccessibleObjectsSelectorComponent } from './accessible-objects-selector/accessible-objects-selector.component';
import { FeedImageModalComponent } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { FeedImageModalSectionComponent } from './feed-image-modal-section/feed-image-modal-section.component';
import { FeedImageModalCanvasComponent } from './feed-image-modal-canvas/feed-image-modal-canvas.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ImagePlaceholderComponent } from './image-placeholder/image-placeholder.component';
import { IndeterminateDirective } from '@shared/directives/indeterminate/indeterminate.directive';
import { ForceResizeDirective } from '@shared/directives/force-resize/force-resize.directive';
import { DynamicVirtualScrollViewportDirective } from '@shared/directives/dynamic-virtual-scroll-viewport.directive';
import { ImageContainerComponent } from './image-container/image-container.component';
import { InViewportModule } from 'ng-in-viewport';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectPanelSimulatorComponent, NgSelectMonkeyPatchDirective } from '@shared/directives/ng-select-monkey-patch.directive';
import { BasicSelectComponent } from './basic-select/basic-select.component';
import { SortOrderButtonComponent } from './sort-order-button';
import { ImageWithArtifactsComponent } from './image-with-artifacts/image-with-artifacts.component';
import { RightPanelSchemaComponent } from './right-panel-summary/right-panel-schema/right-panel-schema.component';
import { DkuFrontendCoreModule } from 'dku-frontend-core';
import { NgSelectSearchInputComponent } from './ng-select-search-input/ng-select-search-input.component';
import { ImageFeedModalComponent } from './image-feed-modal/image-feed-modal.component';
import { ImageFeedModalAnnotationSectionComponent } from './image-feed-modal-annotation-section/image-feed-modal-annotation-section.component';
import { NgSelectPseudoCheckboxComponent } from './ng-select-pseudo-checkbox/ng-select-pseudo-checkbox.component';
import { TextOverflowTooltipModule } from '../../migration/modules/text-overflow-tooltip';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalsModule,
        ScrollingModule,
        NgxEchartsModule,
        DataikuAPIModule,
        DkuFrontendCoreModule,
        MatFormFieldModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        PipesModule,
        InViewportModule,
        WidgetsModule,
        NgSelectModule,
        TextOverflowTooltipModule,
    ],
    declarations: [
        HeatmapComponent,
        ImageFeedComponent,
        ImageFeedModalComponent,
        ImageFeedModalAnnotationSectionComponent,
        CommaSeparatedViewDirective,
        ClickStopDirective,
        ForceIntegerDirective,
        IndeterminateDirective,
        ForceResizeDirective,
        DynamicVirtualScrollViewportDirective,
        SimpleNumberListComponent,
        ContainerSelectionFormComponent,
        DoclinkWrapperComponent,
        DatasetErrorCtaComponent,
        SidebarNavComponent,
        SidebarNavItemComponent,
        InfiniteScrollComponent,
        ImageWithArtifactsComponent,
        FeedImageModalComponent,
        FeedImageModalSectionComponent,
        ConfusionMatrixComponent,
        UserPictureComponent,
        ProjectSelectorComponent,
        AccessibleObjectsSelectorComponent,
        FeedImageModalCanvasComponent,
        CarouselComponent,
        ImageMetadataComponent,
        ImagePlaceholderComponent,
        ImageContainerComponent,
        NgSelectMonkeyPatchDirective,
        NgSelectPanelSimulatorComponent,
        BasicSelectComponent,
        NgSelectSearchInputComponent,
        SortOrderButtonComponent,
        NgSelectPseudoCheckboxComponent,
        RightPanelSchemaComponent
    ],
    exports: [
        HeatmapComponent,
        ConfusionMatrixComponent,
        ImageFeedComponent,
        CommaSeparatedViewDirective,
        ClickStopDirective,
        ForceIntegerDirective,
        IndeterminateDirective,
        ForceResizeDirective,
        DynamicVirtualScrollViewportDirective,
        SimpleNumberListComponent,
        ContainerSelectionFormComponent,
        DoclinkWrapperComponent,
        DatasetErrorCtaComponent,
        SidebarNavComponent,
        SidebarNavItemComponent,
        InfiniteScrollComponent,
        ImageWithArtifactsComponent,
        FeedImageModalComponent,
        FeedImageModalSectionComponent,
        FeedImageModalCanvasComponent,
        ImageMetadataComponent,
        UserPictureComponent,
        ImagePlaceholderComponent,
        ProjectSelectorComponent,
        AccessibleObjectsSelectorComponent,
        ImageContainerComponent,
        NgSelectMonkeyPatchDirective,
        NgSelectModule,
        BasicSelectComponent,
        NgSelectSearchInputComponent,
        ImageContainerComponent,
        SortOrderButtonComponent,
        NgSelectPseudoCheckboxComponent,
        RightPanelSchemaComponent
    ],
})
export class ComponentsModule {
}
