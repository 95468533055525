import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { cloneDeep } from "lodash";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";
import { AnnotationGroup } from "@features/labeling/models/annotation-group";
import { UIAnnotation } from "@features/labeling/models/annotation";

@UntilDestroy()
@Injectable()
export class LabelingReviewAnnotationGroupService {
    annotationGroupList$ = new BehaviorSubject<AnnotationGroup[]>([]);
    fetchedAnnotationGroupList$ = new BehaviorSubject<AnnotationGroup[]>([]);
    selectAnnotationGroupTrigger$ = new Subject<number>();
    deleteAnnotationGroupTrigger$ = new Subject<number>();
    updateAnnotationGroupTrigger$ = new Subject<UIAnnotation[]>();
    addAnnotationGroupTrigger$ = new Subject<AnnotationGroup>();
    selectedAnnotationGroup$: Observable<AnnotationGroup>;
    isConflicting$: Observable<boolean>;
    modifiedByReviewerTrigger$ = new Subject<void>();
    resetAnnotationGroupTrigger$ = new Subject<void>();

    constructor() {
        this.selectAnnotationGroupTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$),
        ).subscribe(([index, annotationGroupList]) => {
            if (annotationGroupList[index]) {
                if (!annotationGroupList[index].selected) {
                    for (let annotationGroup of annotationGroupList) {
                        annotationGroup.selected = false;
                        annotationGroupList[index].annotations[0].selected = false;
                    }
                }
                annotationGroupList[index].selected = !annotationGroupList[index].selected;
                //Automatically select first box
                annotationGroupList[index].annotations[0].selected = !annotationGroupList[index].annotations[0].selected;
                this.annotationGroupList$.next(annotationGroupList);
            }
        })

        this.deleteAnnotationGroupTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$),
        ).subscribe(([index, annotationGroupList]) => {
            if (annotationGroupList[index]) {
                annotationGroupList.splice(index, 1);
                this.annotationGroupList$.next(annotationGroupList);
            }
        })

        this.selectedAnnotationGroup$ = this.annotationGroupList$.pipe(
            map(annotationGroupList => annotationGroupList.filter(r => r.selected)[0]),
            filter(annotationGroup => annotationGroup != undefined)
        )

        this.isConflicting$ = this.annotationGroupList$.pipe(
            map(
                annotationGroupList => annotationGroupList.some(
                    annotationGroup => annotationGroup.hasConflict()
                )
            )
        );

        this.updateAnnotationGroupTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$)
        ).subscribe(([annotations, annotationGroupList]) => {
            if (annotationGroupList) {
                annotationGroupList.filter(r => r.selected)[0].annotations = annotations;
                annotationGroupList = annotationGroupList.filter(r => r.annotations.length > 0);
                this.annotationGroupList$.next(annotationGroupList);
            }
        })

        this.modifiedByReviewerTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$)
        ).subscribe(([_trigger, annotationGroupList]) => {
            if (annotationGroupList.filter(r => r.selected).length) {
                annotationGroupList.filter(r => r.selected)[0].modifiedByReviewer = true;
            }
        })

        this.resetAnnotationGroupTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.fetchedAnnotationGroupList$)
        ).subscribe(([_trigger, annotationGroupList]) => {
            this.annotationGroupList$.next(cloneDeep(annotationGroupList));
        })

        this.addAnnotationGroupTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.annotationGroupList$)
        ).subscribe(([annotationGroup, annotationGroupList]) => {
            if (annotationGroupList) {
                annotationGroupList.push(annotationGroup)
                this.annotationGroupList$.next(annotationGroupList);
            }
        })
    }

    selectAnnotationGroup(index: number) {
        this.selectAnnotationGroupTrigger$.next(index);
    }

    deleteAnnotationGroup(index: number) {
        this.deleteAnnotationGroupTrigger$.next(index);
    }

    resetAnnotationGroupList() {
        this.resetAnnotationGroupTrigger$.next();
    }

    updateAnnotationGroup(annotation: UIAnnotation[], modifiedByReviewer?: boolean) {
        this.updateAnnotationGroupTrigger$.next(annotation);
        if (modifiedByReviewer) {
            this.modifiedByReviewerTrigger$.next();
        }
    }

    addAnnotationGroup(annotationGroup: AnnotationGroup) {
        this.addAnnotationGroupTrigger$.next(annotationGroup);
    }
}