<ng-container *ngIf="(workspace$ | async) as workspace else workspaceError">
    <div class="main-panel workspace-page">
        <workspace-header [workspace]="workspace"></workspace-header>
        <main class="workspace-page__content">
            <section class="workspace-page__toolbar">
                <h3 class="workspace-page__toolbar-title">
                    Objects
                    <button *ngIf="canShareToWorkspace"
                        class="btn btn--icon btn--primary btn--text"
                        matTooltip="Add objects to workspace"
                        matTooltipPosition="right"
                        (click)="openAddObjectModal(workspace)">
                            <i class="icon-plus-sign"></i>
                    </button>
                </h3>

                <div *ngIf="workspace.workspaceObjects.length > 0">
                    <workspace-object-filters
                        [objects]="workspace.workspaceObjects"
                        [filters]="objectFilters"
                        [sort]="objectSort"
                        (filtersChange)="onFiltersChanged(workspace, $event)"
                        (sortChange)="onSortChanged(workspace, $event)">
                    </workspace-object-filters>

                    <div class="btn-group">
                        <button class="btn btn--text btn--secondary btn--icon btn--xlarge"
                            [class.btn--active]="displayType === WorkspaceListType.TILE"
                            (click)="displayType = WorkspaceListType.TILE"
                            matTooltip="Tile view">
                            <i class="icon-th-large"></i>
                        </button>
                        <button class="btn btn--text btn--secondary btn--icon btn--xlarge"
                            [class.btn--active]="displayType === WorkspaceListType.LIST"
                            (click)="displayType = WorkspaceListType.LIST"
                            matTooltip="List view">
                            <i class="icon-list"></i>
                        </button>
                    </div>
                </div>
            </section>
            <section *ngIf="workspace.workspaceObjects.length > 0 && (filteredObjects$ | async) as filteredObjects" class="workspace-page__objects">
                <workspace-object-list
                    [workspaceKey]="workspace.workspaceKey"
                    [objects]="filteredObjects"
                    [canRemove]="workspace.currentUserPermissions.write"
                    [displayType]="displayType"
                    (removeObject)="openRemoveObjectModal(workspace, $event)"
                    (toggleStar)="toggleStar(workspace, $event.object, $event.status)">
                </workspace-object-list>
                <span *ngIf="filteredObjects.length === 0 && workspace.workspaceObjects.length > 0" class="workspace-page__no-results">
                    No object match your search.
                </span>
            </section>
            <section *ngIf="workspace.workspaceObjects.length === 0">
                <div *ngIf="canShareToWorkspace" class="text-center">
                    <h2 class="dku-grand-title-sb">This is the '{{ workspace.displayName }}' workspace!</h2>
                    <h3 class="dku-medium-title">It's currently empty. You can:</h3>
                    <div class="workspace-page__bubble-container mtop16">
                        <div (click)="openAddObjectModal(workspace)" class="workspace-page__bubble selectable">
                            <a><img class="mbot16" src="/static/dataiku/images/workspaces/add-content-to-workspace.svg" width="222" /></a>
                            <h3 class="dku-small-title-sb workspace-page__bubble-title">Add content</h3>
                            <p class="dku-small-title">Publish relevant objects from a variety of projects into the workspace and let everyone here access them</p>
                        </div>
                        <div *ngIf="workspace.currentUserPermissions.admin" (click)="openMembersModal(workspace)" class="workspace-page__bubble selectable">
                            <a><img class="mbot16" src="/static/dataiku/images/workspaces/add-user-to-workspace.svg" width="222" /></a>
                            <h3 class="dku-small-title-sb workspace-page__bubble-title">Add users</h3>
                            <p class="dku-small-title">Have others join you in the workspace</p>
                        </div>
                    </div>
                </div>  
                <div *ngIf="!canShareToWorkspace" class="text-center">
                    <img class="mbot16" src="/static/dataiku/images/workspaces/no-workspace.svg" width="250" />
                    <h2 class="dku-grand-title-sb mbot16">This is the '{{ workspace.displayName }}' workspace!</h2>
                    <h3 class="dku-medium-title">The workspace isn't ready yet; nothing to see for now...</h3>
                    <p class="dku-small-title mtop24">Come back when your colleagues have added relevant content. In the meantime, you can visit the <academy-link>Dataiku Academy</academy-link> to ramp up on Dataiku DSS</p>
                </div>    
            </section>
        </main>
    </div>
    <workspace-right-panel [workspace]="workspace" [timeline]="timeline$ | async"></workspace-right-panel>
</ng-container>
<ng-template #workspaceError>
    <workspace-error-message [message]="errorMessage$ | async"></workspace-error-message>
</ng-template>
