<ng-container *ngIf="(hasError$ | async) else workspaceObjectViewer">
    <api-error-alert *ngIf="apiError$ | async as apiError" [apiError]="apiError" (resetError)="closeError()"
        [closable]="true"></api-error-alert>
    <workspace-error-message *ngIf="errorMessage$ | async as errorMessage" [message]="errorMessage">
    </workspace-error-message>
</ng-container>

<ng-template #workspaceObjectViewer>
    <workspace-object-viewer class="main-panel" [workspace]="workspace$ | async" [object]="object$ | async"
        [insightId]="insightId$ | async" (fail)="onError($event)">
    </workspace-object-viewer>
</ng-template>
