import { Injectable } from "@angular/core";
import { LabelingReviewRecord } from "@features/labeling/services/labeling-review-data-fetcher.service";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { isTaskSetProperly } from "@features/labeling/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, withLatestFrom } from "rxjs/operators";

export enum ReviewFetchType {
    RECORDS_TO_REVIEW = 'RECORDS_TO_REVIEW',
    REVIEWED_RECORDS = 'REVIEWED_RECORDS'
}

@UntilDestroy()
@Injectable()
export class LabelingReviewImagePathService {
    private previousImageTrigger$ = new Subject<void>();
    private previousTrigger$ = new Subject<void>();
    private nextImageTrigger$ = new Subject<void>();
    private refreshTrigger$ = new Subject<void>();
    private fetchTrigger$ = new ReplaySubject<void>(1);
    private updateReviewRecordTrigger$ = new Subject<Partial<LabelingReviewRecord>>();

    isLast$: Observable<boolean>;
    isFirst$: Observable<boolean>;
    isEmpty$: Observable<boolean>;
    isFiltered$: Observable<boolean>;
    itemPaths$ = new ReplaySubject<string[]>(1);
    private originalItemPaths$: Observable<string[]>;

    currentImageSource$ = new ReplaySubject<{ id: number, imagePath: string }>(1);
    currentReviewRecord$ = new ReplaySubject<LabelingReviewRecord>(1);
    currentImagePath$: Observable<string>;
    currentIndex$: Observable<number>;
    finishedReview$ = new BehaviorSubject<boolean>(false);
    reviewFetchType$ = new BehaviorSubject<ReviewFetchType>(ReviewFetchType.RECORDS_TO_REVIEW);
    filterQuery$ = new BehaviorSubject<string>('');
    
    constructor(private labelingService: LabelingService) {

        this.originalItemPaths$ = this.fetchTrigger$.pipe(
            withLatestFrom(
                this.labelingService.labelingTaskInfo$,
                this.labelingService.identifier$, 
                this.reviewFetchType$,
            ),
            switchMap(([, task, identifier, reviewFetchType]) => {
                if (!isTaskSetProperly(task)) {
                    return of([]);
                }
                if (identifier) {
                    return of([identifier]);
                } else {
                    switch(reviewFetchType) {
                        case ReviewFetchType.RECORDS_TO_REVIEW: 
                            return this.labelingService.fetchRecordsToReview().pipe(
                                map((records) => records.map(record => record.id))
                            );
                        case ReviewFetchType.REVIEWED_RECORDS:
                            return this.labelingService.fetchReviewedRecords().pipe(
                                map((records) => records.map(record => record.id))
                            );
                    }
                }
            }),
            shareReplay(1)
        );

        combineLatest([
            this.originalItemPaths$,
            this.filterQuery$
        ]).pipe(
            map(([originalItemPaths, query]) => query ? originalItemPaths.filter(itemPath => itemPath.toLowerCase().includes(query.toLowerCase())) : originalItemPaths),
            untilDestroyed(this),
        ).subscribe(itemPaths => {
            this.itemPaths$.next(itemPaths);
        });

        this.nextImageTrigger$.pipe(
            withLatestFrom(
                this.itemPaths$,
                this.currentImageSource$
            ),
            map(([_, imagePaths, currentImagePath]) => {
                if (currentImagePath) {
                    return [imagePaths, imagePaths.indexOf(currentImagePath.imagePath) + 1] as const;
                } else {
                    return [imagePaths, 1] as const;
                }
            }),
            filter(([imagePaths, newIndex]) => {
                return newIndex !== -1 && newIndex < imagePaths.length;
            }),
            untilDestroyed(this)
        ).subscribe(([imagePaths, id]) => {
            const imagePath = imagePaths[id];
            this.currentImageSource$.next({ imagePath, id });
        });

        this.refreshTrigger$.pipe(
            withLatestFrom(this.currentImageSource$),
            untilDestroyed(this)
        ).subscribe(([_, { imagePath, id }]) => {
            this.currentImageSource$.next({ imagePath, id });
        });

        this.previousTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.finishedReview$)
        ).subscribe(([_trigger, finishedReview]) => {
            if (finishedReview) {
                this.refreshTrigger$.next();
                this.finishedReview$.next(false);
            } else {
                this.previousImageTrigger$.next();
            }
        })

        // TODO remove duplication with nextImageSource$
        this.previousImageTrigger$.pipe(
            withLatestFrom(
                this.itemPaths$,
                this.currentImageSource$
            ),
            map(([_, imagePaths, currentImageSource]) => {
                if (currentImageSource) {
                    return [imagePaths, imagePaths.indexOf(currentImageSource.imagePath) - 1] as const;
                } else {
                    return [imagePaths, 1] as const;
                }
            }),
            filter(([imagePaths, newIndex]) => {
                return newIndex !== -1 && newIndex < imagePaths.length;
            }),
            untilDestroyed(this)
        ).subscribe(([imagePaths, newIndex]) => {
            this.setImageSource(imagePaths[newIndex], newIndex);
        });

        this.isLast$ = combineLatest([this.currentImageSource$, this.itemPaths$]).pipe(
            map(([{ id }, paths]) => paths.length > 0 && (id === paths.length-1))
        );

        this.isFirst$ = this.currentImageSource$.pipe(
            map(({ id }) => id === 0),
            startWith(true)
        );

        this.isEmpty$ = this.itemPaths$.pipe(map(paths => paths.length === 0));

        this.isFiltered$ = this.filterQuery$.pipe(map(query => query.length !== 0));

        this.itemPaths$.pipe(
            distinctUntilChanged(([prevItemPaths,], [currItemPaths,]) => prevItemPaths === currItemPaths),
            untilDestroyed(this)
        ).subscribe(itemPaths => {
            this.setImageSource(itemPaths[0], 0);
        });

        this.currentImagePath$ = this.currentImageSource$.pipe(
            map(image => image.imagePath),
            filter(path => path != null)
        );

        this.currentIndex$ = this.currentImageSource$.pipe(
            map(image => image.id)
        );

        this.reviewFetchType$.pipe(
            untilDestroyed(this)
        ).subscribe(_ => {
            this.fetchTrigger$.next();
        });

        this.updateReviewRecordTrigger$.pipe(
            withLatestFrom(this.currentReviewRecord$),
            untilDestroyed(this)
        ).subscribe(([partialRecord, currentRecord]) => {
            this.setReviewRecord({
                ...currentRecord, 
                ...partialRecord
            });
        });
    }

    setReviewRecord(item: LabelingReviewRecord) {
        this.currentReviewRecord$.next(item);
    }

    updateCurrentReviewRecord(item: Partial<LabelingReviewRecord>) {
        this.updateReviewRecordTrigger$.next(item);
    }

    setImageSource(imagePath: string, id: number) {
        this.currentImageSource$.next({ imagePath, id });
        this.finishedReview$.next(false);
    }

    first() {
        this.fetchTrigger$.next();
    }

    public toggleReviewFetchType(fetchType: ReviewFetchType) {
        this.reviewFetchType$.next(fetchType);
    }

    public filterImagePaths(query: string) {
        this.filterQuery$.next(query);
    }

    public clearFilter() {
        this.filterQuery$.next('');
    }

    nextImage() {
        this.nextImageTrigger$.next();
    }

    previousImage() {
        this.previousTrigger$.next();
    }
}
