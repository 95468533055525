import { Inject, Injectable } from '@angular/core';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { FrontendChartDef } from '../../../../interfaces';
import { EChartsAxesService, EChartSeriesContext, EChartOptionsContext, EChartMatrixPoint, EChartAxis } from '../../../echarts';
import { VerticalBarsEChartDef } from './vertical-bars-echart-def.model';

@Injectable({
    providedIn: 'root'
})
export class VerticalBarsEChartDefService extends VerticalBarsEChartDef {
    constructor(
        echartsAxesService: EChartsAxesService,
        @Inject('ChartStoreFactory') chartStoreFactory: any,
        @Inject('ChartFormatting') chartFormattingService: any,
        @Inject('ChartLabels') chartLabelsService: any,
        @Inject('ChartDimension') chartDimensionService: any
    ) {
        super(chartStoreFactory, chartFormattingService, chartLabelsService, chartDimensionService, echartsAxesService);
    }

    protected getAxesSpecs(chartDef: FrontendChartDef, matrixPoints: Array<EChartMatrixPoint>): any {
        return this.buildAxesSpecs(
            chartDef.genericMeasures,
            chartDef.variant,
            chartDef.xCustomExtent,
            chartDef.yCustomExtent,
            chartDef.genericDimension0[0],
            chartDef.genericDimension1[0],
            chartDef.axis1LogScale,
            matrixPoints
        );
    }

    protected getSeries({
        chartDef,
        chartData,
        chartWidth,
        chartHeight,
        colorScale,
        chartPoints,
        xAxes,
        frameIndex
    }: EChartSeriesContext): Array<BarSeriesOption> {
        if (chartDef && chartData && chartWidth && chartHeight && colorScale && xAxes) {
            let series = this.buildSeries({
                chartDef,
                chartData,
                chartWidth,
                chartHeight,
                colorScale,
                matrixPoints: chartPoints as Array<EChartMatrixPoint>,
                mainAxis: EChartAxis.X,
                mainAxisType: xAxes[0].type,
                frameIndex
            });
            series = this.addSafeguards(series, xAxes);
            return series;
        } else {
            throw new Error('Missing properties to build series for vertical bars echart');
        }
    }

    protected getOptions({ xAxes, yAxes, series, gridOptions }: EChartOptionsContext): EChartsOption {
        if (xAxes && yAxes) {
            return this.buildOptions(xAxes, yAxes, series, gridOptions);
        } else {
            throw new Error('Missing properties to build options for vertical bars echart');
        }
    }
}
