<div *ngIf="objects?.length" class="workspace-object-list">
    <workspace-object-list-element
        *ngFor="let object of objects; trackBy: trackWorkspaceObject"
        [workspaceKey]="workspaceKey"
        [object]="object"
        [displayType]="displayType"
        [canRemove]="canRemove"
        (toggleStar)="onToggleStar($event)"
        (remove)="onRemoveObject(object)"
    ></workspace-object-list-element>
</div>
