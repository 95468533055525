<div class="image-feed__container" [class.image-feed__container--single-row]="itemsPerRow === 1" #container>
    <div class="tac" *ngIf="loadingStatus$ | async">
        <i class="dku-loader icon-spin dku-loader--inlined image-feed__loader"></i>
    </div>    
    <infinite-scroll class="w100 h100" (imageSelect)="imageSelect.emit($event)" [getChunkFn]="getChunk" [selectedIndex]="selectedIndex" [chunkSize]="getChunkSize()" [itemsPerRow]="itemsPerRow" [itemHeight]="imageHeight" (loadingStatus)="loadingStatus$.next($event)">
        <ng-template let-item="item" let-index="index">
            <div class="image-feed-image" 
                 [ngClass]="{ 'image-feed-image--selected': selectedIndex !== undefined && selectedIndex === index ,
                              'cursor-pointer': !!imageClick && (selectedPath === undefined || selectedPath !== item?.itemPath)}"
                 [style.height.px]="imageHeight"
                 (click)="imageClick?.emit({ imageId: index, itemPath: item?.itemPath })">
                <image-with-artifacts [cellData]="item" [placeholderSize]="imageHeight"></image-with-artifacts>
            </div>
        </ng-template>
    </infinite-scroll>
</div>
