import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'sort-order-button',
    templateUrl: './sort-order-button.component.html',
    styleUrls: ['./sort-order-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortOrderButtonComponent {

    @Input() value: boolean;
    @Input() buttonClass = 'btn btn--secondary btn--text btn--icon';
    @Input() disabled = false;
    @Output() valueChange = new EventEmitter<boolean>();

    setValue(newValue: boolean): void {
        this.value = newValue;
        this.valueChange.emit(this.value);
    }
}
