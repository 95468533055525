import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { LabelingMetrics } from '@model-main/labeling/labeling-metrics';
import { filter, map, Observable, shareReplay, tap, withLatestFrom } from 'rxjs';
import { LabelingTask, LabelingTaskStats } from 'src/generated-sources';
import { UnusableTaskWarning } from '../labeling-unusable-warning/labeling-unusable-warning.component';
import { LabelingTaskPrivileges } from '../labeling.component';
import { ColorMeaning, LabelingColorService } from '../services/labeling-color.service';
import { LabelingService } from '../services/labeling.service';


export type PerAnnotatorStats = { [key: string]: Stats };
export interface Stats {
    numLabeled: number
    numReviewed: number
    numTotal: number,
    metrics: LabelingMetrics;
}
@Component({
    selector: 'labeling-task-overview',
    templateUrl: './labeling-task-overview.component.html',
    styleUrls: ['./labeling-task-overview.component.less', './shared-overview.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingTaskOverviewComponent implements OnInit {
    @Input() privilegesOnTask: LabelingTaskPrivileges;

    stats$: Observable<LabelingTaskStats>;

    totalNumRecords$: Observable<number>;

    minNbAnnotatorsPerRecord$: Observable<number>;

    globalPerRecordStatusCount$: Observable<{[key in LabelingTaskStats.RecordStatus]: number}>;
    globalPerClassPercentage$: Observable<{[key: string]: [number, boolean]}>;
    perAnnotatorStats$: Observable<PerAnnotatorStats | null>;

    unusableTaskWarning$: Observable<UnusableTaskWarning | null>;

    objectKeysFun = Object.keys;

    LabelingTaskType = LabelingTask.LabelingTaskType;

    constructor(public labelingService: LabelingService,
        private labelingColorService: LabelingColorService,
        @Inject('$state') private $state: any) { }

    ngOnInit(): void {

        this.labelingColorService.setCurrentColorMeaning(ColorMeaning.CLASS);

        const stats$ = this.labelingService.getStats().pipe(shareReplay(1));

        this.minNbAnnotatorsPerRecord$ = this.labelingService.labelingTaskInfo$.pipe(
            map((info) => info.minNbAnnotatorsPerRecord)
        );

        this.globalPerRecordStatusCount$ = stats$.pipe(
            map((stats) => stats.globalRecordsStat.perRecordStatusCount)
        );

        this.globalPerClassPercentage$ = stats$.pipe(
            withLatestFrom(this.labelingService.classes$),
            map(([stats, classes]) => {
                const perClassCount = stats.perClassCount;
                const totalCount = Object.values(perClassCount).reduce((partialSum, count) => partialSum + count, 0);
                const perClassPercentage: { [key: string]: [number, boolean] } = {};
                for  (const klass of classes) {
                    perClassPercentage[klass] = [0, true];
                }
                for (const [klass, count] of Object.entries(perClassCount)) {
                    perClassPercentage[klass] = [totalCount > 0 ? count / totalCount : 0, classes.includes(klass)];
                }
                return perClassPercentage;
            })
        )

        this.totalNumRecords$ = stats$.pipe(
            map((stats) => Object.values(stats.globalRecordsStat.perRecordStatusCount).reduce((partialSum, count) => partialSum + count, 0))
        );

        this.perAnnotatorStats$ = stats$.pipe(
            map((stats) => {
                if (!stats.perAnnotatorRecordsStats) {
                    return  null;
                }
                const formattedStats: PerAnnotatorStats = {};
                for (const annotator in stats.perAnnotatorRecordsStats) {
                    const numLabeled = stats.perAnnotatorRecordsStats[annotator].perAnswerStatusCount.PENDING_REVIEW;
                    const numReviwed = stats.perAnnotatorRecordsStats[annotator].perAnswerStatusCount.REVIEWED;
                    const metrics = stats.perAnnotatorRecordsStats[annotator].metrics;
                    formattedStats[annotator] = {
                        numLabeled,
                        numReviewed: numReviwed,
                        numTotal: numLabeled + numReviwed,
                        metrics,
                    };
                }
                return formattedStats;
            }),
        );

        this.unusableTaskWarning$ = this.labelingService.labelingTaskUnusableReasons$.pipe(
            map((warnings) => warnings[0])
        );
    }

    sortByDescValue = (a: KeyValue<string, [number, boolean]>, b: KeyValue<string, [number, boolean]>): number => {
        return b.value[0] - a.value[0];
    }

    getColorForClass(klass: string): string {
        return this.labelingColorService.categoryToColorString(klass);
    }

    goToReviewTab() {
        this.$state.go('projects.project.labelingtasks.labelingtask.review');
    }
}
