import { Injectable } from "@angular/core";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ColumnMetadata, ImportantColumnsMetadata, SampleDataFetcherService } from "@shared/services/image-feed/sample-data-fetcher.service";
import { SampleDataFormatterService } from "@shared/services/image-feed/sample-data-formatter.service";
import { Observable } from "rxjs";
import { SerializedMemTableV2, SerializedTableChunk } from "src/generated-sources";
import { LabelingService } from "../services/labeling.service";
import { getImageURL } from "../utils";

@UntilDestroy()
@Injectable()
export class LabelingSettingsDataFetcherService extends SampleDataFetcherService implements ErrorContext {

    labelingTaskId: string;
    idColumn: string;
    itemPathColIndex: number;

    constructor(private labelingService: LabelingService, private DataikuAPI: DataikuAPIService, dataFormatter: SampleDataFormatterService) {
        super(dataFormatter);
        this.labelingService.labelingTaskInfo$.pipe(untilDestroyed(this)).subscribe((infos) => {
            if (this.projectKey !== infos.getManagedFolderLoc().projectKey ||
                this.managedFolderLoc.id !== infos.getManagedFolderLoc().id ||
                this.labelingTaskId !== infos.labelingTaskId ||
                this.idColumn !== infos.idColumn) {
                this.projectKey = infos.projectKey;
                this.managedFolderLoc = infos.getManagedFolderLoc();
                this.labelingTaskId = infos.labelingTaskId;
                this.idColumn = infos.idColumn;
                this.refetchData();
            }
        });
    }

    refreshSample(): Observable<SerializedMemTableV2> {
        return this.DataikuAPI.labelingTasks.refreshImagesDataSample(this.projectKey, this.labelingTaskId, this.CHUNK_SIZE).pipe(catchAPIError(this, true));
    }

    getSampleChunk(offset: number, nbRows: number): Observable<SerializedTableChunk> {
        return this.DataikuAPI.labelingTasks.getImagesDataChunk(this.projectKey, this.labelingTaskId, nbRows, offset).pipe(catchAPIError(this, true));
    }

    buildImportantColumnsMetadata(): ImportantColumnsMetadata<ColumnMetadata> {
        return {
            single: {
                itemPath: {
                    name: this.idColumn
                }
            }
        }
    }

    setIdColumn(idColumn: string) {
        if (idColumn !== this.idColumn) {
            this.idColumn = idColumn;
            this.updateMetataColumns();
            this.refetchData();
        }
    }

    pushError(error: APIError): void {
        this.labelingService.pushError(error);
    }

    // Need to override because fetching images for labeling from managed folder has specific permissions.
    getImagePath(itemPath: string): string {
        return getImageURL(this.projectKey, this.labelingTaskId, this.managedFolderLoc, itemPath);
    }

}