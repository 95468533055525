import type {Aggregation} from './../../backend/model/aggregation';
import type {ChartFilter} from './chart-filter';
import type {ChartType} from './chart-type';
import type {ChartVariant} from './chart-variant';
import type {DimensionDef} from './dimension-def';
import type {MeasureDef} from './measure-def';
import type {Multiplier} from './multiplier';
import type {NADimensionDef} from './nadimension-def';
import type {NumberFormattingOptions} from './number-formatting-options';

/**
 * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef
 */
export interface ChartDef {
    animationDimension: DimensionDef[];
    animationFrameDuration: number;
    animationRepeat: boolean;
    axis1LogScale: boolean;
    axis2LogScale: boolean;
    boxplotBreakdownDim: DimensionDef[];
    boxplotValue: ChartDef.Unaggregated[];
    bubblesOptions: ChartDef.BubblesOptions;
    chartHeight: number;
    colorMeasure: MeasureDef[];
    colorOptions: ChartDef.ColorOptions;
    computeMode: ChartDef.ComputeMode;
    disableSafetyLimits: boolean;
    displayWithECharts?: boolean | null;
    displayXAxis?: boolean | null;
    displayYAxis?: boolean | null;
    facetDimension: DimensionDef[];
    fillOpacity: number;
    filters: ChartFilter[];
    genericDimension0: DimensionDef[];
    genericDimension1: DimensionDef[];
    genericMeasures: MeasureDef[];
    geoLayers: ChartDef.GeometryLayer[];
    geometry: ChartDef.Unaggregated[];
    groupDimension: DimensionDef[];
    hexbinNumber: number;
    hexbinRadius: number;
    hexbinRadiusMode: ChartDef.HexbinRadiusMode;
    includeZero: boolean;
    legendPlacement: ChartDef.LegendPlacement;
    mapGridOptions: ChartDef.MapGridOptions;
    mapOptions: ChartDef.MapOptions;
    multiTooltips: boolean;
    name: string;
    pieOptions: ChartDef.PieOptions;
    pivotDisplayTotals: ChartDef.PivotTableDisplayTotals;
    pivotMeasureDisplayMode: ChartDef.PivotMeasureDisplayMode;
    scatterOptions: ChartDef.ScatterOptions;
    showInChartLabels: boolean;
    showInChartValues: boolean;
    showLegend: boolean;
    showXAxis: boolean;
    showXAxisLabel: boolean;
    showYAxisLabel: boolean;
    singleXAxis: boolean;
    sizeMeasure: MeasureDef[];
    smoothing: boolean;
    strokeWidth: number;
    thumbnailData: string;
    tooltipMeasures: MeasureDef[];
    type: ChartType;
    uaColor: ChartDef.Unaggregated[];
    uaShape: ChartDef.Unaggregated[];
    uaSize: ChartDef.Unaggregated[];
    uaTooltip: ChartDef.Unaggregated[];
    uaXDimension: NADimensionDef[];
    uaYDimension: NADimensionDef[];
    useLiveProcessingIfAvailable: boolean;
    userEditedName: boolean;
    variant: ChartVariant;
    webAppConfig: {[key: string]: any};
    webAppType: string;
    xAxisLabel: string;
    xAxisMode: ChartDef.XAxisMode;
    xAxisNumberFormattingOptions: NumberFormattingOptions;
    xCustomExtent: ChartDef.CustomAxisExtent;
    xDimension: DimensionDef[];
    xMeasure: MeasureDef[];
    yAxisLabel: string;
    yAxisMode: ChartDef.YAxisMode;
    yAxisNumberFormattingOptions: NumberFormattingOptions;
    yCustomExtent: ChartDef.CustomAxisExtent;
    yDimension: DimensionDef[];
    yMeasure: MeasureDef[];
}

export namespace ChartDef.ColorOptions {
    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$ContinuousColorScaleMode
     */
    export enum ContinuousColorScaleMode {
        NORMAL = 'NORMAL',
        LOG = 'LOG',
        SQRT = 'SQRT',
        SQUARE = 'SQUARE'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$Palette
     */
    export interface Palette {
        colors: string[];
        fixedValues: boolean;
        id: string;
        name: string;
        values: number[];
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$QuantizationMode
     */
    export enum QuantizationMode {
        NONE = 'NONE',
        LINEAR = 'LINEAR',
        QUANTILES = 'QUANTILES'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$PaletteType
     */
    export enum PaletteType {
        CONTINUOUS = 'CONTINUOUS',
        DIVERGING = 'DIVERGING'
    }
}

export namespace ChartDef {
    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$Unaggregated
     */
    export interface Unaggregated {
        adminLevel: number;
        column: string;
        dateMode: string;
        decimalPlaces?: number | null;
        displayLabel?: string | null;
        isA: string;
        multiplier: Multiplier;
        prefix: string;
        suffix: string;
        treatAsAlphanum: boolean;
        type: string;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$LegendPlacement
     */
    export enum LegendPlacement {
        SIDEBAR = 'SIDEBAR',
        INNER_TOP_RIGHT = 'INNER_TOP_RIGHT',
        INNER_TOP_LEFT = 'INNER_TOP_LEFT',
        INNER_BOTTOM_RIGHT = 'INNER_BOTTOM_RIGHT',
        INNER_BOTTOM_LEFT = 'INNER_BOTTOM_LEFT',
        OUTER_TOP = 'OUTER_TOP',
        OUTER_RIGHT = 'OUTER_RIGHT',
        OUTER_BOTTOM = 'OUTER_BOTTOM',
        OUTER_LEFT = 'OUTER_LEFT'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplaySubTotals
     */
    export interface PivotTableDisplaySubTotals {
        columns: boolean;
        rows: boolean;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions
     */
    export interface ColorOptions {
        ccScaleMode: ChartDef.ColorOptions.ContinuousColorScaleMode;
        colorPalette: string;
        customColors: {[key: string]: string};
        customPalette: ChartDef.ColorOptions.Palette;
        heatDensityMapIntensity: number;
        heatDensityMapRadius: number;
        numQuantizeSteps: number;
        paletteMiddleValue: number;
        paletteType: ChartDef.ColorOptions.PaletteType;
        quantizationMode: ChartDef.ColorOptions.QuantizationMode;
        singleColor: string;
        transparency: number;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$GeometryLayer
     */
    export interface GeometryLayer {
        colorOptions: ChartDef.ColorOptions;
        geometry: ChartDef.Geometry[];
        uaColor: ChartDef.Unaggregated[];
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$HexbinRadiusMode
     */
    export enum HexbinRadiusMode {
        ABSOLUTE = 'ABSOLUTE',
        NUM_HEXAGONS = 'NUM_HEXAGONS'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$ScatterOptions
     */
    export interface ScatterOptions {
        equalScales: boolean;
        identityLine: boolean;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$Geometry
     */
    export interface Geometry extends ChartDef.Unaggregated {
        aggregationFunction?: Aggregation.Function | null;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$BubblesOptions
     */
    export interface BubblesOptions {
        defaultRadius: number;
        singleShape: ChartDef.BubbleShape;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$XAxisMode
     */
    export enum XAxisMode {
        NORMAL = 'NORMAL',
        LOG = 'LOG',
        PERCENTAGE_STACK = 'PERCENTAGE_STACK'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$PieOptions
     */
    export interface PieOptions {
        donutHoleSize: number;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$PivotMeasureDisplayMode
     */
    export enum PivotMeasureDisplayMode {
        ROWS = 'ROWS',
        COLUMNS = 'COLUMNS'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplayGrandTotals
     */
    export interface PivotTableDisplayGrandTotals {
        column: boolean;
        row: boolean;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$ComputeMode
     */
    export enum ComputeMode {
        NORMAL = 'NORMAL',
        INDICE_100 = 'INDICE_100',
        CUMULATIVE = 'CUMULATIVE',
        DIFFERENCE = 'DIFFERENCE',
        LOG_SCALE = 'LOG_SCALE',
        PERCENTAGE = 'PERCENTAGE',
        AVG_RATIO = 'AVG_RATIO'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$MapGridOptions
     */
    export interface MapGridOptions {
        gridLatDeg: number;
        gridLonDeg: number;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$CustomAxisExtent
     */
    export interface CustomAxisExtent {
        editMode: ChartDef.ExtentEditMode;
        manualExtent: number[];
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$ExtentEditMode
     */
    export enum ExtentEditMode {
        AUTO = 'AUTO',
        MANUAL = 'MANUAL'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$BubbleShape
     */
    export enum BubbleShape {
        EMPTY_CIRCLE = 'EMPTY_CIRCLE',
        FILLED_CIRCLE = 'FILLED_CIRCLE'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$YAxisMode
     */
    export enum YAxisMode {
        NORMAL = 'NORMAL',
        CUMULATIVE = 'CUMULATIVE',
        DIFFERENCE = 'DIFFERENCE'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$MapOptions
     */
    export interface MapOptions {
        lockSquareGrid: boolean;
        tilesLayer: string;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplayTotals
     */
    export interface PivotTableDisplayTotals {
        grandTotal: ChartDef.PivotTableDisplayGrandTotals;
        subTotals: ChartDef.PivotTableDisplaySubTotals;
    }
}