import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Card, WorksheetRootCard, DataSpec, Insight, CardResult } from 'src/generated-sources';
import { BehaviorSubject, Observable, merge, Subject, combineLatest, EMPTY } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { withLatestFrom, first, switchMap } from 'rxjs/operators';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { WaitingService } from '@core/overlays/waiting.service';
import { CollapsingService } from '../collapsing.service';
import { Transition, INITIAL_STATE } from './state';
import { Processes } from './processes';
import { StateSelectors } from './state';
import {
    editRootCardTransition, addTopLevelCardTransition, addTopLevelCardsTransition,editDataSpecTransition,
    resetFocusedCardTransition, loadWorksheetTransition, setErrorTransition, requestSample,
    renameWorksheetTransition, swapCardsTransition, swapColumnsTransition as swapHeaderCardColumnsTransition
} from './transitions';
import type { IScope } from 'angular';
import { catchAPIError, ErrorContext, APIError } from '@core/dataiku-api/api-error';
import { DebugCardModalComponent, DebugCardModalComponentInput } from '../worksheet/debug-card-modal/debug-card-modal.component';
import { ModalsService, ModalShape } from '@shared/modals/modals.service';
import { ComputeService } from '@features/eda/compute.service';
import { SampleContextService } from '@features/eda/sample-context.service';
import { WT1Service } from 'dku-frontend-core';
import { DEFAULT_TAGGABLE_OBJECT_FIELDS } from '@utils/dss-defaults';
import { FutureWatcherService } from 'dku-frontend-core';


@UntilDestroy()
@Injectable()
export class WorksheetContextService extends SampleContextService implements OnDestroy, ErrorContext {
    // Worksheet state
    private state$ = new BehaviorSubject(INITIAL_STATE);

    // Mutations triggered by user actions
    private transitions$ = new Subject<Transition>();

    // Convenient state selectors
    private selectors = new StateSelectors(this.state$);

    // Expose a few selectors publicly
    public getError = this.selectors.getError.bind(this.selectors);
    public getWorksheet = this.selectors.getWorksheet.bind(this.selectors);
    public getRootCard = this.selectors.getRootCard.bind(this.selectors);
    public getSample = this.selectors.getSample.bind(this.selectors);
    public getDataSpec = this.selectors.getDataSpec.bind(this.selectors);
    public getRootCardResults = this.selectors.getRootCardResults.bind(this.selectors);
    public getFocusedCardId = this.selectors.getFocusedCardId.bind(this.selectors);
    public availableVariables = this.selectors.availableVariables.bind(this.selectors);

    // Actions on the worksheet
    resetFocusedCardTransition() {
        this.transitions$.next(resetFocusedCardTransition());
    }

    editRootCard(rootCard: WorksheetRootCard, immediate?: boolean) {
        this.transitions$.next(editRootCardTransition(rootCard, immediate));
    }

    swapCards(previousIndex: number, currentIndex: number) {
        this.transitions$.next(swapCardsTransition(previousIndex, currentIndex));
    }

    swapHeaderCardColumns(cardId: string, previousIndex: number, currentIndex: number) {
        this.transitions$.next(swapHeaderCardColumnsTransition(cardId, previousIndex, currentIndex));
    }

    forceLoadSample() {
        this.transitions$.next(requestSample());
    }

    addTopLevelCard(card: Card) {
        this.transitions$.next(addTopLevelCardTransition(card));
    }

    addTopLevelCards(cards: Card[]) {
        this.transitions$.next(addTopLevelCardsTransition(cards));
    }

    editDataSpec(dataSpec: DataSpec) {
        this.transitions$.next(editDataSpecTransition(dataSpec));
    }

    loadWorksheet(projectKey: string, id: string) {
        this.transitions$.next(loadWorksheetTransition(projectKey, id));
    }

    editWorksheetName(newName: string) {
        this.transitions$.next(renameWorksheetTransition(newName));
    }

    pushError(error?: APIError) {
        this.transitions$.next(setErrorTransition(error));
    }

    // Actions outside the worksheet
    debugCard(card: Card) {
        this.runInteractiveQuery({type: 'debug_card', card}).pipe(
            this.waitingService.bindStaticOverlay(),
            untilDestroyed(this)
        ).subscribe(debugCardResult => {
            let inputData: DebugCardModalComponentInput = {
                ...debugCardResult,
                // Callback to create cards in the worksheet
                addTopLevelCard: newCard => this.addTopLevelCard(newCard)
            };
            this.modalsService.open(DebugCardModalComponent, inputData, ModalShape.NARROW).catch(() => { })
        });
    }

    computeCard(card: Card): Observable<CardResult | undefined> {
        return this.computeCardWithErrorContext(card, this);
    }

    computeCardWithErrorContext(card: Card, errorContext: ErrorContext): Observable<CardResult | undefined> {
        return combineLatest([
            this.selectors.getWorksheet(),
            this.selectors.getSample()
        ]).pipe(
            first(),
            switchMap(([worksheet, sample]) =>
                worksheet && sample ? this.computeService.computeCard(card, sample.id, false)
                    .pipe(catchAPIError(errorContext)) : EMPTY
            )
        );
    }

    // Startup
    constructor(
        DataikuAPI: DataikuAPIService,
        futureWatcherService: FutureWatcherService,
        private waitingService: WaitingService,
        collapsingService: CollapsingService,
        wt1Service: WT1Service,
        private modalsService: ModalsService,
        private computeService: ComputeService,
        @Inject('CreateModalFromTemplate') private createOldModal: any,
        @Inject('$rootScope') private $rootScope: IScope,
    ) {
        super(DataikuAPI, futureWatcherService);

        const backgroundProcesses = new Processes(DataikuAPI, waitingService,
            collapsingService, this.selectors, computeService, wt1Service);

        merge(backgroundProcesses.getAllProcesses(), this.transitions$).pipe(
            withLatestFrom(this.state$),
            untilDestroyed(this)
        ).subscribe(([reducer, state]) => {
            const newState = reducer(state);
            this.state$.next(newState);
        });
    }

    createInsight(card: Card, name: string): void {
        combineLatest([
            this.getDataSpec(),
            this.getWorksheet(),
            this.computeCard(card)
        ]).pipe(first(), catchAPIError(this))
            .subscribe(([dataSpec, worksheet, cardResult]) => {
                if (!dataSpec || !worksheet || !cardResult) {
                    return;
                }

                const insight: Insight = {
                    ...DEFAULT_TAGGABLE_OBJECT_FIELDS,
                    params: { card, dataSpec },
                    dashboardCreationId: 'will-be-replaced-by-modal',
                    listed: true,
                    name,
                    projectKey: worksheet.projectKey,
                    type: 'eda',
                };
                return this.createOldModal('/templates/dashboards/insights/create-and-pin-insight-modal.html',
                    this.$rootScope, 'CreateAndPinInsightModalController', (newScope: any) => {
                        newScope.init(insight, null, JSON.stringify(cardResult!));
                        // An exception is raised when the user clicks cancel. Let's silently ignore it
                    }).catch(() => { });
            });
    }

    ngOnDestroy() {
    }
}
