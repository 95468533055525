import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PainterService } from '@shared/services/image-feed/painter.service';
import { DeepHubPredictionModelDetails, DeepHubPredictionModelPerf } from 'src/generated-sources';
import { DeephubImageClassificationReportDataFetcherService } from './services/deephub-image-classification-report-data-fetcher.service';
import { DeephubImageClassificationReportPainterService } from './services/deephub-image-classification-report-painter.service';
import { DeephubImageClassificationReportService } from './services/deephub-image-classification-report.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { ImagesDataFetcherService } from '@shared/services/image-feed/images-data-fetcher.service';
import { DeephubImageClassificationReportDataFormatterService } from './services/deephub-image-classification-report-data-formatter.service';
import { SampleDataFormatterService } from '@shared/services/image-feed/sample-data-formatter.service';
import { SampleDataFetcherService } from '@shared/services/image-feed/sample-data-fetcher.service';

@Component({
    selector: 'deephub-image-classification-report',
    templateUrl: './deephub-image-classification-report.component.html',
    styleUrls: ['../../shared-styles/deephub-report.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ColorMapContextService,
        DeephubImageClassificationReportPainterService,
        DeephubImageClassificationReportDataFetcherService,
        DeephubImageClassificationReportDataFormatterService,
        { provide: SampleDataFormatterService, useExisting: DeephubImageClassificationReportDataFormatterService},
        { provide: ImagesDataFetcherService, useExisting: DeephubImageClassificationReportDataFetcherService },
        { provide: SampleDataFetcherService, useExisting: DeephubImageClassificationReportDataFetcherService },
        { provide: PainterService, useExisting: DeephubImageClassificationReportPainterService },
    ]
})
export class DeephubImageClassificationReportComponent {
    @Input() modelData: DeepHubPredictionModelDetails;

    constructor(
        private imageClassificationService: DeephubImageClassificationReportService
    ) { }

    ngOnChanges(): void {
        this.imageClassificationService.updateReport({
            coreParams: this.modelData.coreParams,
            fullModelId: this.modelData.fullModelId,
            perf: this.modelData.perf as DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf,
            categories: this.modelData.preprocessing.target_remapping.map(label => label.sourceValue),
        });
    }
}
