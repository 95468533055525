import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { APIError } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { WorkspaceLocalStorageService } from '@features/workspaces/shared';
import { Workspace } from '@model-main/workspaces/workspace';
import { Insight } from '@model-main/dashboards/model/insight';
import { DatasetOptions } from '@migration/modules';
import { AppConfig } from '@shared/models';
import { Observable, BehaviorSubject, of, forkJoin, combineLatest, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { InsightViewer } from '../object-viewer-host';

@Component({
    selector: 'dataset-viewer',
    templateUrl: './dataset-viewer.component.html',
    styleUrls: ['./dataset-viewer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetViewerComponent extends InsightViewer implements OnInit {
    appConfig$ = new BehaviorSubject<AppConfig | null | undefined>(undefined);
    workspaceKey$ = new BehaviorSubject<string | null | undefined>(undefined);
    projectKey$ = new BehaviorSubject<string | null | undefined>(undefined);
    object$ = new BehaviorSubject<Workspace.WorkspaceObject | null | undefined>(undefined);

    datasetOptions$: Observable<DatasetOptions | undefined>;

    @Input()
    set appConfig(value: AppConfig | undefined) {
        this.appConfig$.next(value);
    }

    @Input()
    set workspaceKey(value: string | undefined) {
        this.workspaceKey$.next(value);
    }

    @Input()
    set projectKey(value: string | undefined) {
        this.workspaceKey$.next(value);
    }

    @Input()
    set object(value: Workspace.WorkspaceObject | undefined) {
        this.object$.next(value);
    }

    constructor(
      private workspaceLocalStorageService: WorkspaceLocalStorageService,
      private dataikuApiService: DataikuAPIService
    ) {
        super();
    }

    private getShakerScript(projectKey: string, name: string): Observable<any> {
        return this.dataikuApiService.explores.get(projectKey, name)
            .pipe(
                map(data => data.script),
                catchError((err: APIError) => {
                    this.handleError(err);
                    return throwError(() => err);
                })
            );
    }

    ngOnInit(): void {
        this.datasetOptions$ = combineLatest([this.appConfig$, this.workspaceKey$, this.object$])
            .pipe(
                map(([appConfig, workspaceKey, object]) => {
                    if (appConfig && workspaceKey && object) {
                        const userId = appConfig.login;

                        const datasetOptions: DatasetOptions = {
                            canWriteProject: () => false, // function must be defined (called by the dataset insight viewer)
                            refreshTableFailed: (err: APIError) => this.handleError(err),
                            isLocalSave: true,
                            hook: {
                                onSave: (params: {[key: string]: string}) => {
                                    this.workspaceLocalStorageService.saveLocalObjectParams(userId, workspaceKey, object, params);
                                }
                            }
                        };

                        return datasetOptions;
                    }

                    return;
                })
            );

        this.insight$ = combineLatest([this.appConfig$, this.workspaceKey$, this.object$, this.options$])
            .pipe(
                switchMap(([appConfig, workspaceKey, object, options]) => {
                    if (appConfig && workspaceKey && object && options) {
                        const userId = appConfig.login;
                        const params = this.workspaceLocalStorageService.getLocalObjectParams(userId, workspaceKey, object);

                        if (params) {
                            return of([options, params.shakerScript]);
                        } else {
                            return forkJoin([of(options), this.getShakerScript(options.projectKey, options.name)]);
                        }
                    }

                    return throwError(() => 'Missing options');
                }),
                map(([options, shakerScript]) => {
                    const insight: Partial<Insight> = {
                        ...options,
                        params: {
                            shakerScript,
                            shakerState: {
                                writeAccess: false,
                                filtersExplicitlyAllowed: true
                            },
                            datasetSmartName: options.name
                        }
                    };

                    return insight;
                })
            );
    }

}
