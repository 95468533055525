import { Injectable } from "@angular/core";
import { APIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { CurrentRouteService } from "@core/nav/current-route.service";
import { WaitingService } from "@core/overlays/waiting.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CellData } from "@shared/services/image-feed/images-data-fetcher.service";
import { BehaviorSubject, EMPTY, forkJoin, from, Observable, of, Subject, zip } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { InteractiveModelParams } from "src/generated-sources";


export abstract class DeephubInteractiveScoringCellData implements CellData {
    private readonly SRC_REGEXP = /^data\:image\/(?<format>\w+);base64,(?<base64Repr>[A-Za-z0-9+/=]+)$/; 
    
    itemPath: string;  // holding the base 64 url representation (e.g. "data:image/png;base64,XXXXX") of the image to fit the DataFetcher API

    fileName: string;
    format: string;
    base64Repr: string;
    score: any;
    selected: boolean;
    imageId: number;

    constructor(imageSrcAsBase64: string, fileName: string) {
        this.itemPath = imageSrcAsBase64;
        this.fileName = fileName;

        const match = this.SRC_REGEXP.exec(imageSrcAsBase64);

        if (!match) {
            throw new Error("Passed string is not a base64 url " + imageSrcAsBase64);
        }

        this.format = match.groups?.format!;
        this.base64Repr = match.groups?.base64Repr!;
    }

    public abstract setScore(modelResponse: any): void;
    public abstract setExplanation(explanations: any): void;

    public setIndex(index: number) {
        this.imageId = index;
    }
}

@UntilDestroy()
@Injectable()
export abstract class InteractiveScoringService implements ErrorContext {
    loadedCellDataItems$ = new BehaviorSubject<DeephubInteractiveScoringCellData[]>([]);
    loading$ = new BehaviorSubject<boolean>(false);
    imagesUploaded$ = new Subject<DeephubInteractiveScoringCellData[]>();
    private error$ = new BehaviorSubject<APIError | undefined>(undefined);

    get SESSION_STORAGE_KEY(): string {
        return `dku.whatif.${this.currentRouteService.fullModelId}`;
    }

    constructor(
        private DataikuAPI: DataikuAPIService, 
        private currentRouteService: CurrentRouteService, 
        private waitingService: WaitingService
    ) {
        this.imagesUploaded$.pipe(
            switchMap((uploadedCellDataItems) => {
                const responses$ = uploadedCellDataItems.map(item => this.DataikuAPI.analysis.computeWithInteractiveModel(this.currentRouteService.fullModelId, this.createComputationParams([item])).pipe(
                    this.waitingService.bindOverlayAndWaitForResult(),
                    catchError((error: APIError) => {
                        this.loading$.next(false);
                        this.pushError(error);
                        return EMPTY;
                    })
                ));
                return zip(
                    of(uploadedCellDataItems),
                    forkJoin(responses$)
                );
            }),
            map(([uploadedCellData, modelResponse]) => {
                uploadedCellData.forEach((uploadedCellData, index) => {
                    const model = modelResponse[index];
                    uploadedCellData.setScore(model.scores[0]);
                    if (model.explanations && model.explanations[0]){
                        uploadedCellData.setExplanation(model.explanations[0]);
                    }
                }); 
                return uploadedCellData;
            }),
            withLatestFrom(this.loadedCellDataItems$),
            untilDestroyed(this)
        ).subscribe(([uploadedCellDataItems, loadedCellDataItems]) => {
            const newLoadedCellDataItems = [...uploadedCellDataItems, ...loadedCellDataItems];
            newLoadedCellDataItems.forEach((cellData, index) => cellData.setIndex(index))

            try {
                sessionStorage.setItem(
                    this.SESSION_STORAGE_KEY,
                    // store partial to not overload session storage
                    JSON.stringify(newLoadedCellDataItems.map((item: Partial<DeephubInteractiveScoringCellData>) => ({
                        itemPath: item.itemPath,
                        fileName: item.fileName
                    })))
                );
            } catch (e) {
                console.warn(e);
            }

            this.loadedCellDataItems$.next(newLoadedCellDataItems);
            this.loading$.next(false);
        });
    }

    abstract createCellData(image: string, file: string): DeephubInteractiveScoringCellData;

    getLoading(): Observable<boolean> {
        return this.loading$;
    }

    getLoadedCellDataItems(): BehaviorSubject<DeephubInteractiveScoringCellData[]> {
        return this.loadedCellDataItems$;
    }

    loadStoredImages() {
        let images: Partial<DeephubInteractiveScoringCellData>[] = (JSON.parse(sessionStorage.getItem(this.SESSION_STORAGE_KEY)!) || []).filter((image: Partial<DeephubInteractiveScoringCellData>) => image.itemPath && image.fileName); // ensure that items have an item path and a file name
        
        if (images.length) {
            // ensure we have an item path and file name
            this.loading$.next(true);
            this.imagesUploaded$.next(images.map(image => this.createCellData(image.itemPath!, image.fileName!)));
        }
    }

    clearImages() {
        this.loadedCellDataItems$.next([]);
        sessionStorage.removeItem(this.SESSION_STORAGE_KEY);
    }

    private uploadImageAsBase64(file: File): Observable<DeephubInteractiveScoringCellData> {
        return from(new Promise<DeephubInteractiveScoringCellData>((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = (event: ProgressEvent<FileReader>) => {
                if (reader.result) {
                    resolve(this.createCellData(reader.result as string, file.name));
                }
            }
            reader.readAsDataURL(file)
        }));
    }

    uploadFiles(files: FileList) {
        this.loading$.next(true);
        
        const uploadedFiles$ = [] as Observable<DeephubInteractiveScoringCellData>[];
        for (var i = 0; i < files.length; i++) { 
            uploadedFiles$.push(this.uploadImageAsBase64(files[i]));
        }

        zip(...uploadedFiles$).subscribe((uploadedFiles) => {
            this.imagesUploaded$.next(uploadedFiles);
        })
    }

    protected abstract getNbExplanations(): number;

    private createComputationParams(uploadedCellDataItems: DeephubInteractiveScoringCellData[]): InteractiveModelParams.ComputationParams {
        const nExplanations = this.getNbExplanations();
        const computationType = nExplanations > 0 ? InteractiveModelParams.ExplanationsParams.type: InteractiveModelParams.ScoringParams.type;
        let params = {
            fullModelId: this.currentRouteService.fullModelId,
            records: uploadedCellDataItems.map(uploadedCellData => ({
                input: uploadedCellData.base64Repr
            })),
            applyPreparationScript: false,
            type: computationType 
        } ;

        if (computationType === InteractiveModelParams.ExplanationsParams.type)
        {
            return { nExplanations: nExplanations, ...params } as InteractiveModelParams.ExplanationsParams;
        }        
        return params as InteractiveModelParams.ScoringParams;
    }

    pushError(error?: APIError): void {
        this.error$.next(error);
    }

    getError() {
        return this.error$;
    }
}
