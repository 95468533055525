import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { DKUApp, UsageDataReporting } from '../../generated-sources';
import { enableWT1 } from './wt1';
import { Md5 } from 'ts-md5';

export abstract class WT1Service {
    abstract event(type: string, params: { [index: string]: any }): void;
    abstract configure(args: {
        nodeType?: string;
        dipInstanceId?: string;
        installId?: string;
        licenseKind?: string;
        distrib?: string;
        distribVersion?: string;
        registrationChannel?: string;
        isAutomation?: boolean;
        isGovern?: boolean;
        version?: DKUApp.DSSVersion;
        login?: string;
        email?: string;
        userProfile?: string;
        udrReportingMode?: UsageDataReporting.UsageDataReportingMode;
        udrPrivacyMode?: UsageDataReporting.UsageDataReportingPrivacyMode;
        devInstance?: boolean;
        deploymentMode?: string;
    }): void;

    abstract setVisitorParam(key: string, value: any): void;
    abstract delVisitorParam(key: string): void;
    abstract setSessionParam(key: string, value: any): void;
    abstract delSessionParam(key: string): void;
}


/**
 * Angular 2+ WT1 service.
 * 
 * It is only used for Govern and has not been tested with DSS, that use the angular.js legacyWT1Service implementation.
 */
@Injectable()
export class StandaloneWT1Service extends WT1Service {
    private configured = false;
    private enabled = true;
    private devInstance = false;
    private dlid: string;
    private unconfiguredEvents: { eventType: string, eventParams: { [index: string]: any } }[] = [];
    private udrMode: UsageDataReporting.UsageDataReportingMode;
    private udrPrivacyMode: UsageDataReporting.UsageDataReportingPrivacyMode;
    private login: string;
    private email: string;
    private userProfile: string;
    private scriptLoaded = false;

    private dkuHashCode(value: string): number {
        let hash = 0;
        let char: number;
        if (value.length === 0) {
            return hash;
        }
        for (let i = 0; i < value.length; i++) {
            char = value.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hash = ((hash << 5) - hash) + char;
            // eslint-disable-next-line no-bitwise
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    private get wt1Queue() {
        if (!("_wt1Q" in window)) {
            (window as any)._wt1Q = [];
        }
        return (window as any)._wt1Q;
    }

    configure(args: {
        nodeType?: string;
        dipInstanceId?: string;
        installId?: string;
        licenseKind?: string;
        distrib?: string;
        distribVersion?: string;
        registrationChannel?: string;
        isAutomation?: boolean;
        isGovern?: boolean;
        version?: DKUApp.DSSVersion;
        login?: string;
        email?: string;
        userProfile?: string;
        udrReportingMode?: UsageDataReporting.UsageDataReportingMode;
        udrPrivacyMode?: UsageDataReporting.UsageDataReportingPrivacyMode;
        devInstance?: boolean;
        deploymentMode?: string;
    }): void {
        this.dlid = (Math.random().toString(36) + '00000000000000000').slice(2, 12);
        if (args.dipInstanceId) {
            this.wt1Queue.push(['setVisitorParam', 'dipInstanceId', args.dipInstanceId]);
        }
        if (args.installId) {
            this.wt1Queue.push(['setVisitorParam', 'installId', args.installId]);
        }
        if (args.licenseKind) {
            this.wt1Queue.push(['setVisitorParam', 'dssLicenseKind', args.licenseKind]);
        }
        if (args.distrib) {
            this.wt1Queue.push(['setVisitorParam', 'bkdDistrib', args.distrib]);
        }
        if (args.distribVersion) {
            this.wt1Queue.push(['setVisitorParam', 'bkdDistribVersion', args.distribVersion]);
        }
        if (args.registrationChannel) {
            this.wt1Queue.push(['setVisitorParam', 'regChannel', args.registrationChannel]);
        }
        if (args.isAutomation !== undefined) {
            this.wt1Queue.push(['setVisitorParam', 'isAutomation', args.isAutomation]);
        }

        if (args.isGovern !== undefined) {
            this.wt1Queue.push(['setVisitorParam', 'isGovern', args.isGovern]);
        }

        if (args.deploymentMode) {
            this.wt1Queue.push(["setVisitorParam", "deploymentMode", args.deploymentMode]);
        }
        if (args.nodeType) {
            this.wt1Queue.push(["setVisitorParam", "nodeType", args.nodeType]);
        }

        this.wt1Queue.push(['setVisitorParam', 'dssVersion', args.version?.product_version ?? 'unknown']);
        if (args.login) {
            this.wt1Queue.push(['setSessionParam', 'dssUser', this.dkuHashCode(args.login)]);
            this.wt1Queue.push(['setVisitorParam', 'vdssUser', this.dkuHashCode(args.login)]);
            this.login = args.login;
        }
        if (args.email) {
            this.email = args.email;
        }
        if (args.userProfile) {
            this.userProfile = args.userProfile;
        }
        if (args.devInstance !== undefined) {
            this.devInstance = args.devInstance;
        }
        if (args.udrPrivacyMode) {
            this.udrPrivacyMode = args.udrPrivacyMode;
        }
        if (args.udrReportingMode) {
            this.udrMode = args.udrReportingMode;
        }

        this.enabled = !(this.udrMode === UsageDataReporting.UsageDataReportingMode.NO || this.devInstance);
        const wasConfigured = this.configured;
        this.configured = true;
        if (this.enabled) {
            //Be sure to load wt1.js only once
            if (!this.scriptLoaded) {
                enableWT1();
                this.scriptLoaded = true;
            }
            if (!wasConfigured) {
                this.unconfiguredEvents.forEach(({ eventType, eventParams }) => this.event(eventType, eventParams));
            }
        }
    }

    event(type: string, params: { [index: string]: any } = {}): void {
        if (!this.configured) {
            // store the event while the service is not configured
            this.unconfiguredEvents.push({ eventType: type, eventParams: params });
            return;
        }

        // When params contains object litterals, class instances we must
        // serialize them before sending them to the event queue.
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                const value = params[key];

                if (Object.prototype.toString.call(value) === '[object Object]') {
                    params[key] = JSON.stringify(value);
                }
            }
        }

        if (this.login) {
            params["edssUser"] = this.dkuHashCode(this.login);
            params["loginh"] = Md5.hashStr(this.login.toLowerCase());
            if (this.udrPrivacyMode === UsageDataReporting.UsageDataReportingPrivacyMode.DEFAULT) {
                params["login"] = this.login;
            }
        }

        if (this.email) {
            params["emailh"] = Md5.hashStr(this.email.toLowerCase());
            if (this.udrPrivacyMode === UsageDataReporting.UsageDataReportingPrivacyMode.DEFAULT) {
                params["email"] = this.email;
            }
        }

        if (this.userProfile) {
            params["userProfile"] = this.userProfile;
        }

        if (this.devInstance) {
            const formattedDate = formatDate(new Date(), 'HH:mm:ss.SSS', 'en-US', 'UTC');
            // eslint-disable-next-line no-console
            console.debug('[' + formattedDate + '] WT1: ' + type, params);
        }
        if (!this.enabled || (this.udrMode == "OPEN_ONLY" && !this.eventIsEnabledInOpenOnlyMode(type))) {
            return;
        }
        params.type = type;
        params.dlid = this.dlid;

        this.wt1Queue.push(['trackEvent', params]);

    }

    setVisitorParam(key: string, value: any) {
        this.wt1Queue.push(["setVisitorParam", key, value]);
    }

    delVisitorParam(key: string) {
        this.wt1Queue.push(["delVisitorParam", key]);
    }

    setSessionParam(key: string, value: any) {
        this.wt1Queue.push(["setSessionParam", key, value]);
    }

    delSessionParam(key: string) {
        this.wt1Queue.push(["delSessionParam", key]);
    }

    private eventIsEnabledInOpenOnlyMode(type: string) {
        return ["studio-open", "govern-open",
            "nps-survey", "nps-survey-decline",
            "action-triggered-survey", "action-triggered-survey-decline"].includes(type);
    }
}

@Injectable()
export class LegacyWT1Service extends WT1Service {
    configure(args: {
        nodeType?: string;
        dipInstanceId?: string;
        installId?: string;
        licenseKind?: string;
        distrib?: string;
        distribVersion?: string;
        registrationChannel?: string;
        isAutomation?: boolean;
        isGovern?: boolean;
        version?: DKUApp.DSSVersion;
        login?: string;
        email?: string;
        userProfile?: string;
        udrReportingMode?: UsageDataReporting.UsageDataReportingMode;
        udrPrivacyMode?: UsageDataReporting.UsageDataReportingPrivacyMode;
        devInstance?: boolean;
        deploymentMode?: string;
    }): void {
        console.warn('Cannot configure WT1 service when configured to use the AngularJS service');
    }

    private get wt1Svc(): any | undefined {
        // Retrieve the WT1 AngularJS service referenced in window (no configuration needed)
        if (!("WT1SVC" in window)) {
            console.warn('WT1 AngularJS service is not found');
            return undefined;
        }
        return (window as any).WT1SVC;
    }

    event(type: string, params: { [index: string]: any } = {}): void {
        this.wt1Svc?.event(type, params);
    }

    setVisitorParam(key: string, value: any) {
        this.wt1Svc?.push(["setVisitorParam", key, value]);
    }

    delVisitorParam(key: string) {
        this.wt1Svc?.push(["delVisitorParam", key]);
    }

    setSessionParam(key: string, value: any) {
        this.wt1Svc?.push(["setSessionParam", key, value]);
    }

    delSessionParam(key: string) {
        this.wt1Svc?.push(["delSessionParam", key]);
    }

}