import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PainterService } from '@shared/services/image-feed/painter.service';
import { DeepHubPredictionModelDetails, DeepHubPredictionModelPerf } from 'src/generated-sources';
import { DeephubObjectDetectionReportDataFetcherService } from './services/deephub-object-detection-report-data-fetcher.service';
import { DeephubObjectDetectionReportPainterService } from './services/deephub-object-detection-report-painter.service';
import { DeephubObjectDetectionReportService } from './services/deephub-object-detection-report.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { ImagesDataFetcherService } from '@shared/services/image-feed/images-data-fetcher.service';
import { SampleDataFetcherService } from '@shared/services/image-feed/sample-data-fetcher.service';
import { SampleDataFormatterService } from '@shared/services/image-feed/sample-data-formatter.service';
import { DeephubObjectDetectionReportDataFormatterService } from './services/deephub-object-detection-report-data-formatter.service';

@Component({
    selector: 'deephub-object-detection-report',
    templateUrl: './deephub-object-detection-report.component.html',
    styleUrls: ['../../shared-styles/deephub-report.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ColorMapContextService,
        DeephubObjectDetectionReportPainterService,
        DeephubObjectDetectionReportDataFetcherService,
        DeephubObjectDetectionReportDataFormatterService,
        { provide: SampleDataFormatterService, useExisting: DeephubObjectDetectionReportDataFormatterService},
        { provide: ImagesDataFetcherService, useExisting: DeephubObjectDetectionReportDataFetcherService },
        { provide: SampleDataFetcherService, useExisting: DeephubObjectDetectionReportDataFetcherService },
        { provide: PainterService, useExisting: DeephubObjectDetectionReportPainterService },
    ]
})
export class DeephubObjectDetectionReportComponent {
    // replace inputs with modelData
    @Input() modelData: DeepHubPredictionModelDetails;
    @Input() iou: number;
    @Input() confidenceThreshold: number;
    @Input() tab: string;

    constructor(
        private objectDetectionService: DeephubObjectDetectionReportService
    ) { }

    ngOnChanges(): void {
        this.objectDetectionService.updateReport({
            coreParams: this.modelData.coreParams,
            fullModelId: this.modelData.fullModelId,
            perf: this.modelData.perf as DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf,
            categories: this.modelData.preprocessing.target_remapping.map(label => label.sourceValue),
            confidenceThreshold: this.confidenceThreshold,
            iou: this.iou
        });
    }
}
