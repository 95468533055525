import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { DataikuWT1Module } from 'dku-frontend-core';
import { MigrationModule } from '@migration/migration.module';
import { UIRouterModule } from '@migration/modules';
import { ComponentsModule } from '@shared/components/components.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FacetListComponent } from './facet-list/facet-list.component';
import { FeatureGroupDetailsComponent } from './feature-group-details/feature-group-details.component';
import { FeatureStoreComponent } from './feature-store.component';
import { FeatureStoreService } from './feature-store.service';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchResultsComponent } from './search-results/search-results.component';

@NgModule({
  declarations: [
    FeatureStoreComponent,
    SearchBarComponent,
    SearchResultsComponent,
    FeatureGroupDetailsComponent,
    FacetListComponent,
  ],
  exports: [
    FeatureStoreComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule,
    PipesModule,
    ReactiveFormsModule,
    WidgetsModule,
    DataikuAPIModule,
    ComponentsModule,
    MigrationModule,
    DataikuWT1Module
  ],
  providers: [
    FeatureStoreService,
  ]
})
export class FeatureStoreModule { }
