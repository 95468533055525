import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, NgZone, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Details } from '@core/dataiku-api/definitions/private-feature-store-api';
import { Column } from '@shared/components/right-panel-summary/right-panel-schema/right-panel-schema.component';
import { PromptService } from '@shared/modals/prompt.service';
import { FriendlyTimeDeltaShortPipe } from '@shared/pipes/date-pipes/friendly-time-delta-short.pipe';
import { EscapeHtmlPipe } from '@shared/pipes/escaping/escape-html.pipe';
import { fairAny, InfoMessage } from 'dku-frontend-core';
import { FeatureStoreService } from '../feature-store.service';
import { Highlight, Hit } from '../query-result-models';

export interface HitWithRelated extends Hit {
  splitInputsByType?: fairAny
  splitOutputsByType?: fairAny
}

@Component({
  selector: 'feature-group-details',
  templateUrl: './feature-group-details.component.html',
  styleUrls: ['./feature-group-details.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureGroupDetailsComponent implements OnChanges {
  @Input() projectKey: string | undefined;
  @Input() selectedItem: HitWithRelated | null;
  @Input() details: Details;
  @Input() users: Record<string, string>;
  @Input() datasetsInCurrentProject: string[];
  @Output() removedFromFeatureStore: EventEmitter<Hit>;
  @Output() refreshSelectedItem: EventEmitter<Hit>;

  numberOfUsages: number;
  showUsage: boolean;
  useInProjectTitle: string;

  datasetFeatures: Column[] = [];
  featuresHighlighting: string[] = []; // merges the column and column.plaintext highlighting from the highlight field of ES

  escapeHtml: EscapeHtmlPipe;
  friendlyTimeDeltaShort: FriendlyTimeDeltaShortPipe;

  constructor(
    @Inject('$rootScope') public $rootScope: fairAny,
    @Inject('$scope') public $scope: fairAny,
    @Inject('Navigator') private navigator: fairAny,
    @Inject("StateUtils") private stateUtils: fairAny,
    @Inject('$state') private $state: fairAny,
    @Inject("ExposedObjectsService") private exposedObjectsService: fairAny,
    @Inject("CatalogRefreshUtils") private catalogRefreshUtils: fairAny,
    @Inject('TypeMappingService') private readonly typeMappingService: fairAny,
    @Inject("ActivityIndicator") private activityIndicator: fairAny,
    private promptService: PromptService,
    private featureStoreService: FeatureStoreService,
    private ngZoneService: NgZone,
  ) {
    this.removedFromFeatureStore = new EventEmitter();
    this.refreshSelectedItem = new EventEmitter();
    this.escapeHtml = new EscapeHtmlPipe();
    this.friendlyTimeDeltaShort = new FriendlyTimeDeltaShortPipe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItem && this.selectedItem) {
      if(this.featureGroupUsedInCurrentProject()) {
        this.useInProjectTitle = "This Feature Group is already used in current project.";
      } else {
        const useInProjectLabel = "Use in project" + (this.projectKey ? " " + this.projectKey: "");
        this.useInProjectTitle = !this.disableUseInProject() ? useInProjectLabel : "This Feature Group is not quickly shareable and access requests are not enabled for the source project";
      }
      this.featuresHighlighting = (this.selectedItem.highlight?.column || []).concat(this.selectedItem.highlight?.['column.plaintext'] || []);
      this.catalogRefreshUtils.computeDatasetInputsOutputsByType(this.selectedItem);
    }
    if(changes.details && this.details) {
      this.numberOfUsages = this.details.usages.projectsWithAccess.length + this.details.usages.usagesInNonAccessibleProjects;
      this.showUsage = this.numberOfUsages < 10;
    }
    if((changes.selectedItem || changes.details)  && this.selectedItem  && this.details) {
      // we use the meaning from the fullDatasetInfo and not from ES so that it's updated immediately (no indexation delay)
      this.datasetFeatures = this.selectedItem._source.columns.map(col => ({
        name: col.name,
        comment: col.comment,
        type: col.type,
        meaning: this.details.datasetFullInfo.dataset.schema.columns.find(c => c.name === col.name)?.meaning || null
      }));
    }
  }

  itemType(): string {
    return this.typeMappingService.mapDatasetTypeToName(this.selectedItem?._source.type_raw);
  }

  /**
   * gets the first highlighted value for the field, or undefined if no highligh exists for the field
   */
  private getHighlightFor(field: keyof Highlight) {
    return this.selectedItem?.highlight?.[field]?.[0];
  }

  formatItemName(): string {
    return this.getHighlightFor('name')
      ?? this.getHighlightFor('name.raw')
      ?? this.getHighlightFor('name.plaintext')
      ?? this.escapeHtml.transform(this.selectedItem?._source.name || "");
  }

  linkToProject(projectKey: string): string {
    return this.stateUtils.href.project(
      projectKey
    );
  }

  formatProjectName(): string {
    return this.getHighlightFor('projectName')
      ?? this.getHighlightFor('projectName.plaintext')
      ?? (this.selectedItem?._source.projectName || "");
  }

  hasShortDescHighlighted(): boolean {
    return (this.selectedItem?.highlight && (this.selectedItem.highlight.shortDesc?.length || this.selectedItem.highlight["shortDesc.plaintext"]?.length) || 0) > 0;
  }

  formatShortDesc(): string {
    const highlightedShortDesc = this.getHighlightFor('shortDesc');
    const highlightedShortDescPlainText = this.getHighlightFor('shortDesc.plaintext');
    return highlightedShortDesc
      ? this.formatHighlighted(highlightedShortDesc)
      : highlightedShortDescPlainText
        ? this.formatHighlighted(highlightedShortDescPlainText)
        : this.selectedItem?._source.shortDesc || "";
  }

  hasDescriptionHighlighted(): boolean {
    return (this.selectedItem?.highlight && (this.selectedItem.highlight.description?.length || this.selectedItem.highlight["description.plaintext"]?.length) || 0) > 0;
  }

  formatDescription(): string {
    const highlightedDescription = this.getHighlightFor('description');
    const highlightedDescriptionPlainText = this.getHighlightFor('description.plaintext');
    return highlightedDescription
      ? this.formatHighlighted(highlightedDescription)
      : highlightedDescriptionPlainText
        ? this.formatHighlighted(highlightedDescriptionPlainText)
        : this.selectedItem?._source.description || "";
  }

  formatHighlighted(highlighted: string): string {
    return highlighted
      .replace(/<em>/g, "((STARTEM))").replace(/<\/em>/g, "((ENDEM))")
      .replace(/\(\(STARTEM\)\)/g, '<em class="highlight">').replace(/\(\(ENDEM\)\)/g, "</em>");
  }

  useInProject(): void {
    if (!this.disableUseInProject() && this.selectedItem) {
      if (this.details.datasetFullInfo.objectAuthorizations.canManageExposedElements || this.selectedItem._source.isQuicklyShareable) {
        if(this.projectKey) {
          this.featureStoreService.exposeToProject(
            this.selectedItem._source.projectKey,
            this.selectedItem._type.toUpperCase(),
            this.selectedItem._source.id,
            this.projectKey,
          )
          .subscribe((result: InfoMessage) => {
            if(result.severity === InfoMessage.Severity.ERROR) {
              this.ngZoneService.runOutsideAngular(() => {
                this.activityIndicator.info(result.title, 5000);
                this.$scope.$apply();
              });
            } else {
              this.$state.go("projects.project.flow", {
                projectKey: this.projectKey,
                id: 'dataset_' + this.selectedItem!._source.projectKey + '.' + this.selectedItem!._source.id
              });
            }
          });
        } else {
          this.exposedObjectsService.exposeSingleObject(
            this.selectedItem._type.toUpperCase(),
            this.selectedItem._source.id,
            this.selectedItem._source.name,
            this.selectedItem._source.projectKey
          ).then(() => this.refreshSelectedItem.emit(this.selectedItem!));
        }
      }
      else if (this.details.datasetFullInfo.objectAuthorizations.isObjectSharingRequestEnabled) {
        this.exposedObjectsService.requestSharing(
          this.selectedItem._type.toUpperCase(),
          this.selectedItem._source.id,
          this.selectedItem._source.name,
          this.selectedItem._source.projectKey,
          this.projectKey
        ).then(() => this.refreshSelectedItem.emit(this.selectedItem!));
      }
    }
  }

  featureGroupUsedInCurrentProject() {
    return !!this.projectKey && !!this.selectedItem &&
      (
        this.datasetsInCurrentProject.includes(this.selectedItem._source.projectKey + "." + this.selectedItem._source.id)
      );
  }

  disableUseInProject(): boolean {
    return !this.selectedItem || (
      !this.selectedItem._source.isQuicklyShareable &&
      !this.details.datasetFullInfo.objectAuthorizations.canManageExposedElements &&
      !this.details.datasetFullInfo.objectAuthorizations.isObjectSharingRequestEnabled
    ) || this.featureGroupUsedInCurrentProject();
  }

  removeFromFeatureStore(): void {
    if (this.$rootScope.appConfig.globalPermissions.mayManageFeatureStore) {
      this.promptService.confirm('Remove from Feature Store', 'Remove this Feature Group from the Feature Store?')
        .then(_ => this.removedFromFeatureStore.emit(this.selectedItem!))
        .catch(() => { }); // silently ignoring the error raised when we cancel the removal
    }
  }

  showNavigator(projectKey: string, id: string): void {
    this.navigator.show(projectKey, "DATASET", id);
  }

  flowLink(projectKey: string, name: string): string {
    return this.stateUtils.href.flowLink({
      nodeType: "DATASET",
      projectKey: projectKey,
      name: name
    });
  }

  datasetLink(): string {
    return this.stateUtils.href.dssObject(
      this.selectedItem?. _type.toUpperCase(),
      this.selectedItem?._source.id,
      this.selectedItem?._source.projectKey
    );
  }

  displayRemoveButton(): boolean {
    return !this.projectKey;
  }

  removeFromFeatureStoreLabel(): string {
    return `Remove from Feature Store${!this.$rootScope.appConfig.globalPermissions.mayManageFeatureStore ? ' (you do not have the permission to perform this action)' : ''}`
  }

  lastBuildInfo(): string {
    if (this.selectedItem?._source.type_raw === "UploadedFiles") {
      return "Uploaded Dataset";
    }
    if (this.details.datasetFullInfo.lastBuild && !this.details.datasetFullInfo.currentBuildState) {
      const buildTime = this.friendlyTimeDeltaShort.transform(this.details.datasetFullInfo.lastBuild.buildEndTime);
      const buildStatus = this.details.datasetFullInfo.lastBuild.buildSuccess ? '<span class="text-success">Success</span>' : '<span class="text-error">Failed</span>';
      return `${buildTime} (${buildStatus})`;
    }
    return "N/A";
  }
}
