<div class="vertical-flex">
    <div class="top-level-tabs">
        <div class="horizontal-flex row-fluid object-nav">
            <div class="flex oh object-breadcrumb">
                <a class="item" ui-sref route=".items">
                    <i class="icon icon-dku-feature-store universe-background feature-store"></i>
                    <span class="title ellipsis">Feature Store</span>
                </a>
            </div>
            <div class="otherLinks" *ngIf="projectKey">
                <a class="btn btn--secondary btn--icon" [href]="currentProjectLink()" alt="Back to project" title="Back to project" [attr.data-qa-smid]="'feature-store-back-to-project'">
                    Back to project
                </a>
            </div>
        </div>
    </div>
    <div class="list-page-4 flex dss-page catalog-page feature-store">
        <api-error-alert
            *ngIf="featureStoreService.error$ | async as error"
            [apiError]="error"
            [closable]="true"
            (resetError)="featureStoreService.resetError()"
        ></api-error-alert>
        <div class="catalog-tooltips"></div>

        <div class="h100 vertical-flex">
            <search-bar
                [searchQuery]=searchQuery
                (newQueryEvent)=newQuery($event)
                class="summary-row noflex"></search-bar>

            <div class="flex">
                <div class="fh">
                    <div class="row-fluid horizontal-flex">
                        <div class="span9 h100 no-flex">
                            <div block-api-error></div>
                            <div class="main-list-zone h100">
                                <div class="h100" style="position: relative">
                                    <facet-list
                                        [aggregationResults]=aggregationResults
                                        [users]=users
                                        [projectNames]=projectNames
                                        (newFacetSelection)=newFacetSelection($event)
                                        class="left-sidebar span3 scroll-zone offset0 fh"></facet-list>
                                    <search-results
                                        [projectKey]=projectKey
                                        [tagsMap]=tagsMap
                                        [hits]=hits
                                        [selectedItem]=selectedItem
                                        [searchQuery]=searchQuery
                                        [facetMap]=activeFacets
                                        [datasetsInCurrentProject]=datasetsInCurrentProject
                                        (resetFiltersEvent)=resetFiltersRequest()
                                        (itemSelectedEvent)=newItemSelected($event)
                                        class="scroll-zone span9 offset0 fh"
                                        style="margin-left: 25%"></search-results>
                                </div>
                            </div>
                        </div>

                        <div class="span3 right-sidebar object-right-column-summary h100 flex" style="margin-left: 0">
                            <div class="catalog-right-column h100">
                                <div class="col-md-3 h100">
                                    <feature-group-details
                                        [projectKey]=projectKey
                                        [selectedItem]=selectedItem
                                        [details]=details
                                        [users]=users
                                        [datasetsInCurrentProject]=datasetsInCurrentProject
                                        (removedFromFeatureStore)=removedFromFeatureStore($event)
                                        (refreshSelectedItem)=newItemSelected($event)></feature-group-details>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
