<div class="review-image-header">
    <div class="buttons-container">
        <button class="btn btn--secondary action-button" (click)="previousImage()"
            [disabled]="((labelingReviewImagePathService.isFirst$ | async) && !(labelingReviewImagePathService.finishedReview$ | async)) || (labelingReviewImagePathService.isEmpty$ | async)">
            <i class="mleft8 icon-chevron-left"></i>
            <span class="action-name">BACK</span>
            <code class="keybind action-key padtop0">←</code>
        </button>
        <button class="btn btn--secondary action-button mleft8" (click)="nextImage()"
            [disabled]="(this.labelingService.identifier$ | async) || (labelingReviewImagePathService.isEmpty$ | async) || (labelingReviewImagePathService.isLast$ | async) ">
            <span class="action">NEXT</span>
            <i class="mleft8 icon-chevron-right"></i>
            <code class="keybind action-key padtop0">→</code>
        </button>
        <div class="btn btn--secondary icon-container mleft8" (click)="resetModifications()" 
          [matTooltip]="'Reset current modifications'" [matTooltipPosition]="'above'">
            <i class="icon-small labeling-review__refrech-icon icon-rotate-left cursor-pointer"></i>
        </div>
    </div>
    <div *ngIf="!(labelingReviewImagePathService.isEmpty$ | async) && !(labelingReviewImagePathService.finishedReview$ | async)"
        class="review-info-container mleft8">
        <div class="icon-container mleft8">
            <i class="icon-small cursor-pointer" [class]="labelingService.copyClass$ | async" (click)="copyPermalink()"
                [matTooltip]="'Copy image link to clipboard'" [matTooltipPosition]="'above'"></i>
        </div>
        <div class="mleft8 path-container" [matTooltip]="(labelingReviewImagePathService.currentImagePath$ | async) ?? ''"
            [matTooltipPosition]="'above'">
            {{labelingReviewImagePathService.currentImagePath$ | async | gentleTruncateBeginning : 50}}
        </div>
        <div class="mleft32 icon-container" *ngIf="labelingReviewUpdateService.reviewStatus$ | async as reviewStatus">
            <span *ngIf="reviewStatus === ReviewStatus.VALIDATED" class="text-success review-status-container">
                <i class="icon-small icon-dku-label-validate mright8"></i>
                Validated
            </span>
            <span *ngIf="reviewStatus === ReviewStatus.CONSENSUS" class="review-status-container icon-digital-blue-base">
                <i class="icon-small icon-dku-label-review mright8"></i>
                Needs validation
            </span>
            <span *ngIf="reviewStatus === ReviewStatus.CONFLICTING" class="text-warning review-status-container">
                <i class="icon-small icon-dku-label-conflict mright8"></i>
                Conflicting
            </span>
        </div>

    </div>
    <div *ngIf="!(labelingReviewImagePathService.isEmpty$ | async) && !(labelingReviewImagePathService.finishedReview$ | async)" class="save-button-container">
        <div *ngIf="!(labelingReviewUpdateService.updatingReview$ | async)"
            matTooltip="Unable to save because of blocking conflicts or missing category" [matTooltipDisabled]="canSave$ | async"
            [matTooltipPosition]="'left'">
            <button class="btn btn--contained btn--primary h100" (click)="saveReview()"
                [disabled]="!(canSave$ | async)">
                <i class="icon-ok"></i>
                Validate
            </button>
        </div>
        <div *ngIf="(labelingReviewUpdateService.updatingReview$ | async) && (labelingReviewUpdateService.isDirty$ | async)"
        matTooltip="Unable to save because review is empty or missing category" [matTooltipDisabled]="canSave$ | async"
            [matTooltipPosition]="'left'">
            <button class="btn btn--contained btn--primary h100" (click)="saveReview()"
                [disabled]="!(canSave$ | async)">
                <i class="icon-save"></i>
                Update
            </button>
        </div>
        <div *ngIf="(labelingReviewUpdateService.updatingReview$ | async) && !(labelingReviewUpdateService.isDirty$ | async)">
            <button class="btn btn--contained btn--primary h100" disabled>
                <i class="icon-save"></i>
                Validated!
            </button>
        </div>
    </div>
</div>
