export enum EChartLabelPosition {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
    INSIDE = 'inside',
    INSIDE_LEFT = 'insideLeft',
    INSIDE_RIGHT = 'insideRight',
    INSIDE_TOP = 'insideTop',
    INSIDE_BOTTOM = 'insideBottom',
    INSIDE_TOP_LEFT = 'insideTopLeft',
    INSIDE_TOP_RIGHT = 'insideTopRight',
    INSIDE_BOTTOM_LEFT = 'insideBottomLeft',
    INSIDE_BOTTOM_RIGHT = 'insideBottomRight'
}
