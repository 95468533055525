import type {AxisDef} from './../../pivot/backend/model/axis-def';
import type {ChartFilter} from './../../pivot/frontend/model/chart-filter';

/**
 * Generated from com.dataiku.dip.shaker.filter.FilterRequest
 */
export interface FilterRequest {
    elements: FilterRequest.FilterElement[];
}

export namespace FilterRequest {
    /**
     * Generated from com.dataiku.dip.shaker.filter.FilterRequest$FilterType
     */
    export enum FilterType {
        ALPHANUM = 'ALPHANUM',
        ALPHANUM_FACET = 'ALPHANUM_FACET',
        NUMERICAL_FACET = 'NUMERICAL_FACET',
        BOUNDING_BOX_FACET = 'BOUNDING_BOX_FACET',
        DATE_FACET = 'DATE_FACET',
        VALIDITY = 'VALIDITY',
        GLOBAL_SEARCH = 'GLOBAL_SEARCH'
    }

    /**
     * Generated from com.dataiku.dip.shaker.filter.FilterRequest$FilterElement
     */
    export interface FilterElement {
        active: boolean;
        column: string;
        columnType: AxisDef.Type;
        dateFilterOption: ChartFilter.DateRelativeOption;
        dateFilterPart: ChartFilter.DateFilterPart;
        dateFilterType: ChartFilter.DateFilterType;
        isAGlobalFilter: boolean;
        maxValue: number;
        minValue: number;
        params: {[key: string]: string};
        timezone: string;
        type: FilterRequest.FilterType;
        values: string[];
    }
}