import { service } from '@migration/downgraded-providers';
import { EChartsManagerService, EChartsInstanceManagerService, HorizontalBarsEChartDefService, PieEChartDefService, StackedVerticalBarsEChartDefService, TreemapEChartDefService, VerticalBarsEChartDefService } from './modules';

export function downgrade() {
    //  Services
    service('EChartsManager', EChartsManagerService),
    service('EChartsInstanceManager', EChartsInstanceManagerService),
    service('VerticalBarsEChartDef', VerticalBarsEChartDefService),
    service('StackedVerticalBarsEChartDef', StackedVerticalBarsEChartDefService),
    service('HorizontalBarsEChartDef', HorizontalBarsEChartDefService),
    service('TreemapEChartDef', TreemapEChartDefService),
    service('PieEChartDef', PieEChartDefService);
}
