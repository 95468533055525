import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ImageMetaData } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { ColumnContent } from '@shared/services/image-feed/sample-data-fetcher.service';


@Component({
  selector: 'image-metadata',
  templateUrl: './image-metadata.component.html',
  styleUrls: ['./image-metadata.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageMetadataComponent implements OnChanges {
    @Input() image: ImageMetaData;
    displayedColums: ColumnContent[];
    
    ngOnChanges(): void {
        this.displayedColums = this.image.allColumns.filter(column => {
            if (column.name.startsWith("__dku__deephub_")) {
                return false;
            }

            // We do not want to display column with data used for populating the image
            // (annotations, pairing, etc...), except for the path to the image, that provides
            // useful information
            return column.isItemPath || !column.isImportantColumn;
        });
    }
}
