<div class="empty-annotations-placeholder" *ngIf="!label || label.annotations.length === 0">
    <div *ngIf="skipped" class="skipped-placeholder"> 
        <i class="icon-fast-forward skipped-placeholder__skip-icon"></i>
        <h2>Sample was skipped</h2>
    </div>

    <div *ngIf="!skipped">
        <h2>No labels yet</h2>
        <div>
            <p>Select a category by...</p>
        </div>
        <div class="circles-container">
            <div class="circle-container">
                <div class="circle cat-example"></div>
                <span>...clicking on the category buttons</span>
            </div>
            <span>or</span>
            <div class="circle-container">
                <div class="circle shortcut-example"></div>
                <span>...using keyboard shortcuts</span>
            </div>
        </div>
        <p>...then draw a box around the target</p>
    </div>
</div>

<div *ngIf="label && label?.annotations?.length">
    <ng-container *ngFor="let annotation of label.annotations">
        <div class="labeling-annotation" [ngClass]="{ 'selected': annotation.selected }" (click)="selectAnnotation($event, annotation)">
            <div class="bbox-thumb-container" *ngIf="annotation | instanceOf: UIBoundingBox">
                <bbox-thumb [bboxHeight]="annotation.height" [bboxWidth]="annotation.width" [color]="color(annotation.category)"></bbox-thumb>
            </div>
            <div class="labeling-annotation__class" *ngIf="annotation.category">{{annotation.category}}</div>
            <div class="labeling-annotation__class" *ngIf="!annotation.category">Assign a category</div>
            <i class="icon-trash mleft4" (click)="deleteAnnotation(annotation)"></i>
        </div>
    </ng-container>
</div>
