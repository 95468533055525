import type {IndividualExplanationParams} from './../prediction/flow/individual-explanation-params';

export namespace InteractiveModelParams.ExplorationParams {
    /**
     * Generated from com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplorationParams$FeatureDomain
     */
    export interface FeatureDomain {
        categories: string[];
        maxValue: number;
        minValue: number;
        name: string;
        type: InteractiveModelParams.ExplorationParams.EmuType;
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplorationParams$EmuType
     */
    export enum EmuType {
        FROZEN = 'FROZEN',
        NUMERICAL = 'NUMERICAL',
        CATEGORICAL = 'CATEGORICAL'
    }
}

export namespace InteractiveModelParams {
    /**
     * Generated from com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ScoringParams
     */
    export interface ScoringParams extends InteractiveModelParams._ComputationParams {
        // PolyJson type
        type: 'SCORING';
    }

    export namespace ScoringParams {
        export const type = 'SCORING';
    }

    /** @deprecated Use InteractiveModelParams.ComputationParams.isScoringParams() */ export function isScoringParams(toTest: InteractiveModelParams.ComputationParams): toTest is ScoringParams { return ScoringParams.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplorationParams
     */
    export interface _ExplorationParams extends InteractiveModelParams._ComputationParams {
        featureDomains: InteractiveModelParams.ExplorationParams.FeatureDomain[];
        target: any /* TODO: Missing translation of com.google.gson.JsonPrimitive */;
    }

    export type ExplorationParams = InteractiveModelParams.CounterfactualsParams | InteractiveModelParams.OutcomeOptimizationParams;

    export namespace ExplorationParams {
        export const type = ['COUNTERFACTUALS', 'OUTCOME_OPTIMIZATION'] as const;
    }

    /** @deprecated Use InteractiveModelParams.ComputationParams.isExplorationParams() */ export function isExplorationParams(toTest: InteractiveModelParams.ComputationParams): toTest is ExplorationParams { return (ExplorationParams.type as readonly unknown[]).includes(toTest['type']); }

    /**
     * Generated from com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ComputationParams
     */
    export interface _ComputationParams {
        applyPreparationScript: boolean;
        fullModelId: string;
        records: {[key: string]: any}[];
    }

    export type ComputationParams = InteractiveModelParams.CounterfactualsParams | InteractiveModelParams.ExplanationsParams | InteractiveModelParams.OutcomeOptimizationParams | InteractiveModelParams.ScoringParams;

    export namespace ComputationParams {
        export const type = ['COUNTERFACTUALS', 'EXPLANATIONS', 'OUTCOME_OPTIMIZATION', 'SCORING'] as const;
        export function isCounterfactualsParams(toTest: InteractiveModelParams.ComputationParams): toTest is InteractiveModelParams.CounterfactualsParams { return toTest['type'] === 'COUNTERFACTUALS'; }
        export function isExplanationsParams(toTest: InteractiveModelParams.ComputationParams): toTest is InteractiveModelParams.ExplanationsParams { return toTest['type'] === 'EXPLANATIONS'; }
        export function isExplorationParams(toTest: InteractiveModelParams.ComputationParams): toTest is InteractiveModelParams.ExplorationParams { return ['COUNTERFACTUALS', 'OUTCOME_OPTIMIZATION'].includes(toTest['type']); }
        export function isOutcomeOptimizationParams(toTest: InteractiveModelParams.ComputationParams): toTest is InteractiveModelParams.OutcomeOptimizationParams { return toTest['type'] === 'OUTCOME_OPTIMIZATION'; }
        export function isScoringParams(toTest: InteractiveModelParams.ComputationParams): toTest is InteractiveModelParams.ScoringParams { return toTest['type'] === 'SCORING'; }
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$CounterfactualsParams
     */
    export interface CounterfactualsParams extends InteractiveModelParams._ExplorationParams {
        // PolyJson type
        type: 'COUNTERFACTUALS';
    }

    export namespace CounterfactualsParams {
        export const type = 'COUNTERFACTUALS';
    }

    /** @deprecated Use InteractiveModelParams.ComputationParams.isCounterfactualsParams() */ export function isCounterfactualsParams(toTest: InteractiveModelParams.ComputationParams): toTest is CounterfactualsParams { return CounterfactualsParams.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$OutcomeOptimizationParams
     */
    export interface OutcomeOptimizationParams extends InteractiveModelParams._ExplorationParams {
        // PolyJson type
        type: 'OUTCOME_OPTIMIZATION';
    }

    export namespace OutcomeOptimizationParams {
        export const type = 'OUTCOME_OPTIMIZATION';
    }

    /** @deprecated Use InteractiveModelParams.ComputationParams.isOutcomeOptimizationParams() */ export function isOutcomeOptimizationParams(toTest: InteractiveModelParams.ComputationParams): toTest is OutcomeOptimizationParams { return OutcomeOptimizationParams.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplanationsParams
     */
    export interface ExplanationsParams extends InteractiveModelParams._ComputationParams {
        explanationMethod?: IndividualExplanationParams.ExplanationsMethod | null;
        nExplanations: number;

        // PolyJson type
        type: 'EXPLANATIONS';
    }

    export namespace ExplanationsParams {
        export const type = 'EXPLANATIONS';
    }

    /** @deprecated Use InteractiveModelParams.ComputationParams.isExplanationsParams() */ export function isExplanationsParams(toTest: InteractiveModelParams.ComputationParams): toTest is ExplanationsParams { return ExplanationsParams.type === toTest['type']; }
}