import { Injectable } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';

@Injectable({
    providedIn: 'root',
})
export class WorkspaceLocalStorageService {

    constructor() { }

    private getLocalStorageKeyPrefix(userId: string, workspaceKey: string) {
        return `dss.workspaces.params.${userId}.${workspaceKey}`;
    }

    private getLocalStorageKey(userId: string, workspaceKey: string, object: Workspace.WorkspaceObject) {
        if (object?.reference) {
            return `${this.getLocalStorageKeyPrefix(userId, workspaceKey)}.${object.reference.projectKey}.${object.reference.type}.${object.reference.id}`;
        }
        return null;
    }

    getLocalObjectParams(userId: string, workspaceKey: string, object: Workspace.WorkspaceObject) {
        const key = this.getLocalStorageKey(userId, workspaceKey, object);
        if (key) {
            const item = localStorage.getItem(key);
            if (item) {
                return JSON.parse(item);
            }
        }
        return null;
    }

    saveLocalObjectParams(userId: string, workspaceKey: string, object: Workspace.WorkspaceObject, params: { [key: string]: string }) {
        const key = this.getLocalStorageKey(userId, workspaceKey, object);
        if (key) {
            localStorage.setItem(key, JSON.stringify(params));
        }
    }

    removeUnusedLocalObjectParams(userId: string, workspace: Workspace) {
        const keyPrefix = this.getLocalStorageKeyPrefix(userId, workspace.workspaceKey);
        const isNotUsed = (key: string) => !workspace.workspaceObjects.some(object => this.getLocalStorageKey(userId, workspace.workspaceKey, object) === key);
        let key;

        for (let i = localStorage.length - 1; i >= 0; --i) {
            key = localStorage.key(i);
            if (key?.startsWith(keyPrefix) && isNotUsed(key)) {
                localStorage.removeItem(key);
            }
        }
    }
}
