import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { EscapeHtmlPipe } from '@shared/pipes/escaping/escape-html.pipe';
import { fairAny } from 'dku-frontend-core';
import { FacetMap } from '../feature-store-models';
import type { Hit, Highlight } from '../query-result-models';

@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultsComponent {

  @Input() projectKey: string | undefined;
  @Input() tagsMap: any;
  @Input() hits: Hit[];
  @Input() selectedItem: Hit | null;
  @Input() facetMap: FacetMap;
  @Input() searchQuery: string;
  @Input() datasetsInCurrentProject: string[];
  @Output() resetFiltersEvent: EventEmitter<void>;
  @Output() itemSelectedEvent: EventEmitter<Hit>;

  escapeHtml: EscapeHtmlPipe;

  constructor(
    @Inject("TaggingService") private taggingService: fairAny,
  ) {
    this.resetFiltersEvent = new EventEmitter();
    this.itemSelectedEvent = new EventEmitter();

    this.escapeHtml = new EscapeHtmlPipe();

  }

  /**
   * gets the first highlighted value for the field, or undefined if no highligh exists for the field
   */
   private getHighlightFor(item: Hit, field: keyof Highlight) {
    return item.highlight?.[field]?.[0];
  }

  canShowSearchResults(): boolean {
    return this.hits && this.hits.length > 0;
  }

  noFiltersInSearch(): boolean {
    return !this.searchQuery && !this.hasFacets();
  }

  hasFacets(): boolean {
    return this.facetMap && Object.keys(this.facetMap).length > 0;
  }

  itemCount(): string {
    const count = this.hits ? this.hits.length : 0;
    return "<strong>" + count + "</strong> Feature Group" + (count<2?"":"s");
  }

  formatItemName(item: Hit): string {
    return this.getHighlightFor(item, 'name')
      ?? this.getHighlightFor(item, 'name.raw')
      ?? this.getHighlightFor(item, 'name.plaintext')
      // Comes from _source, encode HTML entities in order to display attributes like <stuff
      ?? this.escapeHtml.transform(item._source.name);
  }

  formatProjectName(item: Hit): string {
    return this.getHighlightFor(item, 'projectName')
      ?? this.getHighlightFor(item, 'projectName.plaintext')
      ?? this.getHighlightFor(item, 'projectKey')
      ?? this.getHighlightFor(item, 'projectKey.plaintext')
      ?? item._source.projectName;
  }

  itemHasDescription(item: Hit): boolean {
    return (item._source.shortDesc !== undefined && item._source.shortDesc !=="") ||
          (item._source.description !== undefined && item._source.description !=="");
  }

  formatItemDescription(item: Hit): string {
    return this.getHighlightFor(item, 'shortDesc')
      ?? this.getHighlightFor(item, 'shortDesc.plaintext')
      ?? this.getHighlightFor(item, 'description')
      ?? this.getHighlightFor(item, 'description.plaintext')
      ?? (item._source.shortDesc || item._source.description);
  }

  itemHasTags(item: Hit): boolean {
    return item._source.tag?.length !== 0;
  }

  highlightedTags(item: Hit): string[] {
    if(item.highlight) {
      const tags: string[] = item.highlight.tag?.map(this.removeEmphasis) || [];
      const tagsPlaintext: string[] = item.highlight["tag.plaintext"]?.map(this.removeEmphasis) || [];
      tagsPlaintext.forEach(tag => {
        if(tags.length < 5 && !tags.includes(tag)) {
          tags.push(tag);
        }
      });
      return tags;
    }
    return [];
  }

  itemTags(item: Hit): string[] {
    const tags = this.highlightedTags(item);
    item._source.tag.forEach(tag => {
      if(tags.length < 5 && !tags.includes(tag)) {
        tags.push(tag);
      }
    });
    return tags;
  }

  tagIsHighlighted(item: Hit, tag: string): boolean {
    if (item.highlight) {
      if(item.highlight.tag && item.highlight.tag.length > 0) {
        return item.highlight.tag.map(this.removeEmphasis).includes(tag);
      }
      if(item.highlight["tag.plaintext"] && item.highlight["tag.plaintext"].length > 0) {
        return item.highlight["tag.plaintext"].map(this.removeEmphasis).includes(tag);
      }
    }
    return false;
  }

  itemHasHighlightedColumns(item: Hit): boolean {
    return this.getHighlightFor(item, 'column') !== undefined
      || this.getHighlightFor(item, 'column.plaintext') !== undefined;
  }

  itemHighlightedColumns(item: Hit): string {
    const columns: string[] = [];
    if (item.highlight) {
      if (item.highlight.column && item.highlight.column.length > 0) {
        columns.push(...item.highlight.column);
      }
      if (item.highlight["column.plaintext"] && item.highlight["column.plaintext"].length > 0) {
        item.highlight["column.plaintext"].forEach(column => {
          const strippedColumnName = column.replace(/<\/?em>/g, '');
          const index = columns.map(c => c.replace(/<\/?em>/g, '')).indexOf(strippedColumnName);
          if(index === -1) {
            columns.push(column);
          }
        });
      }
    }
    return `feature${columns.length>1?"s":""}: ${columns.join(", ")}`;
  }

  numberOfTagsNotDisplayed(item: Hit): number {
    const tagsWithHighlight = this.highlightedTags(item);
    const displayedTags = (tagsWithHighlight.length > 5)?tagsWithHighlight.length:5;
    return item._source.tag.length - displayedTags;
  }

  removeEmphasis(emphazised: string) {
    return emphazised.replace(/<em>/g, "").replace(/<\/em>/g, "");
  }

  tagBackgroundColor(projectKey: string, tag: string): string {
    return this.tagsMap[projectKey]?.tags[tag]?.color || this.taggingService.getTagColor(tag);
  }

  resetSearch(): void {
    this.resetFiltersEvent.emit();
  }

  selectItem(item: Hit): void {
    this.itemSelectedEvent.emit(item);
  }

  itemIsAlreadyInCurrentProject(item: Hit): boolean {
    return this.datasetsInCurrentProject.includes(item._source.projectKey + "." + item._source.id);
  }
}
