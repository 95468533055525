import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalsModule } from '@shared/modals/modals.module';
import { ComponentsModule } from "@shared/components/components.module";

import { MatSortModule } from '@angular/material/sort';

import { WidgetsModule } from '@app/widgets/widgets.module';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { MigrationModule } from '@migration/migration.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DkuFrontendCoreModule } from 'dku-frontend-core';
import { IsEllipsisActiveDirective } from './directives/is-ellipsis-active.directive';
import { ExperimentTrackingHeaderComponent } from './experiment-tracking-header/experiment-tracking-header.component';
import { ExperimentTrackingRunArtifactsComponent } from './experiment-tracking-run/experiment-tracking-run-artifacts/experiment-tracking-run-artifacts.component';
import { ExperimentTrackingRunDetailsComponent } from './experiment-tracking-run/experiment-tracking-run-details/experiment-tracking-run-details.component';
import { ExperimentTrackingRunComponent } from './experiment-tracking-run/experiment-tracking-run.component';
import { ExperimentTrackingRunsListComponent } from './experiment-tracking-runs-list.component';
import { ChartRunsMetricsComponent } from './chart-runs-metrics/chart-runs-metrics.component';
import { ExperimentTrackingComponent } from './experiment-tracking/experiment-tracking.component';
import { ExperimentTrackingStatusComponent } from './experiment-tracking-status/experiment-tracking-status.component';
import { DataikuWT1Module } from 'dku-frontend-core';
import { ExperimentTrackingRunModelDeployModalComponent } from './experiment-tracking-run/experiment-tracking-run-model-deploy-modal/experiment-tracking-run-model-deploy-modal.component';
import { ValuesListModule } from '@app/widgets/lists/values-list/values-list.module';
import { ExperimentTrackingRunInferenceComponent } from './experiment-tracking-run/experiment-tracking-run-inference/experiment-tracking-run-inference.component';
import { ExperimentTrackingInvalidMetricValueComponent } from './experiment-tracking-invalid-metric-value/experiment-tracking-invalid-metric-value.component';
import { YYYYMMDDHHmmssDateTimePipe } from '@shared/pipes/date-pipes/yyyymmddhhmmss-date-time.pipe';
import { SmartNumberPipe } from '@shared/pipes/number-pipes/smart-number.pipe';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        ComponentsModule,
        CommonModule,
        WidgetsModule,
        MigrationModule,
        PipesModule,
        ModalsModule,
        DataikuAPIModule,
        DataikuWT1Module,
        DkuFrontendCoreModule,
        ValuesListModule
    ],
    declarations: [
        ExperimentTrackingRunsListComponent,
        IsEllipsisActiveDirective,
        ChartRunsMetricsComponent,
        ExperimentTrackingHeaderComponent,
        ExperimentTrackingRunComponent,
        ExperimentTrackingRunDetailsComponent,
        ExperimentTrackingRunArtifactsComponent,
        ExperimentTrackingComponent,
        ExperimentTrackingStatusComponent,
        ExperimentTrackingRunModelDeployModalComponent,
        ExperimentTrackingRunInferenceComponent,
        ExperimentTrackingInvalidMetricValueComponent
    ],
    exports: [ExperimentTrackingRunsListComponent],
    providers: [YYYYMMDDHHmmssDateTimePipe, SmartNumberPipe]
})
export class ExperimentTrackingModule { }
