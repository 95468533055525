import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceObjectType, WorkspaceDisplayService } from '../../../../shared';

export enum WorkspaceListType {
    LIST = 'list',
    TILE = 'tile',
}

export interface WorkspaceObjectStarEvent {
    status: boolean;
    object: Workspace.WorkspaceObject;
}

@Component({
    selector: 'workspace-object-list-element',
    templateUrl: './workspace-object-list-element.component.html',
    styleUrls: ['./workspace-object-list-element.component.less'],
    host: { '[class]': 'displayType' },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceObjectListElementComponent implements OnChanges {

    readonly WorkspaceListType = WorkspaceListType;

    link?: { href: string, target: string };
    type: WorkspaceObjectType;
    openExternally: boolean;

    @Input() workspaceKey: string;
    @Input() object: Workspace.WorkspaceObject;
    @Input() displayType = WorkspaceListType.LIST;
    @Input() canRemove = false;
    @Output() remove = new EventEmitter<Workspace.WorkspaceObject>();
    @Output() toggleStar = new EventEmitter<WorkspaceObjectStarEvent>();

    constructor(private workspaceDisplayService: WorkspaceDisplayService) {}

    ngOnChanges(): void {
        this.link = this.workspaceDisplayService.getObjectLink(this.workspaceKey, this.object);
        this.type = this.workspaceDisplayService.getObjectType(this.object);
        this.openExternally = this.workspaceDisplayService.shouldOpenExternally(this.object);
    }

    onToggleStar(nextStatus: boolean): void {
        this.toggleStar.emit({
            status: nextStatus,
            object: this.object
        });
    }

    onRemove(): boolean {
        this.remove.emit(this.object);
        return false;
    }

}
