import { Component } from '@angular/core';
import { LabelingReviewRecord } from '@features/labeling/services/labeling-review-data-fetcher.service';
import { Observable } from 'rxjs';
import { LabelingReviewImagePathService } from '../services/labeling-review-image-path.service';

@Component({
    selector: 'review-image-feed',
    templateUrl: './review-image-feed.component.html',
    styleUrls: ['./review-image-feed.component.less']
})
export class ReviewImageFeedComponent {
    currentImagePath$: Observable<string>;
    currentIndex$: Observable<number>;

    constructor(public labelingReviewImagePathService: LabelingReviewImagePathService) {
        this.currentImagePath$ = labelingReviewImagePathService.currentImagePath$;
        this.currentIndex$ = labelingReviewImagePathService.currentIndex$;
    }

    setReviewRecord(record: LabelingReviewRecord) {
        this.labelingReviewImagePathService.setReviewRecord(record);
    }

    setImageSource($event: { imageId?: number, itemPath?: string }) {
        this.labelingReviewImagePathService.setImageSource($event.itemPath!, $event.imageId!);
    }
}
