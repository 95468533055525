<div class="workspaces-index-page">
    <api-error-alert [apiError]="error$ | async" (resetError)="closeError()" [closable]="true"></api-error-alert>
    <div class="horizontal-flex h100">
        <ng-container *ngIf="workspaces$ | async as workspaces">
            <workspaces-left-menu
                *ngIf="isLeftMenuVisible"
                [workspaces]="workspaces" [selectedWorkspaceKey]="(currentWorkspace$ | async)?.workspaceKey"
                (workspaceSelected)="goToWorkspace($event)">
            </workspaces-left-menu>
            <div *ngIf="!!(error$ | async) === false" class="workspaces-index-page__content"
                [hidden]="loading$ | async">
                <ui-view></ui-view>
            </div>
        </ng-container>
    </div>
</div>
