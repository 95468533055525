import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterNonNull } from 'dku-frontend-core';
import { combineLatest, Subject } from 'rxjs';
import { CanvasPairedItem, DeephubObjectDetectionReportPainterService } from '@features/deephub/object-detection/report/services/deephub-object-detection-report-painter.service';
import { BasePainterOptions } from '@shared/models/painter';
import { PainterService } from '@shared/services/image-feed/painter.service';
import { FeedImageModalService } from '@shared/services/image-feed/feed-image-modal.service';

@UntilDestroy()
@Component({
    selector: 'deephub-object-detection-report-image-feed-modal',
    templateUrl: './deephub-object-detection-report-image-feed-modal.component.html',
    styleUrls: ['./deephub-object-detection-report-image-feed-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DeephubObjectDetectionReportPainterService,
        { provide: PainterService, useExisting: DeephubObjectDetectionReportPainterService },
    ]
})
export class DeephubObjectDetectionReportImageFeedModalComponent {
    pairList$ = new Subject<CanvasPairedItem[]>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            imageId: number,
            imageOptions: BasePainterOptions
        },
        private canvasPainter: DeephubObjectDetectionReportPainterService,
        private feedImageModalService: FeedImageModalService
    ) {
        combineLatest([
            this.feedImageModalService.imageId$,
            this.feedImageModalService.canvas$
        ]).pipe(
            filterNonNull(),
            untilDestroyed(this)
        ).subscribe(([_, canvas]) => {
            this.pairList$.next(this.canvasPainter.getPairs(canvas));
        });
    }
}
