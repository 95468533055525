<ng-container>
    <div class="review-image-feed__wrapper vertical-flex h100" >
        <review-image-feed-header></review-image-feed-header>
        <div *ngIf="(labelingReviewImagePathService.isEmpty$ | async)" class="empty-list-cta">
            <ng-container *ngIf="(labelingReviewImagePathService.isFiltered$ | async) === true; else noAnnotations">
                <p>No images match your filter</p>
                <button class="btn btn--secondary" (click)="labelingReviewImagePathService.clearFilter()">
                    Clear Filter
                </button>
            </ng-container>
            <ng-template #noAnnotations>
                <p>
                    <review-image-empty></review-image-empty>
                </p>
            </ng-template>
        </div>
        <image-feed class="flex review-image-feed__feed" [imageHeight]="200" [itemsPerRow]="1" (imageSelect)="setReviewRecord($event)" (imageClick)="setImageSource($event)" [selectedPath]="currentImagePath$ | async " [selectedIndex]="currentIndex$ | async" *ngIf="(labelingReviewImagePathService.isEmpty$ | async) === false" ></image-feed>
    </div>
</ng-container>
