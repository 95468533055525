import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LabelingReviewImagePathService, ReviewFetchType } from '../services/labeling-review-image-path.service';

@Component({
    selector: 'review-image-empty',
    templateUrl: './review-image-empty.component.html',
    styleUrls: ['./review-image-empty.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewImageEmptyComponent {

    get ReviewFetchType() {
      return ReviewFetchType;
    }

    constructor(
        public labelingReviewImagePathService: LabelingReviewImagePathService
    ) { }
    
}
