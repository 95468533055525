<object-viewer-navigation [ngClass]="{ 'workspace-object-viewer--full-size': (showRightPanel$ | async) === false }"
    [workspace]="workspace$ | async" [object]="object$ | async" [project]="project$ | async"
    [insightId]="insightId$ | async">
</object-viewer-navigation>

<div class="workspace-object-viewer"
    [ngClass]="{ 'workspace-object-viewer--full-size': (showRightPanel$ | async) === false }">
    <ng-template objectViewerHost></ng-template>
</div>

<workspace-right-panel *ngIf="showRightPanel$ | async" [workspace]="workspace$ | async" [object]="object$ | async"
    [editable]="editable$ | async" [project]="project$ | async"></workspace-right-panel>
