import { Inject, Injectable } from '@angular/core';
import { EChartsOption, GridComponentOption, TitleComponentOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { isNumber, cloneDeep } from 'lodash';
import { ChartLabels } from '../../enums';
import { ChartBase, ChartCoordinates, FrontendChartDef, Legend } from '../../interfaces';
import { ChartTensorDataWrapper } from '../../models';
import { EChartsAxesService } from './echarts-axes.service';
import { EChartNameLocation } from './enums';
import { MetaEChartDef, Cartesian2DSeries, NonCartesian2DSeries } from './interfaces';
import { Cartesian2DEChartDef, EChartDef } from './models';

class FacetsMetadata {
    facets: Array<{label: string, width: number, containerWidth: number}>;
    maxContainerWidth: number;
}

@Injectable({
    providedIn: 'root'
})
export class EChartsInstanceManagerService {
    private AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS: { fontFamily: string, color: string, fontSize: number } = {
        fontFamily: this.CHART_FORMATTING_OPTIONS.FONT_FAMILY,
        color: this.CHART_FORMATTING_OPTIONS.COLOR,
        fontSize: this.CHART_FORMATTING_OPTIONS.AXIS_LABELS_FONT_SIZE
    };

    constructor(
        @Inject('Logger') private logger: any,
        @Inject('CHART_FORMATTING_OPTIONS') private CHART_FORMATTING_OPTIONS: any,
        private echartsAxesService: EChartsAxesService
    ) { }

    private getFacetLabel(facet?: { label: string }): string {
        if (!facet || !facet.label || facet.label === ChartLabels.NO_VALUE) {
            return 'No value';
        } else {
            return facet.label;
        }
    }

    private updateManualExtent(chartDef: FrontendChartDef, xAxes: Array<XAXisComponentOption>, yAxes: Array<YAXisComponentOption>): void {
        /**
         * Extents are computed from first xAxis and yAxis
         */
        const xAxisExtent: [number, number] = [isNumber(xAxes[0].min) ? xAxes[0].min : Infinity, isNumber(xAxes[0].max) ? xAxes[0].max : -Infinity];
        const yAxisExtent: [number, number] = [isNumber(yAxes[0].min) ? yAxes[0].min : Infinity, isNumber(yAxes[0].max) ? yAxes[0].max : -Infinity];

        /*
         *  Mandatory for manual range
         *  Used to to prevent the chart def watcher to take the incoming changes into account
         */
        chartDef.$ignoreFields = ['xCustomExtent.manualExtent', 'yCustomExtent.manualExtent'];

        if (xAxisExtent[0] !== Infinity && xAxisExtent[1] !== -Infinity) {
            chartDef.xCustomExtent.manualExtent = xAxisExtent;
        }

        if (yAxisExtent[0] !== Infinity && yAxisExtent[1] !== -Infinity) {
            chartDef.yCustomExtent.manualExtent = yAxisExtent;
        }

        //  Reset it afterwards
        setTimeout(() => {
            chartDef.$ignoreFields = [];
        });
    }

    private getFacetsMetadata(facets: Array<{label: string}>): FacetsMetadata {
        const FACET_LABEL_MARGIN = 20;
        const FACET_LABEL_MIN_WIDTH = 50;
        const FACET_LABEL_MAX_WIDTH = 250;

        const facetsMetadata: FacetsMetadata = {
            facets: [],
            maxContainerWidth: 50
        };

        return facets.reduce(
            (acc, facet: { label: string }) => {
                const facetLabel = this.getFacetLabel(facet);
                const facetLabelWidth = this.echartsAxesService.getTextWidth(facetLabel, `${this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize}px ${this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS.fontFamily}`) || 0;

                let facetLabelContainerWidth = 0;

                //  Min/max width
                if (facetLabelWidth + FACET_LABEL_MARGIN < FACET_LABEL_MIN_WIDTH) {
                    facetLabelContainerWidth = FACET_LABEL_MIN_WIDTH;
                } else if (facetLabelWidth + FACET_LABEL_MARGIN > FACET_LABEL_MAX_WIDTH) {
                    facetLabelContainerWidth = FACET_LABEL_MAX_WIDTH;
                } else {
                    facetLabelContainerWidth = facetLabelWidth + FACET_LABEL_MARGIN;
                }

                acc.maxContainerWidth = Math.max(acc.maxContainerWidth, facetLabelContainerWidth);
                acc.facets.push({ label: facetLabel, width: facetLabelWidth, containerWidth: facetLabelContainerWidth });

                return acc;
            },
            facetsMetadata
        );
    }

    private getMetaEChartDef(
        chartDef: FrontendChartDef,
        xAxes: Array<XAXisComponentOption>,
        yAxes: Array<YAXisComponentOption>,
        series: Array<Cartesian2DSeries>,
        grid: GridComponentOption,
        maxContainerWidth: number,
        noXAxis: boolean
    ): MetaEChartDef {
        const left = isNumber(grid.left) ? grid.left : parseInt(grid.left || '0');
        const height = chartDef.displayXAxis && !noXAxis ? grid.bottom : 20;
        const meta: MetaEChartDef = {
            height,
            options: {
                grid: {
                    //  bottom and height are set to cheat echarts to display only xAxis
                    bottom: height,
                    left: maxContainerWidth + left,
                    right: grid.right,
                    height: height
                },
                xAxis: xAxes.map(xAxis => ({ ...xAxis, show: !!(chartDef.displayXAxis && !noXAxis) })),
                yAxis: yAxes.map(yAxis => ({ ...yAxis, show: false })),
                series
            }
        };

        return meta;
    }

    private getFacetNonCartesian2DSeriesOption(
        series: NonCartesian2DSeries,
        top: number,
        bottom: number,
        left: number,
        right: number,
        height: number
    ) {
        const updatedSeries: NonCartesian2DSeries = {
            ...series,
            top,
            bottom,
            left,
            right,
            height
        };

        return updatedSeries;
    }

    private getFacetGridOption(
        grid: GridComponentOption,
        top: number,
        bottom: number,
        height: number,
        maxContainerWidth: number
    ): GridComponentOption {
        const updatedGrid: GridComponentOption = {
            ...grid,
            top,
            bottom,
            left: maxContainerWidth + (isNumber(grid.left) ? grid.left : parseInt(grid.left || '0')),
            height
        };

        return updatedGrid;
    }

    private getFacetTitleOption(
        facet: {label: string, width: number, containerWidth: number},
        facetIndex: number,
        top: number,
        chartHeight: number,
        maxContainerWidth: number,
        singleXAxis?: boolean,
        subtext?: string,
        subtextStyle?: any
    ): TitleComponentOption {
        const facetTitle: TitleComponentOption = {
            text: facet.label,
            subtext,
            subtextStyle,
            textStyle: {
                fontWeight: 'normal',
                width: facet.containerWidth,
                overflow: 'truncate',
                ...this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS
            },
            left: Math.ceil(maxContainerWidth / 2 - facet.width / 2)
        };

        /**
         * The title should always be aligned to the middle of the chart,
         * with a single X axis, we need to take care of the initial top
         * but, as we removed the other X axes, we also need to remove it
         * from the current chart height (original chart height embeds an X axis).
         */
        if (singleXAxis) {
            facetTitle.top = Math.ceil(top + chartHeight * facetIndex + (chartHeight - top) / 2 - this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize / 2);
        } else {
            facetTitle.top = Math.ceil(chartHeight * facetIndex + chartHeight / 2 - this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize / 2);
        }

        return facetTitle;
    }

    private getNonCartesian2DOptionsByFacets(context: {
        options: EChartsOption,
        facetsMetadata: FacetsMetadata,
        chartHeight: number,
        singleXAxis?: boolean,
        displayXAxis?: boolean | null,
        noXAxis?: boolean
    }): EChartsOption {
        const series = context.options.series as Array<NonCartesian2DSeries>;
        const grid = context.options.grid as GridComponentOption;

        let updatedOptions: EChartsOption = {
            series: [],
            title: [],
            grid
        };

        const top = isNumber(grid.top) ? grid.top : parseInt(grid.top || '0');
        const bottom = isNumber(grid.bottom) ? grid.bottom : parseInt(grid.bottom || '0');
        const left = isNumber(grid.left) ? grid.left : parseInt(grid.left || '0');
        const right = isNumber(grid.right) ? grid.right : parseInt(grid.right || '0');
        const height = context.chartHeight - top - bottom;

        updatedOptions = context.facetsMetadata.facets.reduce((acc, facet, facetIndex, facets) => {
            const seriesItem = series.find(seriesItem => {
                const coordIds = (seriesItem.id as string).split('-');
                const facetId = coordIds[1];
                const seriesItemFacetIndex = parseInt(facetId.split('facet')[1]);
                return seriesItemFacetIndex === facetIndex;
            });

            if (seriesItem) {
                let updatedTop = top;
                let updatedBottom = top;

                if (facetIndex > 0 && acc.series && 'length' in acc.series) {
                    const previousSeries = acc.series[facetIndex - 1];
                    if ('bottom' in previousSeries) {
                        updatedTop = (previousSeries.bottom as number) + top + context.chartHeight * facetIndex;
                    }
                }

                if (facetIndex < facets.length - 1) {
                    updatedBottom = 0;
                }

                const facetSeries = this.getFacetNonCartesian2DSeriesOption(
                    seriesItem,
                    updatedTop,
                    updatedBottom,
                    context.facetsMetadata.maxContainerWidth + left,
                    right,
                    height
                );

                (acc.series as Array<NonCartesian2DSeries>).push(facetSeries);
            } else {
                throw new Error(`Expected series for facet ${facetIndex} to be found.`);
            }

            const title = context.options.title as TitleComponentOption;
            const facetTitle = this.getFacetTitleOption(
                facet,
                facetIndex,
                top,
                context.chartHeight,
                context.facetsMetadata.maxContainerWidth,
                context.singleXAxis,
                title?.subtext,
                title?.subtextStyle
            );

            (acc.title as Array<TitleComponentOption>).push(facetTitle);

            return acc;
        }, updatedOptions);

        return updatedOptions;
    }

    private drawNonCartesian2DFacets(
        facets: Array<{ label: string }>,
        chartId: string, chartDef: FrontendChartDef,
        chartData: ChartTensorDataWrapper,
        chartBase: ChartBase,
        echartDef: EChartDef,
        legends: Array<Legend> | null,
        frameIndex: number
    ): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        const facetsMetadata = this.getFacetsMetadata(facets);

        const chartBaseForFacet: ChartBase = {
            ...chartBase,
            height: chartDef.chartHeight,
            width: chartBase.width - facetsMetadata.maxContainerWidth
        };

        const facetsResult = echartDef.draw({
            chartId,
            chartDef,
            chartData,
            chartBase: chartBaseForFacet,
            legends,
            frameIndex
        });

        const updatedOptions = this.getNonCartesian2DOptionsByFacets({
            options: facetsResult.options,
            facetsMetadata,
            chartHeight: chartDef.chartHeight,
            singleXAxis: chartDef.singleXAxis,
            displayXAxis: chartDef.displayXAxis
        });

        return { options: updatedOptions, allCoords: facetsResult.allCoords };
    }

    private getCartesian2DSeriesByFacets(series: Array<Cartesian2DSeries> = []): Array<Cartesian2DSeries> {
        let updatedSeries = cloneDeep(series);

        /**
         * Retrieves number of axes used by series
         * On series with axes, there is at least one axis
         */
        let seriesAxes = { xAxisIndices: { 0: true } as any, yAxisIndices: { 0: true } as any };
        seriesAxes = series
            .reduce((acc, seriesItem) => {
                if (isNumber(seriesItem.xAxisIndex)) {
                    acc.xAxisIndices[seriesItem.xAxisIndex] = true;
                }

                if (isNumber(seriesItem.yAxisIndex)) {
                    acc.yAxisIndices[seriesItem.yAxisIndex] = true;
                }

                return acc;
            }, seriesAxes);

        const xAxisIndices = Object.keys(seriesAxes.xAxisIndices);
        const yAxisIndices = Object.keys(seriesAxes.yAxisIndices);

        updatedSeries = updatedSeries
            .map(seriesItem => {
                const coordIds = (seriesItem.id as string).split('-');
                const facetId = coordIds[1];
                const seriesItemFacetIndex = parseInt(facetId.split('facet')[1]);

                /**
                 * For each facet, we need to duplicate axes
                 * Thus, series should target the axis on which they belong
                 */
                seriesItem.xAxisIndex = (seriesItem.xAxisIndex || 0) + xAxisIndices.length * seriesItemFacetIndex;
                seriesItem.yAxisIndex = (seriesItem.yAxisIndex || 0) + yAxisIndices.length * seriesItemFacetIndex;
                return seriesItem;
            });

        return updatedSeries;
    }

    private getCartesian2DOptionsByFacets(context: {
        options: EChartsOption,
        facetsMetadata: FacetsMetadata,
        chartHeight: number,
        singleXAxis?: boolean,
        displayXAxis?: boolean | null,
        noXAxis?: boolean
    }): EChartsOption {
        const updatedSeries = this.getCartesian2DSeriesByFacets(context.options.series as Array<Cartesian2DSeries>);

        let updatedOptions: EChartsOption = {
            xAxis: [],
            yAxis: [],
            series: updatedSeries,
            grid: [],
            title: []
        };

        const grid = context.options.grid as GridComponentOption;
        const top = isNumber(grid.top) ? grid.top : parseInt(grid.top || '0');
        const bottom = isNumber(grid.bottom) ? grid.bottom : parseInt(grid.bottom || '0');

        updatedOptions = context.facetsMetadata.facets.reduce((acc, facet, facetIndex, facets) => {
            let updatedXAxis: XAXisComponentOption = {
                ...(context.options.xAxis as Array<XAXisComponentOption>)[0],
                gridIndex: facetIndex
            };

            if (context.singleXAxis) {
                updatedXAxis = {
                    ...updatedXAxis,
                    name: undefined,
                    nameTextStyle: undefined,
                    nameGap: 0,
                    nameLocation: EChartNameLocation.END,
                    axisLine: {
                        show: true
                    },
                    splitLine: {
                        show: false
                    },
                    show: true,
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                };
            }

            (acc.xAxis as Array<XAXisComponentOption>).push(updatedXAxis);

            const updatedYAxes: Array<YAXisComponentOption> = (context.options.yAxis as Array<YAXisComponentOption>)
                .map(yAxis => {
                    return {
                        ...yAxis,
                        gridIndex: facetIndex
                    };
                });
            (acc.yAxis as Array<YAXisComponentOption>).push(...updatedYAxes);

            let updatedTop = top;
            let updatedBottom = bottom;
            let height = context.chartHeight - top - bottom;

            if (context.singleXAxis) {
                updatedTop = top + context.chartHeight * facetIndex;
                updatedBottom = context.displayXAxis && !context.noXAxis ? 0 : 1;
                height = context.chartHeight - top - updatedBottom;
            } else {
                if (facetIndex > 0 && acc.grid && 'length' in acc.grid) {
                    updatedTop = (acc.grid[facetIndex - 1].bottom as number) + top + context.chartHeight * facetIndex;
                }

                if (facetIndex < facets.length - 1) {
                    updatedBottom = 0;
                }
            }

            const facetGrid = this.getFacetGridOption(
                grid,
                updatedTop,
                updatedBottom,
                height,
                context.facetsMetadata.maxContainerWidth
            );

            (acc.grid as Array<GridComponentOption>).push(facetGrid);

            const title = context.options.title as TitleComponentOption;
            const facetTitle = this.getFacetTitleOption(
                facet,
                facetIndex,
                top,
                context.chartHeight,
                context.facetsMetadata.maxContainerWidth,
                context.singleXAxis,
                title?.subtext,
                title?.subtextStyle
            );

            (acc.title as Array<TitleComponentOption>).push(facetTitle);

            return acc;
        }, updatedOptions);

        return updatedOptions;
    }

    private drawCartesian2DFacets(
        facets: Array<{ label: string }>,
        chartId: string, chartDef: FrontendChartDef,
        chartData: ChartTensorDataWrapper,
        chartBase: ChartBase,
        echartDef: EChartDef,
        noXAxis: boolean,
        noYAxis: boolean,
        legends: Array<Legend> | null,
        frameIndex: number
    ): { meta?: MetaEChartDef, options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        const facetsMetadata = this.getFacetsMetadata(facets);

        const chartBaseForFacet: ChartBase = {
            ...chartBase,
            height: chartDef.chartHeight,
            width: chartBase.width - facetsMetadata.maxContainerWidth
        };

        const facetsResult = echartDef.draw({
            chartId,
            chartDef,
            chartData,
            chartBase: chartBaseForFacet,
            noXAxis,
            noYAxis,
            legends,
            frameIndex
        });

        const xAxes = facetsResult.options.xAxis as Array<XAXisComponentOption>;
        const yAxes = facetsResult.options.yAxis as Array<YAXisComponentOption>;
        const series = facetsResult.options.series as Array<Cartesian2DSeries>;

        //  Mandatory for manual range
        this.updateManualExtent(chartDef, xAxes, yAxes);

        const meta = this.getMetaEChartDef(
            chartDef,
            xAxes,
            yAxes,
            series,
            facetsResult.options.grid as GridComponentOption,
            facetsMetadata.maxContainerWidth,
            noXAxis
        );

        const updatedOptions = this.getCartesian2DOptionsByFacets({
            options: facetsResult.options,
            facetsMetadata,
            chartHeight: chartDef.chartHeight,
            singleXAxis: chartDef.singleXAxis,
            displayXAxis: chartDef.displayXAxis,
            noXAxis
        });

        return { options: updatedOptions, allCoords: facetsResult.allCoords, meta };
    }

    public draw(
        chartId: string,
        chartDef: FrontendChartDef,
        chartData: ChartTensorDataWrapper,
        chartBase: ChartBase,
        echartDef: EChartDef,
        noXAxis: boolean,
        noYAxis: boolean,
        legends: Array<Legend> | null,
        frameIndex: number,
        facets?: Array<{ label: string }>
    ): { meta?: {height?: string | number, options: EChartsOption}, options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        if (facets && facets.length) {
            if (echartDef instanceof Cartesian2DEChartDef) {
                return this.drawCartesian2DFacets(facets, chartId, chartDef, chartData, chartBase, echartDef, noXAxis, noYAxis, legends, frameIndex);
            } else {
                return this.drawNonCartesian2DFacets(facets, chartId, chartDef, chartData, chartBase, echartDef, legends, frameIndex);
            }
        } else {
            return echartDef.draw({ chartId, chartDef, chartData, chartBase, noXAxis, noYAxis, legends, frameIndex, facetIndex: 0 });
        }
    }

    public clearEvents(echartInstance: any) {
        echartInstance.off('mouseover');
        echartInstance.off('mouseout');
        echartInstance.off('finished');

        try {
            echartInstance.getZr().off('click');
        } catch (e) {
            this.logger.error(e);
        }

        echartInstance.off('globalout');
    }
}
