import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AutocompletableTextareaModule } from '@migration/modules';
import { WorkspaceIconModule } from '../workspace-icon';
import { WorkspaceGeneralEditionComponent } from './workspace-general-edition.component';

@NgModule({
    declarations: [
        WorkspaceGeneralEditionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        WorkspaceIconModule,
        AutocompletableTextareaModule
    ],
    exports: [
        WorkspaceGeneralEditionComponent
    ]
})
export class WorkspaceGeneralEditionModule { }
