import { Aggregation } from '@model-main/pivot/backend/model/aggregation';
import { EChartsOption, TitleComponentOption } from 'echarts';
import { isNumber } from 'lodash';
import { ChartCoordinates, ChartPoint, FrontendChartDef, FrontendMeasureDef } from '../../../interfaces';
import { EChartDrawContext, EChartLegendContext, EChartMatrixPoint, EChartOptionsContext, EChartSeriesContext, EChartSeriesOption } from '../interfaces';
import { EChartPrepareDataContext } from './echart-prepare-data-context.model';

/**
 * Main entrypoint to create an EChart definition
 * This definition provides a "draw" method which must return EChartsOption (an object interpreted by ECharts to draw a chart)
 * A set of utility methods are also defined to provide building steps (getSeries, getOptions etc...)
 */
export abstract class EChartDef {

    constructor() { }

    /**
     * Highlights or downplays an element of the chart on mouseover
     * @param {string} itemLabel        label of the legend item triggering the event
     * @param {Object} echartInstance
     * @param {boolean} mouseEnter      whether it is a mouse in or mouse out event
     */
    onLegendHover = (legendContext: EChartLegendContext): void => {
        const actionType = legendContext.mouseEnter ? 'highlight' : 'downplay';
        legendContext.echartInstance.dispatchAction({
            type: actionType,
            seriesName: legendContext.itemLabel
        });
    };

    afterChange = (echartInstance: any): void => {
        return;
    };

    protected abstract getSeriesId(coord: ChartCoordinates): string;

    /**
     * getAllCoords returns chart coordinates which allow to associate a point to a tooltip
     * @param chartPoints
     * @returns chartCoordinates a matrix of chart coordinates used by tooltips
     */
    protected getAllCoords(chartPoints: Array<ChartPoint>): Array<Array<ChartCoordinates>> {
        let allCoordsMap: Record<string, Array<ChartCoordinates>> = {};

        allCoordsMap = chartPoints.reduce((acc, point) => {
            const coordinatesId = this.getSeriesId(point.coord);
            if (!acc[coordinatesId]) {
                acc[coordinatesId] = [];
            }

            acc[coordinatesId].push(point.coord);

            return acc;
        }, allCoordsMap);

        return Object.values(allCoordsMap);
    }

    /**
     * mapValue transforms the value of a point according to its implementation in children classes
     * @param value
     * @returns modified value
     */
    protected mapValue(value: any): any {
        return value;
    }

    protected isMeasureComputedInPercentage(measure: FrontendMeasureDef): boolean {
        return measure.computeMode === Aggregation.ComputeMode.PERCENTAGE || measure.computeMode === Aggregation.ComputeMode.CUMULATIVE_PERCENTAGE;
    }

    protected prepareData(context: EChartPrepareDataContext): Array<EChartMatrixPoint> {
        const frames = (context.chartData.getAxisLabels('animation') || [null]).map((_: any, index: number) => index);
        const facets = (context.chartData.getAxisLabels('facet') || [null]).map((_: any, index: number) => index);
        const axisLabels = context.axis ? context.chartData.getAxisLabels(context.axis) : [null];
        const colorLabels = context.chartData.getAxisLabels('color') || [null];

        const getPrepareDataReducer = (frameIndex: number, facetIndex: number, colorIndex: number, axisIndex: number, color: any, axisValues: Array<any>) => {
            return (measureAcc: any, measure:FrontendMeasureDef, measureIndex: number) => {
                const coord: ChartCoordinates = {
                    animation: frameIndex,
                    facet: facetIndex,
                    color: colorIndex,
                    measure: measureIndex
                };

                if (context.axis) {
                    coord[context.axis] = axisIndex;
                }

                let value = context.chartData.aggr(measureIndex).get(coord);

                if (context.chartDef.axis1LogScale) {
                    //  If value is null in log scale, we ignore it (echarts bug: https://github.com/apache/echarts/issues/9801)
                    if (value === null) {
                        return measureAcc;
                    }

                    //  If log scale, we need to transform 0 values to 1
                    if (value === 0) {
                        value = 1;
                    }

                    //  Percentage are number between 0 and 1, but a log scale starts at 1
                    if (this.isMeasureComputedInPercentage(measure)) {
                        value = value + 1;
                    }
                }

                const point = {
                    colorLabel: color && color.label,
                    value: context.mapper ? context.mapper(value) : value,
                    axisValues,
                    coord
                };

                measureAcc.push(point);

                return measureAcc;
            };
        };

        const data = frames.reduce((frameAcc: any, frameIndex: number) => {
            return facets.reduce((facetAcc: any, facetIndex: number) => {
                return axisLabels.reduce((axisAcc: any, axisValues: any, axisIndex: number) => {
                    return colorLabels.reduce((colorAcc: any, color: any, colorIndex: number) => {
                        return context.chartDef.genericMeasures.reduce(
                            getPrepareDataReducer(frameIndex, facetIndex, colorIndex, axisIndex, color, axisValues),
                            colorAcc
                        );
                    }, axisAcc);
                }, facetAcc);
            }, frameAcc);
        }, []);

        return data;
    }

    /**
     * getTitle is an utility method which is used to build an ECharts title
     * @param chartPoints
     * @returns A TitleComponentOption (ECharts title options definition)
     */
    protected getTitle(chartPoints: Array<EChartMatrixPoint>): TitleComponentOption | undefined {
        return;
    }

    /**
     * getSeries is an utility method which is used to build series
     * @param seriesContext
     * @returns An array of EChartSeriesOption, an object which defines a piece of series
     */
    protected abstract getSeries(seriesContext: EChartSeriesContext): Array<EChartSeriesOption>;

    /**
     * getOptions is an utility method which is used to build echart options (axes, series, grid)
     * @param optionsContext
     * @returns EChartsOption, an object which defines a chart to be drawn by ECharts
     */
    protected abstract getOptions(optionsContext: EChartOptionsContext): EChartsOption;

    /**
     * getColorSpec is an utility method used to retrieve the color spec of a chart
     * @param chartDef
     * @returns ColorSpec
     */
    abstract getColorSpec(chartDef: FrontendChartDef): any;

    /**
     * draw is the main entrypoint, it is used to retrieve both coordinates (for tooltips) and echart options (to draw the chart)
     * @param drawContext
     */
    abstract draw(drawContext: EChartDrawContext): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> };
}
