import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BasePainterOptions } from '@shared/models/painter';
import { FeedImageModalService } from '@shared/services/image-feed/feed-image-modal.service';
import { CellData } from '@shared/services/image-feed/images-data-fetcher.service';
import { ColumnContent, SampleDataFetcherService } from '@shared/services/image-feed/sample-data-fetcher.service';
import { StaticCanvas } from 'fabric/fabric-impl';
import { map, switchMap } from 'rxjs/operators';
import { SerializedTableChunk } from 'src/generated-sources';

export interface ImageMetaData {
    allColumns: ColumnContent[];
    cellData: CellData;
    rawChunk: SerializedTableChunk,
    valid: boolean
}

export interface FeedImageModalParams {
    imageId?: number
}

@UntilDestroy()
@Component({
    selector: 'feed-image-modal',
    templateUrl: './feed-image-modal.component.html',
    styleUrls: ['./feed-image-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedImageModalComponent implements AfterViewInit {
    currentImage?: ImageMetaData;

    @Input() imageId: number;
    @Input() imageOptions: BasePainterOptions;

    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

    constructor(
        public dataFetcher: SampleDataFetcherService,
        public feedImageModalService: FeedImageModalService,
        public cd: ChangeDetectorRef
    ) {
        this.feedImageModalService.imageId$.pipe(
            untilDestroyed(this),
            switchMap(imageId => this.dataFetcher.getSampleChunk(imageId, 1)),
            map((chunk: SerializedTableChunk) => ({
                allColumns: this.dataFetcher.formatRowAsColumnsContent(chunk.content),
                cellData: this.dataFetcher.formatRow(chunk.content, chunk.firstRow),
                rawChunk: chunk,
                valid: true
            }))
        ).subscribe(image => {
            this.currentImage = image;
            this.feedImageModalService.setImage(this.currentImage);
            this.cd.markForCheck();
        });
    }

    ngAfterViewInit(): void {
        this.setImageId(this.imageId);
    }

    onCanvasUpdate(canvas: StaticCanvas) {
        this.feedImageModalService.setCanvas(canvas);
    }

    setImageId(id: number) {
        this.feedImageModalService.setImageId(id);
    }

    updateImageStatus(hasImage: boolean) {
        if (this.currentImage) {
            this.currentImage = {
                ...this.currentImage,
                valid: hasImage
            }
            this.feedImageModalService.setImage(this.currentImage);
        }
    }
}