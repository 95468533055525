import { Injectable } from "@angular/core";
import { LabelingReviewDataFetcherService, ReviewStatus } from "@features/labeling/services/labeling-review-data-fetcher.service";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject, switchMap, withLatestFrom } from "rxjs";
import { LabelingReviewAnnotationGroupService } from "./labeling-review-annotation-group.service";
import { LabelingReviewImagePathService } from "./labeling-review-image-path.service";
import { LabelingReviewUpdateService } from "./labeling-review-update.service";

@UntilDestroy()
@Injectable()
export class LabelingValidateService {

    validateTrigger$ = new Subject<void>();
    constructor(
        private labelingReviewImagePathService: LabelingReviewImagePathService,
        private labelingReviewUpdateService: LabelingReviewUpdateService, 
        private labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService,
        private labelingService: LabelingService,
        private dataFetcher: LabelingReviewDataFetcherService
    ){  
        this.validateTrigger$.pipe(
            withLatestFrom(
                this.labelingReviewUpdateService.updatingReview$,
                this.labelingReviewUpdateService.currentReview$,
                this.labelingReviewImagePathService.isLast$,
                this.labelingReviewImagePathService.currentImagePath$,
                this.labelingReviewAnnotationGroupService.annotationGroupList$,
            ),
            switchMap(([_trigger, update, currentReview, isLast, path, annotationGroupList]) => {
                if (isLast) {
                    this.labelingReviewImagePathService.finishedReview$.next(true);
                }

                if (update) {
                    return this.labelingService.resolveRecordFromAnnotation(currentReview, path!);
                }

                return this.labelingService.resolveRecordFromAnnotationGroupList(annotationGroupList, path!);
            }),
            withLatestFrom(this.labelingReviewImagePathService.currentIndex$),
            untilDestroyed(this),
        ).subscribe(([verifiedAnswer, index]) => {
            this.dataFetcher.updateItem(index, {
                verifiedAnswer,
                status: ReviewStatus.VALIDATED
            });
            this.labelingReviewImagePathService.nextImage();
        });
    }

    validate() {
        this.validateTrigger$.next();
    }
    
}