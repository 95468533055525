import { Injectable } from "@angular/core";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { AnyLoc, FilterRequest, ImageViewSettings, SerializedMemTableV2, SerializedShakerScript, SerializedTableChunk } from "src/generated-sources";
import { resolveSmartName } from "@utils/loc";
import { WaitingService } from "@core/overlays/waiting.service";
import { ColumnMetadata, ImportantColumnsMetadata, ImportantColumnsMetadataWithAnnotation, SampleDataFetcherService } from "@shared/services/image-feed/sample-data-fetcher.service";
import { SampleDataFormatterService } from "@shared/services/image-feed/sample-data-formatter.service";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class DatasetViewDataFetcherService extends SampleDataFetcherService {

    datasetLoc: AnyLoc;
    script: SerializedShakerScript;
    filters: FilterRequest;
    requestedSampleId: string|null;
    nbColumnsInDataset: number;

    idColumn: string;
    itemPathColIndex: number;
    annotationColumn?: string;

    constructor(private DataikuAPI: DataikuAPIService, private waitingService: WaitingService,
                dataFormatter: SampleDataFormatterService) {
        super(dataFormatter);
    }

    setDatasetViewInfo(contextProjectKey: string, datasetLoc: AnyLoc, imageViewSettings: ImageViewSettings, nbColumnsInDataset: number, script: SerializedShakerScript, filters: FilterRequest, requestedSampleId: string|null) {
        this.datasetLoc = datasetLoc;
        this.script = script;
        this.projectKey = contextProjectKey;
        this.managedFolderLoc = resolveSmartName(contextProjectKey, imageViewSettings.managedFolderSmartId);
        this.filters = filters;
        this.nbColumnsInDataset = nbColumnsInDataset;
        this.requestedSampleId = requestedSampleId;
        this.idColumn = imageViewSettings.pathColumn;

        if (imageViewSettings.annotationParams && imageViewSettings.annotationParams.enabled) {
            this.annotationColumn = imageViewSettings.annotationParams.annotationColumn;;
        }
        this.refetchData();
    }

    refreshSample(): Observable<SerializedMemTableV2> {
        return this.DataikuAPI.datasets.refreshTable(this.projectKey, this.datasetLoc.projectKey, this.datasetLoc.id, 
            this.script, false, this.filters, this.nbColumnsInDataset, this.requestedSampleId)
        .pipe(
            this.waitingService.bindOverlayAndWaitForResult()
        );
    }

    getSampleChunk(offset: number, nbRows: number): Observable<SerializedTableChunk> {
        return this.DataikuAPI.datasets.getTableChunk(this.projectKey, this.datasetLoc.projectKey, this.datasetLoc.id, 
                                                      this.script,
                                                      offset, nbRows, 0, this.nbSelectedColumns, this.filters, this.requestedSampleId);
    }

    buildImportantColumnsMetadata(): ImportantColumnsMetadata<ColumnMetadata> | ImportantColumnsMetadataWithAnnotation<ColumnMetadata> {
        if (this.annotationColumn) {
            return {
                single: {
                    annotation: {
                        name: this.annotationColumn
                    },
                    itemPath: {
                        name: this.idColumn
                    }
                }
            }
        } else {
            return {
                single: {
                    itemPath: {
                        name: this.idColumn
                    }
                }
            }
        }
    }
}