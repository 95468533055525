<modal-header [title]="'Validate all consensual images'" [hasBorder]="true"></modal-header>
<div class="modal-body">
    <p>
        Are you sure you want to validate all consensual images?
    </p>
</div>
<div class="modal-footer modal-footer-std-buttons">
    <div class="pull-right">
        <button type="button" class="btn btn--text btn--secondary" (click)="dialogRef.close(false)">Cancel</button>
        <button type="button" class="btn btn--text btn--primary" (click)="dialogRef.close(true)" >
            Validate
        </button>
    </div>
</div>
