import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { CellDataWithAnnotation } from '@shared/services/image-feed/sample-data-fetcher.service';

@Component({
    selector: 'image-feed-modal-annotation-section',
    templateUrl: './image-feed-modal-annotation-section.component.html',
    styleUrls: [
        './image-feed-modal-annotation-section.component.less',
        '../image-feed-modal/image-feed-modal-shared-style.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageFeedModalAnnotationSectionComponent implements OnChanges {
    @Input() cellData: CellDataWithAnnotation

    categories: string[];
    categoryColorMap: { [key: string]: string } = {};

    constructor(
        public colorMapService: ColorMapContextService
    ) { }

    ngOnChanges(): void {
        this.setColorMap();
    }

    setColorMap() {
        this.categories = this.cellData.listCategories();
        if (this.categories.length > 0) {
            this.categoryColorMap = this.categories.reduce((allCategories, category) => ({
                ...allCategories,
                [category]: this.categoryColorMap[category] || this.colorMapService.getColor(category)
            }), {});
        }
    }
}
