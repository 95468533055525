import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { UIAnnotation } from "@features/labeling/models/annotation";
import { UILabel } from "@features/labeling/models/label";
import { LabelingAnnotationService } from "@features/labeling/services/labeling-annotation.service";
import { LabelingColorService } from "@features/labeling/services/labeling-color.service";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SimpleKeyValue } from "dku-frontend-core";
import { clone } from "lodash";
import { Observable, ReplaySubject, Subject, withLatestFrom } from "rxjs";

@UntilDestroy()
@Component({
    selector: 'category-selector',
    templateUrl: './category-selector.component.html',
    styleUrls: [
        './category-selector.component.less',
        '../../shared-styles/right-panel.less',
        '../../shared-styles/annotation.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CategorySelectorComponent implements OnInit {
    @Input() label: UILabel | null;
    @Output() labelChange = new EventEmitter<UILabel>();
    @Output() categoryChange = new EventEmitter<string>();

    private selectCategoryByIndex$ = new Subject<number>();
    selectedCategory$ = new ReplaySubject<string>(1);

    classes$: Observable<SimpleKeyValue[]>;

    constructor(private labelingColorService: LabelingColorService,
        private labelingAnnotationService: LabelingAnnotationService,
        private labelingService: LabelingService) {        
    }
    
    ngOnInit(): void {
        this.classes$ = this.labelingService.classesWithInstructions$;
        
        this.selectCategoryByIndex$.pipe(
            withLatestFrom(this.classes$),
            untilDestroyed(this),
        ).subscribe(([index, classes]) => {
            if (classes.length > index) {
                this.selectedCategory$.next(classes[index].key);       
            }
        });

        this.selectedCategory$.pipe(
            untilDestroyed(this)
        ).subscribe((category) => {
            this.selectCategory(category);
        });
    }

    categoryIsActive(selectedCategory: string | null, currentCategory: string) {
        return this.label?.selectable ? selectedCategory === currentCategory : this.label?.annotations[0]?.category === currentCategory;
    }

    categoryClicked(event: Event, category: string) {
        this.selectedCategory$.next(category);
        (event.currentTarget as HTMLButtonElement).blur();
    }

    colorStr(category: string, opacity?: number) {
        return this.labelingColorService.categoryToColorString(category, opacity);
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const key = parseInt(event.key);
        if (!isNaN(key)) {
            const classIndex = key !== 0 ? key - 1 : 9;
            this.selectCategoryByIndex$.next(classIndex);
        }
    }

    private selectCategory(category: string) {
        if(!this.label) {
            return;
        }
        const newLabel = clone(this.label);

        if (newLabel.selectable) {
            this.labelingAnnotationService.selectCategory(category);
            newLabel.annotations.filter(a => a.selected).forEach(a => a.category = category);
        } else {
            newLabel.annotations = [new UIAnnotation(category)];
        }

        this.labelChange.emit(newLabel);
        this.categoryChange.emit(category);
    }
}
