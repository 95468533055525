import { isObject } from '@utils/objects';
import { throwError, Observable, EMPTY } from 'rxjs';
import { upgradePromise, EnrichedPromise } from './utils/enriched-promise';
import { catchError } from 'rxjs/operators';
import { SerializedError } from 'dku-frontend-core';
import { ChangeDetectorRef } from '@angular/core';

export interface RawAPIError {
    httpCode: number;
    code?: string;
    message: string;
    details?: string;
    errorType: string;
}

export interface EnhancedSerializedError extends SerializedError {
    httpCode: number;
}

export type APIError = RawAPIError | EnhancedSerializedError;

export function isSerializedError(toTest: any): toTest is SerializedError {
    return isObject(toTest) &&
        'errorType' in toTest &&
        'code' in toTest &&
        'stackTrace' in toTest;
}

export function isEnhancedSerializedError(toTest: APIError): toTest is EnhancedSerializedError {
    return isSerializedError(toTest) &&
        'httpCode' in toTest;
}

export function isRawAPIError(toTest: any): toTest is RawAPIError {
    return isObject(toTest) &&
        'httpCode' in toTest &&
        'errorType' in toTest;
}

export function isAPIError(toTest: any): toTest is APIError {
    return isEnhancedSerializedError(toTest) || isRawAPIError(toTest);
}

export function getErrorDetails(data: any, status: any, headers: any, statusText: string) : APIError {
    /* Network / HTTP error */
    if (data == null && status == -1) {
        return {
            httpCode: status,
            message: "Network error: " + (statusText === undefined ? "" : statusText),
            errorType: "XHRNetworkError"
        };
    }
    if (status == 413) {
        return {
            httpCode: status,
            message: data && data.message || 'No message',
            details: data && data.details || 'No details',
            errorType: "HTTPError413"
        };
    }

    if (data && data.$customMessage) {
        return {
            httpCode: status,
            code: "0",
            message: data.message || "Unknown error",
            details: data.details,
            errorType: data.errorType || "unknown"
        };
    }

    const ctype = headers("Content-type");
    if (ctype && ctype.startsWith("application/json") && data && data.errorType) {
        return {
            ...data,
            httpCode: status
        };
    } else {
        let errorType = "unknown";
        if (status == 502) {
            errorType = "Gateway error";
        }
        return {
            httpCode: status,
            code: "0",
            message: 'Unknown error',
            details: data && data.details || 'No details',
            errorType: errorType
        };
    }
}

export interface ErrorContext { pushError(error: APIError): void; }

function catchAPIErrorFailureAction(errorContext: ErrorContext, rethrowCaught: boolean, err: any, cd: ChangeDetectorRef | undefined = undefined) {
    if (isAPIError(err)) {
        errorContext.pushError(err);
        if (cd) {
            cd.markForCheck();
        }
        if (rethrowCaught) {
            return throwError(err);
        } else {
            return EMPTY;
        }
    } else {
        return throwError(err);
    }
}

export function catchAPIError<T>(errorContext: ErrorContext, rethrowCaught: boolean = false, cd: ChangeDetectorRef | undefined = undefined) {
    return catchError<T, Observable<T>>(err => catchAPIErrorFailureAction(errorContext, rethrowCaught, err, cd));
}

export function upgradePromiseCatchError<T>(promise: EnrichedPromise<T>): Promise<T> {
    return upgradePromise(promise).catch(
        error => {
            const errorDetails: APIError = getErrorDetails(error.data, error.status, error.headers, error.statusText);
            throw errorDetails;
        }
    );
}

