import { StripHtmlPipe } from './text-pipes/strip-html.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntriesPipe } from './entries.pipe';
import { NiceConstPipe } from './nice-const.pipe';
import { InfoMessageAlertClassPipe } from './info-message-alert-class.pipe';

import { CapitalizePipe } from './capitalize.pipe';
import { ReplacePipe } from './replace.pipe';
import { EscapeHtmlPipe } from './escaping/escape-html.pipe';
import { EscapeHighlightedHtmlPipe } from './escaping/escape-highlighted-html.pipe';

import { PercentagePipe } from './number-pipes/percentage.pipe';
import { SmartNumberPipe } from './number-pipes/smart-number.pipe';
import { SmartPercentagePipe } from './smart-percentage.pipe';

import { FriendlyTimeDeltaShortPipe } from './date-pipes/friendly-time-delta-short.pipe';
import { FriendlyDateTimePipe } from './date-pipes/friendly-date-time.pipe';
import { FriendlyDurationPipe } from './date-pipes/friendly-duration.pipe';
import { DurationHHMMSSPipe } from './date-pipes/duration-hhmmss.pipe';
import { FriendlyTimeDeltaHHMMSSPipe } from './date-pipes/friendly-time-delta-hhmmss.pipe';
import { YYYYMMDDHHmmssDateTimePipe } from './date-pipes/yyyymmddhhmmss-date-time.pipe';

import { SliceListPipe } from './list-pipes/slice-list.pipe';
import { OrderByPipe } from '@shared/pipes/list-pipes/order-by.pipe';
import { FormatModalityPipe } from '@shared/pipes/list-pipes/format-modality.pipe';

import { FilesizePipe } from './filesize.pipe';
import { NicePrecisionPipe } from '@shared/pipes/number-pipes/nice-precision.pipe';
import { FriendlyDurationShortPipe } from '@shared/pipes/date-pipes/friendly-duration-short.pipe';
import { MlTaskTypeToIconPipe } from '@shared/pipes/visualization/ml-task-type-to-icon.pipe';
import { BackendTypeToIconPipe } from '@shared/pipes/visualization/backend-type-to-icon.pipe';
import { PlurifyPipe } from '@shared/pipes/text-pipes/plurify.pipe';
import { GentleTruncateBeginningPipe } from '@shared/pipes/text-pipes/gentle-truncate-beginning.pipe';
import { RangePipe } from '@shared/pipes/list-pipes/range.pipe';
import { PluckPipe } from '@shared/pipes/list-pipes/pluck.pipe';
import { FilterPipe } from '@shared/pipes/filter.pipe';
import { DkuEvaluatePermissionsPipe } from './dku-evaluate-permissions.pipe';
import { DelayWhenFalsePipe } from './delay-when-false.pipe';
import { SmarterNumberPipe } from './number-pipes/smarter-number.pipe';
import { MapPipe } from './list-pipes/map.pipe';
import { TypeToIconPipe } from './visualization/type-to-icon.pipe';
import { TypeToColorPipe } from './visualization/type-to-color.pipe';
import { SubtypeToColorPipe } from './visualization/subtype-to-color.pipe';
import { DatasetTypeToIconPipe } from './visualization/dataset-type-to-icon.pipe';
import { InitialsPipe } from './text-pipes/initials.pipe';
import { ColorContrastPipe } from './text-pipes/color-contrast.pipe';
import { MeaningLabelPipe } from './meaning-label.pipe';

const allPipes = [
    EntriesPipe,
    NiceConstPipe,
    InfoMessageAlertClassPipe,

    CapitalizePipe,
    ReplacePipe,
    EscapeHtmlPipe,
    EscapeHighlightedHtmlPipe,

    PercentagePipe,
    SmartPercentagePipe,
    SmartNumberPipe,
    SmarterNumberPipe,

    FriendlyTimeDeltaShortPipe,
    FriendlyDateTimePipe,
    FriendlyDurationPipe,
    DurationHHMMSSPipe,
    FriendlyDurationShortPipe,
    FriendlyTimeDeltaHHMMSSPipe,
    YYYYMMDDHHmmssDateTimePipe,

    SliceListPipe,
    OrderByPipe,
    FormatModalityPipe,
    RangePipe,
    PluckPipe,
    NicePrecisionPipe,
    FilesizePipe,
    PlurifyPipe,
    InitialsPipe,
    ColorContrastPipe,
    FilterPipe,
    StripHtmlPipe,
    GentleTruncateBeginningPipe,
    MapPipe,

    MlTaskTypeToIconPipe,
    BackendTypeToIconPipe,
    TypeToIconPipe,
    TypeToColorPipe,
    SubtypeToColorPipe,
    DatasetTypeToIconPipe,
    MeaningLabelPipe,

    DkuEvaluatePermissionsPipe,
    DelayWhenFalsePipe
];

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: allPipes,
    exports: allPipes,
    providers: allPipes
})
export class PipesModule {
}
