import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';

@Component({
    selector: 'workspace-object-link-favicon',
    template: '<img [src]="imgSrc" alt="" [class.icon-link]="!imgSrc" (error)="handleImgError()">',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceObjectLinkFaviconComponent implements OnChanges {
    @Input() object: Workspace.WorkspaceObject | null;

    imgSrc?: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.object) {
            if (this.object?.htmlLink?.url) {
                this.imgSrc = `${this.object.htmlLink.url}/favicon.ico`;
            } else {
                this.imgSrc = undefined;
            }
        }
    }

    handleImgError(): void {
        this.imgSrc = undefined;
    }
}
