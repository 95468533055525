import { Component, ChangeDetectionStrategy, Input, Inject, NgZone, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceTimeline } from '@model-main/timelines/workspace/workspace-timeline';
import { ITaggingService } from '@model-main/server/services/itagging-service';
import { ProjectsService } from '@model-main/server/services/projects-service';
import { StandardizedSidePanelOptions } from '@migration/modules';
import { merge, cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { CreateModalFromTemplate, DKURootScope } from '../../models';
import { WorkspaceDisplayService, WorkspaceModalService, WorkspaceSecurityService } from '../../services';
import { MigrationStoreService } from '@migration/migration-store.service';

@Component({
    selector: 'workspace-right-panel',
    templateUrl: './workspace-right-panel.component.html',
    styleUrls: ['./workspace-right-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceRightPanelComponent implements OnChanges, OnDestroy {

    objectType = ITaggingService.TaggableType.WORKSPACE.toString();
    options$ = new BehaviorSubject<StandardizedSidePanelOptions | null>(null);

    @Input() workspace: Workspace | null | undefined;
    @Input() object: Workspace.WorkspaceObject | null | undefined;
    @Input() project: ProjectsService.UIProject | null | undefined;

    @Input()
    set timeline(value: WorkspaceTimeline | null | undefined) {
        this.updateOptions({ objectFullInfo: { timeline: (!this.object && value) || undefined } });
    }

    @Input()
    set editable(value: boolean | null | undefined) {
        this.updateOptions({ editable: !!(this.object && value) });
    }

    constructor(
        private ngZone: NgZone,
        private workspaceDisplayService: WorkspaceDisplayService,
        private workspaceSecurityService: WorkspaceSecurityService,
        private workspaceModalService: WorkspaceModalService,
        private migrationStoreService: MigrationStoreService,
        @Inject('$rootScope') private $rootScope: DKURootScope,
        @Inject('CreateModalFromTemplate') private createModalFromTemplate: CreateModalFromTemplate
    ) {
        migrationStoreService.register('standardizedSidePanelOptions', this.options$);

        this.setOptions({
            standardizedSidePanel: {
                opened: false,
                tabToToggle: '',
                slidePanel: () => {
                    this.updateOptions({
                        standardizedSidePanel: {
                            opened: !this.options$.value?.standardizedSidePanel.opened
                        }
                    });
                },
                toggleTab: (tabName: string) => {
                    this.updateOptions({
                        standardizedSidePanel: {
                            tabToToggle: tabName
                        }
                    });
                }
            },
            selection: { selectedObject: {} },
            objectFullInfo: {}
        });
    }

    private setOptions(options: StandardizedSidePanelOptions) {
        this.options$.next(cloneDeep(options));
    }

    private updateOptions(newOptions: Partial<StandardizedSidePanelOptions>) {
        this.setOptions(merge(this.options$.value, newOptions));
    }

    private onObjectUpdate(object?: Workspace.WorkspaceObject | null) {
        if (object) {
            if (this.workspace) {
                const workspace = this.workspace;
                this.updateOptions({
                    removeObject: () => this.ngZone.run(() => this.workspaceModalService.removeWorkspaceObject(workspace, object))
                });
            }

            let partialOptions: Partial<StandardizedSidePanelOptions> = {};

            if (object.appId) {
                this.objectType = 'WORKSPACE_APP';
                partialOptions = {
                    actions: ['source', 'remove'],
                    selection: {
                        selectedObject: {
                            id: object.appId,
                            type: 'APP',
                            nodeType: 'APP',
                            name: object.displayName
                        }
                    },
                    openInstantiationModal: () => this.createModalFromTemplate('/templates/apps/app-instantiation-modal.html', this.$rootScope, 'AppInstantiationModalController')
                };
            } else if (object.reference) {
                this.objectType = `WORKSPACE_${object.reference.type}`;

                const actions = ['star', 'watch', 'source', 'remove'];
                if (object.reference.type === ITaggingService.TaggableType.DATASET) {
                    actions.push('share');
                }

                partialOptions = {
                    actions,
                    selection: {
                        selectedObject: {
                            id: object.reference.id,
                            projectKey: object.reference.projectKey,
                            type: object.reference.type,
                            nodeType: object.reference.type,
                            name: object.displayName
                        }
                    }
                };
            }

            if (this.workspaceSecurityService.canGoToSource(object, this.project)) {
                partialOptions.objectHref = this.workspaceDisplayService.getSourceObjectLink(object);
            }
            
        this.updateOptions(partialOptions);
        }
    }

    private onWorkspaceUpdate(workspace?: Workspace | null) {
        if (workspace) {
            let partialOptions: Partial<StandardizedSidePanelOptions>;

            if (this.object) {
                partialOptions = {
                    selection: {
                        selectedObject: {
                            workspaceKey: workspace.workspaceKey
                        }
                    },
                    canRemove: workspace.currentUserPermissions.write
                };
            } else {
                partialOptions = {
                    selection: {
                        selectedObject: {
                            id: workspace.workspaceKey,
                            type: ITaggingService.TaggableType.WORKSPACE,
                            nodeType: ITaggingService.TaggableType.WORKSPACE,
                            name: workspace.displayName,
                            workspaceKey: workspace.workspaceKey
                        }
                    },
                    objectFullInfo: {
                        workspace
                    }
                };
            }

            this.updateOptions(partialOptions);
        }
    }

    private onProjectUpdate(project?: ProjectsService.UIProject | null) {
        if (this.object && project) {
            const isDataset = this.options$.value?.selection.selectedObject.type === ITaggingService.TaggableType.DATASET;

            const partialOptions: Partial<StandardizedSidePanelOptions> = {
                projectSummary: project,
                canManageSourceProjectExposedElements: isDataset && project.canManageExposedElements,
                canWriteSourceProjectDashboards: isDataset && project.canWriteDashboards,
                canShareToWorkspaces: this.workspaceSecurityService.canShareFromProject(project),
                isObjectSharingRequestEnabled: this.workspaceSecurityService.isObjectSharingRequestEnabled(project)
            };

            this.updateOptions(partialOptions);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.object) {
            this.onObjectUpdate(changes.object.currentValue);
        }
        if (changes.workspace) {
            this.onWorkspaceUpdate(changes.workspace.currentValue);
        }
        if (changes.project) {
            this.onProjectUpdate(changes.project.currentValue);
        }
    }

    ngOnDestroy(): void {
        this.migrationStoreService.unregister('standardizedSidePanelOptions');
    }
}
