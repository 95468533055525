import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DeephubImagesDataService } from 'src/generated-sources';
import { DeephubImageClassificationReportService, ImageClassificationReport } from './deephub-image-classification-report.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { AbstractDeephubReportDataFetcherService } from '@features/deephub/computer-vision/report/services/abstract-deephub-report-data-fetcher.service';
import { SampleDataFormatterService } from '@shared/services/image-feed/sample-data-formatter.service';
import { ColumnMetadata } from '@shared/services/image-feed/sample-data-fetcher.service';


export type DeephubImageClassificationResultsImportantColumnsMetadata<T extends ColumnMetadata> = {
    single: {
        itemPath: T;
        annotation: T;
        prediction: T;
    }
    multiple: {
        probabilities: T[];
    }
}

@UntilDestroy()
@Injectable()
export class DeephubImageClassificationReportDataFetcherService extends AbstractDeephubReportDataFetcherService<ImageClassificationReport, DeephubImagesDataService.ImageClassificationPredictedFilter> {

    constructor(
        DataikuAPI: DataikuAPIService,
        imageClassificationService: DeephubImageClassificationReportService,
        currentRoute: CurrentRouteService,
        dataFormatter: SampleDataFormatterService,
        colorMapService: ColorMapContextService,
        @Inject('COLOR_PALETTES') COLOR_PALETTES: { [palette: string]: string[]; }
    ) {
        super(DataikuAPI, imageClassificationService, currentRoute, colorMapService, dataFormatter, COLOR_PALETTES);
    }

    combineReportFilter(report: ImageClassificationReport, filter: DeephubImagesDataService.ImageClassificationPredictedFilter): DeephubImagesDataService.ImageClassificationPredictedFilter {
        return {...filter, type: "DEEP_HUB_IMAGE_CLASSIFICATION"};
    }

    getDefaultFilter(): Partial<DeephubImagesDataService.ImageClassificationPredictedFilter> {
        return { ascending: false};
    }

    buildImportantColumnsMetadata(): DeephubImageClassificationResultsImportantColumnsMetadata<ColumnMetadata> {
        return {
            single: {
                annotation: {
                    name: this.coreParams.target_variable
                },
                itemPath: {
                    name: this.coreParams.pathColumn
                },
                prediction: {
                    name: 'prediction',
                }
            },
            multiple: {
                probabilities: this.categories.map(category => ({name: "proba_" + category}))
            }
        }
    }
}
