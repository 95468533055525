<div *ngIf="!selectedItem">
    <h4 class="empty-right-column small-lr-padding">Click an item to view details</h4>
</div>
<div class="h100 vertical-flex" *ngIf="selectedItem">
    <div class="column-header noflex">
        <div class="column-header noflex item-header horizontal-flex">
            <div class="noflex object-icon universe-background {{selectedItem._type.toLowerCase()}}">
                <div class="middle"><i class="icon icon-dku-label-feature-store"></i></div>
            </div>
            <h2 class="flex" title="{{selectedItem._source.name}}">
                <span [innerHtml]="formatItemName()"></span>
            </h2>
            <div class="btn-items feature-store__feature-group-actions">
                <a (click)="useInProject()" class="btn btn--secondary btn--icon" alt="Use in project" title="{{useInProjectTitle}}"
                    [ngClass]="{'disabled': disableUseInProject()}" [wt1Click]="'feature-group-use'" [attr.data-qa-smid]="'use-it'+(disableUseInProject()?'-disabled':'')">
                    <i class="icon-dku-share"></i> Use
                </a>
                <a href="{{ datasetLink() }}" class="btn btn--secondary btn--icon" alt="Explore" title="Explore">
                    <i class="icon-dku-explore"></i> Explore
                </a>
                <a (click)="removeFromFeatureStore()" class="btn btn--secondary btn--icon"
                    *ngIf="displayRemoveButton()"
                    [title]="removeFromFeatureStoreLabel()" [ngClass]="{'disabled': !$rootScope.appConfig.globalPermissions.mayManageFeatureStore}"
                    [wt1Click]="'feature-group-unset'" [attr.data-qa-smid]="'remove-from-fs'+(!$rootScope.appConfig.globalPermissions.mayManageFeatureStore?'-disabled':'')">
                    <i class="icon-dku-unset-label-feature-store"></i> Remove
                </a>
            </div>
        </div>
    </div>
        <div class="h100 flex" style="overflow: hidden;">
            <div class="object-details h100" style="padding-top: 15px; overflow-y: auto;">
                <div class="line feature-store__feature-group-actions">
                    <h4 [attr.data-qa-smid]="'source-project-label'">Source project</h4>
                    <p><a href="{{ linkToProject(selectedItem._source.projectKey) }}" class="highlight" [innerHtml]="formatProjectName() | escapeHighlightedHtml"></a></p>
                    <a (click)="showNavigator(selectedItem._source.projectKey, selectedItem._source.id)" class="btn btn--secondary btn--icon" alt="Navigate around" title="Navigate around">
                        <i class="icon-compass" [attr.data-qa-smid]="'navigate-around'"></i>
                    </a>
                    <a class="btn btn--secondary btn--icon" [href]="flowLink(selectedItem._source.projectKey, selectedItem. _source.id)" alt="See in flow" title="See in flow">
                        <i class="icon-dku-nav_flow" [attr.data-qa-smid]="'flow-button'"></i>
                    </a>
                </div>
                <div class="line">
                    <h4>Data connection type</h4>
                    <p>{{itemType()}}</p>
                </div>
                <div class="block" *ngIf="selectedItem._source.shortDesc">
                    <h4>About</h4>
                    <markdown class="markdown-description"
                        [ngClass]="{'highlight-markdown': this.hasShortDescHighlighted()}"
                        [from]=formatShortDesc()></markdown>
                </div>
                <div class="block" *ngIf="selectedItem._source.description">
                    <h4>Description</h4>
                    <markdown class="markdown-description"
                        [ngClass]="{'highlight-markdown': this.hasDescriptionHighlighted()}"
                        [from]=formatDescription()></markdown>
                </div>
                <hr/>

                <div class="line users">
                    <h4>Users</h4>
                    <div>
                        <a *ngFor="let user of selectedItem._source.user" href="/profile/{{user}}/"
                        title="{{ users[user] || user }}" class="avatar"
                        toggle="tooltip" animation="false" container=".catalog-tooltips">
                            <div>
                                <user-picture [user]="user" [size]="24"></user-picture>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="line" *ngIf="details.datasetFullInfo.dataset.creationTag 
                                        && details.datasetFullInfo.dataset.creationTag.lastModifiedOn 
                                        && details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login">
                    <h4>Created</h4>
                    <p class="feature-store__created-modified-info">
                        <span
                            title="{{details.datasetFullInfo.dataset.creationTag.lastModifiedOn | friendlyDateTime}}"
                            toggle="tooltip" animation="false" container=".catalog-tooltips">
                                {{details.datasetFullInfo.dataset.creationTag.lastModifiedOn | friendlyTimeDeltaShort}} by
                        </span>
                        <span>
                            <a href="/profile/{{details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login}}/"
                                class="avatar"
                                title="{{users[details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login] || details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login}}"
                                toggle="tooltip" animation="false" container=".catalog-tooltips">
                                    <user-picture [user]="details.datasetFullInfo.dataset.creationTag.lastModifiedBy.login" [size]="24"></user-picture>
                            </a>
                        </span>
                    </p>
                </div>
                <div class="line" *ngIf="details.datasetFullInfo.dataset.versionTag 
                                        && details.datasetFullInfo.dataset.versionTag.lastModifiedOn 
                                        && details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login">
                    <h4>Last modified</h4>
                    <p class="feature-store__created-modified-info">
                        <span
                            title="{{details.datasetFullInfo.dataset.versionTag.lastModifiedOn | friendlyDateTime}}"
                            toggle="tooltip" animation="false" container=".catalog-tooltips">
                                {{details.datasetFullInfo.dataset.versionTag.lastModifiedOn | friendlyTimeDeltaShort}} by
                        </span>
                        <span>
                            <a href="/profile/{{details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login}}/"
                                class="avatar"
                                title="{{users[details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login || ''] || details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login}}"
                                toggle="tooltip" animation="false" container=".catalog-tooltips">
                                    <user-picture [user]="details.datasetFullInfo.dataset.versionTag.lastModifiedBy.login" [size]="24"></user-picture>
                            </a>
                        </span>
                    </p>
                </div>
                <div class="line">
                    <h4>Last build</h4>
                    <p [innerHtml]="lastBuildInfo()"></p>
                </div>
                <div class="line" *ngIf="selectedItem._source.connection">
                    <h4>Connection</h4>
                    <p>{{selectedItem._source.connection}}</p>
                </div>

                <dss-right-panel-schema
                    wordingOption="feature"
                    [columns]="datasetFeatures"
                    [columnHighlight]="featuresHighlighting"
                ></dss-right-panel-schema>

                <div class="accordion" *ngIf="numberOfUsages > 0">
                    <h4 class="accordion-title" (click)="showUsage=!showUsage">
                        <i [ngClass]="{'icon-chevron-up':showUsage, 'icon-chevron-down':!showUsage}"></i>
                        Usage
                        <span>
                            (<span>{{numberOfUsages}}</span>)
                        </span>
                    </h4>
                    <div [hidden]="!showUsage" class="feature-store__accordion-body">
                        <div class="feature-store__feature-group-project-usage horizontal-flex" *ngFor="let project of details.usages.projectsWithAccess; let last=last;" [ngClass]="{'dku-border-bottom': details.usages.usagesInNonAccessibleProjects > 0 || !last}">
                            <p><a href="{{ linkToProject(project.projectKey) }}" [innerHtml]="project.projectName + ' (' + project.projectKey + ')'"></a></p>
                            <a (click)="showNavigator(project.projectKey, selectedItem._source.projectKey + '.' + selectedItem._source.id)" class="btn btn--secondary btn--icon" alt="Navigate around" title="Navigate around">
                                <i class="icon-compass"></i>
                            </a>
                            <a class="btn btn--secondary btn--icon" [href]="flowLink(project.projectKey, selectedItem._source.projectKey + '.' + selectedItem._source.id)" alt="See in flow" title="See in flow">
                                <i class="icon-dku-nav_flow"></i>
                            </a>
                        </div>
                        <div *ngIf="details.usages.usagesInNonAccessibleProjects > 0" class="feature-store__feature-group-no-access-to-project">
                            <span>+ {{details.usages.usagesInNonAccessibleProjects}} non-accessible project{{details.usages.usagesInNonAccessibleProjects < 2 ? "":"s"}}</span>
                        </div>
                    </div>
                </div>

                <dataset-status [objectName]="''" [checklists]="details.datasetFullInfo.dataset.checklists.checklists" [data]="details.datasetFullInfo" [context]="'feature-store'" [readOnly]="true"></dataset-status>

                <related-by-type [elementsByType]="selectedItem.splitInputsByType" [baseString]="'Related initial'" [baseItemProjectKey]="selectedItem._source.projectKey"></related-by-type>
                <related-by-type [elementsByType]="selectedItem.splitOutputsByType" [baseString]="'Related final'" [baseItemProjectKey]="selectedItem._source.projectKey"></related-by-type>

            </div>
        </div>
</div>
