import { Injectable } from "@angular/core";
import { CellData } from "@shared/services/image-feed/images-data-fetcher.service";
import { ColumnMetadataWithIndex } from "@shared/services/image-feed/sample-data-fetcher.service";
import { SampleDataFormatterService } from "@shared/services/image-feed/sample-data-formatter.service";
import { DeepHubColumnFormat } from "src/generated-sources";
import { DeephubObjectDetectionResultsImportantColumnsMetadata } from "./deephub-object-detection-report-data-fetcher.service";

export interface DeephubObjectDetectionReportCellData extends CellData {
    target: DeepHubColumnFormat.ObjectDetectionTargetItem[];
    prediction: DeepHubColumnFormat.ObjectDetectionPredictedItem[],
    pairing: DeepHubColumnFormat.PairingItem[],
    enrichedValid: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[],
    enrichedFiltered: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[],
}

@Injectable()
export class DeephubObjectDetectionReportDataFormatterService extends SampleDataFormatterService {
    rowToCellData(rowContent: string[], columnsMetadata: DeephubObjectDetectionResultsImportantColumnsMetadata<ColumnMetadataWithIndex>, i: number): DeephubObjectDetectionReportCellData {
        return {
            itemPath: rowContent[columnsMetadata.single.itemPath.index],
            target: JSON.parse(rowContent[columnsMetadata.single.annotation.index]),
            prediction: JSON.parse(rowContent[columnsMetadata.single.prediction.index]),
            pairing: JSON.parse(rowContent[columnsMetadata.single.pairing.index]),
            enrichedValid: JSON.parse(rowContent[columnsMetadata.single.enrichedValid.index]),
            enrichedFiltered: JSON.parse(rowContent[columnsMetadata.single.enrichedFiltered.index]),
            imageId: i
        };
    }
}