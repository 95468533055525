import { Component, Input } from '@angular/core';
import { PainterService } from "@shared/services/image-feed/painter.service";
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PredictionMLTask } from 'src/generated-sources';
import { DeephubDesignDataFetcherService } from '@features/deephub/computer-vision/design/services/deephub-design-data-fetcher.service';
import { ImageClassificationDataFormatterService } from '@shared/services/image-feed/image-classification/image-classification-sample-data-formatter.service';
import { ImageClassificationPainterService } from '@shared/services/image-feed/image-classification/image-classification-painter.service';
import { SampleDataFormatterService } from '@shared/services/image-feed/sample-data-formatter.service';
import { ImagesDataFetcherService } from '@shared/services/image-feed/images-data-fetcher.service';
import { SampleDataFetcherService } from '@shared/services/image-feed/sample-data-fetcher.service';

@Component({
    selector: 'deephub-image-classification-design-image-feed',
    templateUrl: './deephub-image-classification-design-image-feed.component.html',
    providers: [
        ColorMapContextService,
        DeephubDesignDataFetcherService,
        ImageClassificationDataFormatterService,
        ImageClassificationPainterService,
        { provide: SampleDataFormatterService, useExisting: ImageClassificationDataFormatterService},
        { provide: ImagesDataFetcherService, useExisting: DeephubDesignDataFetcherService },
        { provide: SampleDataFetcherService, useExisting: DeephubDesignDataFetcherService },
        { provide: PainterService, useExisting: ImageClassificationPainterService },
    ]
})
export class DeephubImageClassificationDesignImageFeedComponent {
    @Input() mltask: PredictionMLTask.DeepHubPredictionMLTask;
    @Input() selectedCategories?: string[];
    // Explicitly pass pathColumn and managedFolderSmartId to trigger change from Angular
    @Input() pathColumn: string;
    @Input() managedFolderSmartId: string;
};
