import type {EnsembleParams} from './ensemble-params';
import type {MetricParams} from './metric-params';
import type {PredictionModelingParams} from './prediction-modeling-params';

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.PreTrainPredictionModelingParams
 */
export interface PreTrainPredictionModelingParams {
    algorithm: PreTrainPredictionModelingParams.Algorithm;
    autoOptimizeThreshold: boolean;
    autoarima_timeseries_grid: PredictionModelingParams.AutoArimaSpace;
    causal_learning: PredictionModelingParams.CausalLearningMethod;
    computeLearningCurves: boolean;
    custom_mllib_grid: PredictionModelingParams.MLLibCustomGridParams;
    custom_python: PredictionModelingParams.CustomPythonParams;
    deep_learning_sparkling_grid: PredictionModelingParams.H2ODeepLearningGridParams;
    dtc_classifier_grid: PredictionModelingParams.DecisionTreeHyperparametersSpace;
    ensemble_params: EnsembleParams;
    extra_trees_grid: PredictionModelingParams.RandomForestHyperparametersSpace;
    forcedClassifierThreshold: number;
    gbm_sparkling_grid: PredictionModelingParams.H2OGBMGridParams;
    gbt_classifier_grid: PredictionModelingParams.GBTClassificationHyperparametersSpace;
    gbt_regressor_grid: PredictionModelingParams.GBTRegressionHyperparametersSpace;
    glm_sparkling_grid: PredictionModelingParams.H2OGLMGridParams;
    gluonts_deepar_timeseries_grid: PredictionModelingParams.GluonTSDeepARSpace;
    gluonts_gpu_params: PredictionModelingParams.GpuCodeParams;
    gluonts_mqcnn_timeseries_grid: PredictionModelingParams.GluonTSMQCNNSpace;
    gluonts_npts_timeseries_grid: PredictionModelingParams.GluonTSNPTSForecasterSpace;
    gluonts_simple_feed_forward_timeseries_grid: PredictionModelingParams.GluonTSSimpleFeedForwardSpace;
    gluonts_transformer_timeseries_grid: PredictionModelingParams.GluonTSTransformerSpace;
    gridLength: number;
    grid_search_params: PredictionModelingParams.GridSearchParams;
    keras: PredictionModelingParams.KerasCodeParams;
    knn_grid: PredictionModelingParams.KNNHyperparametersSpace;
    lars_grid: PredictionModelingParams.LarsHyperparametersSpace;
    lasso_grid: PredictionModelingParams.LassoHyperparametersSpace;
    least_squares_grid: PredictionModelingParams.LeastSquareHyperparametersSpace;
    lightgbm_classification_grid: PredictionModelingParams.LightGBMHyperParametersSpace;
    lightgbm_regression_grid: PredictionModelingParams.LightGBMHyperParametersSpace;
    logit_grid: PredictionModelingParams.LogisticRegressionHyperparametersSpace;
    max_ensemble_nodes_serialized: number;
    metrics: MetricParams;
    mllib_dt_grid: PredictionModelingParams.MLLibDecisionTreeGridParams;
    mllib_gbt_grid: PredictionModelingParams.MLLibTreesEnsembleGridParams;
    mllib_linreg_grid: PredictionModelingParams.MLLibLinearRegressionGridParams;
    mllib_logit_grid: PredictionModelingParams.MLLibLogisticRegressionGridParams;
    mllib_naive_bayes_grid: PredictionModelingParams.MLLibNaiveBayesGridParams;
    mllib_rf_grid: PredictionModelingParams.MLLibTreesEnsembleGridParams;
    multi_layer_perceptron_classification_grid: PredictionModelingParams.MultiLayerPerceptronHyperParameterSpace;
    multi_layer_perceptron_regression_grid: PredictionModelingParams.MultiLayerPerceptronHyperParameterSpace;
    nb_sparkling_grid: PredictionModelingParams.H2ONaiveBayesGridParams;
    neural_network_grid: PredictionModelingParams.NeuralNetworkHyperparametersSpace;
    pluginAlgoCustomGridSearch: boolean;
    plugin_python_grid: PredictionModelingParams.CustomPythonPluginParams;
    rf_classifier_grid: PredictionModelingParams.RandomForestHyperparametersSpace;
    rf_regressor_grid: PredictionModelingParams.RandomForestHyperparametersSpace;
    rf_sparkling_grid: PredictionModelingParams.H2ORandomForestGridParams;
    ridge_grid: PredictionModelingParams.RidgeRegressionHyperparametersSpace;
    seasonal_loess_timeseries_grid: PredictionModelingParams.SeasonalLoessSpace;
    seasonal_naive_timeseries_grid: PredictionModelingParams.SeasonalNaiveSpace;
    sgd_grid: PredictionModelingParams.SGDClassificationHyperparametersSpace;
    sgd_reg_grid: PredictionModelingParams.SGDRegressionHyperparametersSpace;
    skipExpensiveReports: boolean;
    svc_grid: PredictionModelingParams.SVMHyperparametersSpace;
    svr_grid: PredictionModelingParams.SVMHyperparametersSpace;
    trivial_identity_timeseries_grid: PredictionModelingParams.TrivialIdentityTimeseriesSpace;
    vertica_linreg_grid: PredictionModelingParams.VerticaLinearRegParams;
    vertica_logit_grid: PredictionModelingParams.VerticaLogisticRegParams;
    xgboost_grid: PredictionModelingParams.XGBoostHyperparametersSpace;
}

export namespace PreTrainPredictionModelingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PreTrainPredictionModelingParams$Algorithm
     */
    export enum Algorithm {
        PYTHON_ENSEMBLE = 'PYTHON_ENSEMBLE',
        SPARK_ENSEMBLE = 'SPARK_ENSEMBLE',
        RANDOM_FOREST_CLASSIFICATION = 'RANDOM_FOREST_CLASSIFICATION',
        EXTRA_TREES = 'EXTRA_TREES',
        CUSTOM_PLUGIN = 'CUSTOM_PLUGIN',
        SCIKIT_MODEL = 'SCIKIT_MODEL',
        RANDOM_FOREST_REGRESSION = 'RANDOM_FOREST_REGRESSION',
        RIDGE_REGRESSION = 'RIDGE_REGRESSION',
        LASSO_REGRESSION = 'LASSO_REGRESSION',
        LEASTSQUARE_REGRESSION = 'LEASTSQUARE_REGRESSION',
        LOGISTIC_REGRESSION = 'LOGISTIC_REGRESSION',
        SVC_CLASSIFICATION = 'SVC_CLASSIFICATION',
        SVM_REGRESSION = 'SVM_REGRESSION',
        SGD_CLASSIFICATION = 'SGD_CLASSIFICATION',
        SGD_REGRESSION = 'SGD_REGRESSION',
        GBT_CLASSIFICATION = 'GBT_CLASSIFICATION',
        GBT_REGRESSION = 'GBT_REGRESSION',
        DECISION_TREE_CLASSIFICATION = 'DECISION_TREE_CLASSIFICATION',
        DECISION_TREE_REGRESSION = 'DECISION_TREE_REGRESSION',
        KNN = 'KNN',
        NEURAL_NETWORK = 'NEURAL_NETWORK',
        LARS = 'LARS',
        LIGHTGBM_CLASSIFICATION = 'LIGHTGBM_CLASSIFICATION',
        LIGHTGBM_REGRESSION = 'LIGHTGBM_REGRESSION',
        XGBOOST_CLASSIFICATION = 'XGBOOST_CLASSIFICATION',
        XGBOOST_REGRESSION = 'XGBOOST_REGRESSION',
        MULTI_LAYER_PERCEPTRON_CLASSIFICATION = 'MULTI_LAYER_PERCEPTRON_CLASSIFICATION',
        MULTI_LAYER_PERCEPTRON_REGRESSION = 'MULTI_LAYER_PERCEPTRON_REGRESSION',
        KERAS_CODE = 'KERAS_CODE',
        SPARKLING_DEEP_LEARNING = 'SPARKLING_DEEP_LEARNING',
        SPARKLING_GBM = 'SPARKLING_GBM',
        SPARKLING_RF = 'SPARKLING_RF',
        SPARKLING_GLM = 'SPARKLING_GLM',
        SPARKLING_NB = 'SPARKLING_NB',
        MLLIB_LOGISTIC_REGRESSION = 'MLLIB_LOGISTIC_REGRESSION',
        MLLIB_DECISION_TREE = 'MLLIB_DECISION_TREE',
        MLLIB_LINEAR_REGRESSION = 'MLLIB_LINEAR_REGRESSION',
        MLLIB_RANDOM_FOREST = 'MLLIB_RANDOM_FOREST',
        MLLIB_NAIVE_BAYES = 'MLLIB_NAIVE_BAYES',
        MLLIB_GBT = 'MLLIB_GBT',
        MLLIB_CUSTOM = 'MLLIB_CUSTOM',
        VERTICA_LINEAR_REGRESSION = 'VERTICA_LINEAR_REGRESSION',
        VERTICA_LOGISTIC_REGRESSION = 'VERTICA_LOGISTIC_REGRESSION',
        VIRTUAL_MLFLOW_PYFUNC = 'VIRTUAL_MLFLOW_PYFUNC',
        VIRTUAL_PROXY_MODEL = 'VIRTUAL_PROXY_MODEL',
        TRIVIAL_IDENTITY_TIMESERIES = 'TRIVIAL_IDENTITY_TIMESERIES',
        SEASONAL_NAIVE = 'SEASONAL_NAIVE',
        AUTO_ARIMA = 'AUTO_ARIMA',
        SEASONAL_LOESS = 'SEASONAL_LOESS',
        GLUONTS_NPTS_FORECASTER = 'GLUONTS_NPTS_FORECASTER',
        GLUONTS_SIMPLE_FEEDFORWARD = 'GLUONTS_SIMPLE_FEEDFORWARD',
        GLUONTS_DEEPAR = 'GLUONTS_DEEPAR',
        GLUONTS_TRANSFORMER = 'GLUONTS_TRANSFORMER',
        GLUONTS_MQCNN = 'GLUONTS_MQCNN'
    }
}