<div class="accordion columns" *ngIf="columns.length">
    <h4 class="accordion-title" (click)="showSchema=!showSchema" [attr.data-qa-smid]="'features-title'">
        <i [ngClass]="{'icon-chevron-up':showSchema, 'icon-chevron-down':!showSchema}"></i>
        {{ wordingOption | capitalize }}s
        <span [ngClass]="{'count-matching': hasHighlightedColumns()}">
            (<span>{{columns.length}}</span>)
        </span>
    </h4>
    <div [hidden]="!showSchema" class="right-panel-schema__accordion-body">
        <div class="noflex header last-header dataset-schema-editor-header">
            <span>
                <div class="std-list-search-box list-control-widget right-panel-schema__name-filtering">
                    <span class="add-on" style="font-size: 14px"><i class="icon-dku-search"></i></span>
                    <input type="search"
                        [(ngModel)]="filterName"
                        placeholder="Search&hellip;"
                        class="catalog-search-input ng-pristine ng-valid"
                        style="width:130px!important; margin-right: 30px;"
                        [attr.data-qa-smid]="'features-filter'"
                        />
                </div>
            </span>
        </div>
        <div class="right-panel-schema__table" [attr.data-qa-smid]="'features-table'">
            <table>
                <thead>
                    <tr class="dku-border-bottom">
                        <th class="feature-name-column">{{ wordingOption | capitalize }} name</th>
                        <th class="feature-type-column">Type</th>
                        <th class="feature-meaning-column">Meaning</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let column of getFilteredHighlightedColumns(); let last=last;">
                        <tr [ngClass]="{'dku-border-bottom': !last}">
                            <td><div class="ellipsis right-panel-schema__column-name highlight"
                                [attr.data-qa-smid]="'feature-name'"
                                textOverflowTooltip [textTooltip]="column.name | escapeHighlightedHtml" [allowHtml]="true"
                            ></div></td>
                            <td><span class="mx-textellipsis">{{column.type | lowercase | capitalize}}</span></td>
                            <td><span class="mx-textellipsis">{{column.meaning | meaningLabel }}</span></td>
                            <td><span class="mx-textellipsis" title="{{column.comment}}">
                                    {{column.comment}}
                            </span></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>