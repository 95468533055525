import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PainterImage } from '@shared/models/painter';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PainterService } from '@shared/services/image-feed/painter.service';
import { createGradientColorStyleImg } from '@shared/utils/gradient-color-img';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subject, withLatestFrom } from 'rxjs';
import { DeephubImageClassificationInteractiveScoringCellData } from '../interactive-scoring/image-classification-interactive-scoring-data.service';
import { DeephubImageClassificationImageSectionPainterService } from '../services/deephub-image-classification-report-image-section-painter.service';
import { interpolateViridis } from "d3-scale-chromatic";

@UntilDestroy()
@Component({
    selector: 'deephub-image-classification-report-image-section',
    templateUrl: './deephub-image-classification-report-image-section.component.html',
    styleUrls: [
        './deephub-image-classification-report-image-section.component.less',
        '../shared-styles/deephub-image-classification-legend.less',
        '../../../shared-styles/deephub-interactive-scoring.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DeephubImageClassificationImageSectionPainterService,
        { provide: PainterService, useExisting: DeephubImageClassificationImageSectionPainterService }, 
    ]
})
export class DeephubImageClassificationReportImageSectionComponent implements OnInit{
    @Input() cellData: DeephubImageClassificationInteractiveScoringCellData;

    painterImage$ = new BehaviorSubject<PainterImage>(new PainterImage());
    painterImageUpdateTrigger$ = new Subject<{
        category: string,
        active: boolean
    }>();

    painterImageUpdatePinTrigger$ = new Subject<{
        category: string
    }>();
    gradientColorStyleImg: string;

    constructor( 
        private colorMapContextService: ColorMapContextService,
        private painterService: DeephubImageClassificationImageSectionPainterService
    ) { 
        this.gradientColorStyleImg = createGradientColorStyleImg(interpolateViridis); // keep in sync colorPalette with dataiku/doctor/deephub/image_classification_explaining.py
    }

    ngOnInit(): void {
        this.painterImageUpdatePinTrigger$.pipe(
            untilDestroyed(this)
        ).subscribe(toUpdate => {
            this.cellData.togglePinnedCategory(toUpdate.category);
        })

        this.painterImageUpdateTrigger$.pipe(
            withLatestFrom(
                this.painterImage$
            ),
            untilDestroyed(this)
        ).subscribe(([toUpdate, painterImage]) => {
            this.painterService.toggleMasksVisibility(painterImage, toUpdate.category, toUpdate.active, this.cellData.pinnedCategory);
            this.painterImage$.next(cloneDeep(painterImage));
        })
    }

    getPredictionColor(score: any) {
        return this.colorMapContextService.getColor(score.prediction);
    }

    getFormattedProba(score: any) {
        return (score["proba_" + score.prediction] * 100).toFixed(1);
    }
}
