import { Injectable } from "@angular/core";
import { CellData } from "@shared/services/image-feed/images-data-fetcher.service";
import { ColumnMetadata, ColumnMetadataWithIndex } from "@shared/services/image-feed/sample-data-fetcher.service";
import { SampleDataFormatterService } from "@shared/services/image-feed/sample-data-formatter.service";
import { DeephubImageClassificationResultsImportantColumnsMetadata } from "./deephub-image-classification-report-data-fetcher.service";


export interface DeephubImageClassificationReportCellData extends CellData {
    prediction: string,
    probabilities: Map<string, number>,
    pinnedCategory?: string;
}

export interface DeephubImageClassificationEvalCellData extends DeephubImageClassificationReportCellData {
    target: string,
}

export function isEvalCellData(cellData: DeephubImageClassificationReportCellData): cellData is DeephubImageClassificationEvalCellData {
    return (cellData as DeephubImageClassificationEvalCellData).target !== undefined;
}

@Injectable()
export class DeephubImageClassificationReportDataFormatterService extends SampleDataFormatterService {

    rowToCellData(rowContent: string[], columnsMetadata: DeephubImageClassificationResultsImportantColumnsMetadata<ColumnMetadataWithIndex>, i: number): DeephubImageClassificationReportCellData {
        const cell = {
            itemPath: rowContent[columnsMetadata.single.itemPath.index],
            target: rowContent[columnsMetadata.single.annotation.index],
            prediction: rowContent[columnsMetadata.single.prediction.index],
            probabilities: new Map<string, number>(),
            imageId: i
        };
        for (const column of columnsMetadata.multiple.probabilities) {
            cell.probabilities.set(column.name, parseFloat(rowContent[column.index]));
        }

        return cell;
    }
}