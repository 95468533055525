import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WorkspaceObjectKey } from '@shared/models';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceTimeline } from '@model-main/timelines/workspace/workspace-timeline';

@Injectable({ providedIn: 'root' })
export class PrivateWorkspacesAPI {

    constructor(private dkuHttp: DkuHttpService) { }

    list(accessLevel = "READ"): Observable<Workspace[]> {
        return this.dkuHttp.request('GET', '/workspaces/list', { accessLevel });
    }

    get(workspaceKey: string): Observable<{workspace: Workspace, timeline: WorkspaceTimeline}> {
        return this.dkuHttp.request('GET', '/workspaces/get', { workspaceKey });
    }

    create(workspace: Workspace): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/create', { workspace });
    }

    update(workspace: Workspace): Observable<Workspace> {
        return this.dkuHttp.request('PUT', '/workspaces/update', { workspace });
    }

    save(workspace: Workspace): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/save', { workspace });
    }

    delete(workspaceKey: string): Observable<void> {
        return this.dkuHttp.request('POST', '/workspaces/delete', { workspaceKey });
    }

    getObject(workspaceKey: string, objectKey: WorkspaceObjectKey): Observable<{object: Workspace.WorkspaceObject, workspace: Workspace}> {
        return this.dkuHttp.request('GET', '/workspaces/get-object', { workspaceKey, objectKey });
    }

    addObjects(workspaceKey: string, workspaceObjects: Workspace.WorkspaceObject[]): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/add-objects', { workspaceKey, workspaceObjects });
    }

    removeObjects(workspaceKey: string, objectsToRemove: Workspace.WorkspaceObject[]): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/remove-objects', { workspaceKey, objectsToRemove });
    }

    timeline(workspaceKey: string): Observable<WorkspaceTimeline> {
        return this.dkuHttp.request('GET', '/workspaces/timeline', { workspaceKey });
    }
}
