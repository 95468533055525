<ng-container *ngIf="availableMetricKeys && availableMetricKeys.length">
    <form class="dkuform-horizontal h100 chart-runs-metrics-form" [formGroup]="form">
        <!-- refresh -->
        <button class="refresh-button link-std mleft8 btn btn--text btn--secondary"
                (click)="refresh()"
                [qa]="'experiments-runs-refresh-button'"
                [wt1Click]="'experiment-tracking-refresh-runs'">
            <i class="icon-refresh"></i>
        </button>
        <div class="dib" *ngIf="togglableDisplayMode">
            <button class="btn btn--secondary btn--icon"
                [class.btn--active]="displayMode == DisplayModeEnum.LAST_VALUES"
                [qa]="'experiments-runs-display-mode-last-values'"
                (click)="setDisplayMode(DisplayModeEnum.LAST_VALUES)"
                [wt1Click]="'experiment-tracking-runs-charts-display-last-values'" matTooltip="Last values">
                <i class="icon-bar-chart"></i>
            </button>
            <button class="btn btn--secondary btn--icon" [class.btn--active]="displayMode == DisplayModeEnum.STEPS"
            [qa]="'experiments-runs-display-mode-steps'"
                (click)="setDisplayMode(DisplayModeEnum.STEPS)"
                [wt1Click]="'experiment-tracking-runs-charts-display-steps'" matTooltip="Series">
                <i class="icon-dku-ml-hp-scaling-uniform"></i>
            </button>
        </div>
        <div class="dib control" *ngIf="displayMode == DisplayModeEnum.STEPS">
            <label>Display:</label>
            <div>
                <ul class="chart-options-selector btn-group">
                    <button class="btn btn--secondary" (click)="toggleStepsDisplayMode(false)"
                        [ngClass]="{'btn--active' : !displayStepsAsTSDiff}"
                        [wt1Click]="'experiment-tracking-runs-charts-toggle-steps-display-mode'"
                        matTooltip="Display metrics by steps"
                        [qa]="'experiments-runs-steps-display-mode-steps'">
                        STEP
                    </button>
                    <button class="btn btn--secondary" (click)="toggleStepsDisplayMode(true)"
                        [ngClass]="{'btn--active' : displayStepsAsTSDiff}"
                        [wt1Click]="'experiment-tracking-runs-charts-toggle-steps-display-mode'"
                        matTooltip="Display metrics as relative time"
                        [qa]="'experiments-runs-steps-display-mode-relative-time'">
                        RELATIVE TIME
                    </button>
                </ul>
            </div>
        </div>
        <div class="dib control">
            <label>Metrics:</label>
            <div>
                <dku-bs-select-form-control formControlName="selectedMetrics" ngOptions="metric for metric in list"
                    [list]="availableMetricKeys" [dataLiveSearch]="true" [dkuMultiple]="true" [layout]="'list'"
                    [qa]="'experiments-runs-metrics-select'">
                </dku-bs-select-form-control>
            </div>
        </div>
        <div class="dib control" *ngIf="displayMode == DisplayModeEnum.LAST_VALUES">
            <label>X Axis:</label>
            <div>
                <dku-bs-select-form-control formControlName="selectedParameter" ngOptions="param for param in list"
                    [list]="availableParameterKeys ?? []" [dataLiveSearch]="true" [layout]="'list'"
                    [qa]="'experiments-runs-x-axis-select'">
                </dku-bs-select-form-control>
            </div>
        </div>
        <div class="dib control autorefresh-control pull-right">
            <label class="dku-toggle">
                <input type="checkbox" formControlName="autoRefresh" [qa]="'experiments-runs-auto-refresh'">
                <span></span>
            </label>
            Autorefresh mode
        </div>

        <div class="model-comparator">
            <div class="model-comparator-graph-container"
                [ngClass]="{'model-comparator-graph-container-rows' : !pinnedCharts.size}">
                <ng-container *ngFor="let chartOptions of chartsOptions">
                    <div class="model-comparator-graph pinned" *ngIf="isChartPinned(chartOptions.metric)"
                         [qa]="{'experiments-runs-chart': {metric: chartOptions.metric, pinned: 'true' }}">
                        <span class="title mx-textellipsis" isEllipsisActive
                            [matTooltip]="chartOptions.metric">{{chartOptions.metric}}</span>
                        <experiment-tracking-invalid-metric-value [metricInfo]="chartOptions.metricInfo" [showRuns]="true"></experiment-tracking-invalid-metric-value>
                        <span matTooltip="Un-pin this metric" (click)="unpinChart(chartOptions)"
                            [wt1Click]="'experiment-tracking-runs-chart-unpin'" class="resize">
                            <i class="icon-fixed-width icon-dku-unpin-chart"></i>
                        </span>
                        <lazy-echart [options]="chartOptions" (chartInit)="onChartInit($event, chartOptions)">
                        </lazy-echart>
                    </div>
                </ng-container>
                <ng-container *ngFor="let chartOptions of chartsOptions">
                    <div class="noflex model-comparator-graph" *ngIf="!isChartPinned(chartOptions.metric)"
                         [qa]="{'experiments-runs-chart': {metric: chartOptions.metric, pinned: 'false' }}">
                        <span class="title mx-textellipsis" isEllipsisActive
                            [matTooltip]="chartOptions.metric">{{chartOptions.metric}}</span>
                        <experiment-tracking-invalid-metric-value
                            [metricInfo]="chartOptions.metricInfo"
                            [showRuns]="true"
                            (clickOnRun)="clickAndHoverOnRun($event)">
                        </experiment-tracking-invalid-metric-value>
                        <span matTooltip="Pin this metric" (click)="pinChart(chartOptions)"
                            [wt1Click]="'experiment-tracking-runs-chart-pin'" class="resize">
                            <i class="icon-fixed-width icon-dku-pin-chart"></i>
                        </span>
                        <lazy-echart [options]="chartOptions" (chartInit)="onChartInit($event, chartOptions)">
                        </lazy-echart>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</ng-container>
<div *ngIf="runs?.length && (!availableMetricKeys || !availableMetricKeys.length)"
    class="flex empty-list-cta placeholder">
    <h1 [qa]="'experiments-runs-no-metrics'">
        Selected runs do not have at least one metric defined
    </h1>
    <p [qa]="'experiments-runs-no-metrics-description'">
        Add metrics using the MLflow API
    </p>
    <p>
        <a target="_blank" href="https://doc.dataiku.com/dss/latest/mlops/experiment-tracking/index.html"
           [qa]="'experiments-runs-no-metrics-read-documentation-link'">Read the documentation</a> to learn more.
    </p>
    <div>
        <button class="btn btn--secondary btn--large"
                (click)="refresh()"
                [qa]="'experiments-runs-no-metrics-refresh-button'"
                [wt1Click]="'experiment-tracking-refresh-runs'">
            <i class="icon-refresh"></i>&nbsp;Refresh
        </button>
    </div>
</div>
<div *ngIf="!runs?.length" class="flex empty-list-cta placeholder">
    <h1 [qa]="'experiment-runs-no-selection'">
        No runs selected for display
    </h1>
</div>
