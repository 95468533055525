<ng-container *ngIf="labelingService.labelingTaskInfo$ | async as task">
    <div *ngIf="(labelingReviewAnnotationGroupService.annotationGroupList$ | async) && (labelingReviewAnnotationGroupService.annotationGroupList$ | async)!.length" class="annotations-wrapper h100">
        <ng-container *ngFor="let annotationGroup of labelingReviewAnnotationGroupService.annotationGroupList$ | async; index as index">
            <div (click)="annotationGroup.selectable && selectAnnotationGroup(index)">
                <div class="labeling-annotation" [ngClass]="{ 'selected': annotationGroup.selected, 'selectable': annotationGroup.selectable }">
                    <i class="text-warning labeling-annotation__header-icon icon-dku-label-conflict mright8" *ngIf="annotationGroup.hasConflict()"></i>
                    <i class="text-success labeling-annotation__header-icon icon-dku-label-validate mright8" *ngIf="!annotationGroup.hasConflict() && !annotationGroup.hasMissingCategory()"></i>
                    <i class="text-weak labeling-annotation__header-icon icon-exclamation-sign mright8" *ngIf="annotationGroup.hasMissingCategory()"></i>
                    <span class="name">
                        <ng-container [ngSwitch]="task.type">
                            <ng-container *ngSwitchCase="LabelingTaskType.OBJECT_DETECTION">
                                Region {{index + 1}}
                            </ng-container>
                            <ng-container *ngSwitchCase="LabelingTaskType.IMAGE_CLASSIFICATION">
                                Classification
                            </ng-container>
                        </ng-container>
                    </span>
                    <div class="category mx-textellipsis">
                        <span *ngIf="!annotationGroup.hasConflict() && !annotationGroup.hasMissingCategory()" title="{{annotationGroup.getConsensusCategory()}}" class=" mleft8">{{annotationGroup.getConsensusCategory()}}</span>
                        <span *ngIf="annotationGroup.hasConflict()" class="category--conflict text-warning"> 
                            <span>Conflict</span>
                        </span>
                        <span *ngIf="annotationGroup.hasMissingCategory()" class="category--conflict text-weak"> 
                            <span>Missing category</span>
                        </span>
                    </div>
                    <i class="icon-trash labeling-annotation__header-icon cursor-pointer mleft16" (click)="deleteAnnotationGroup(task.type, index); $event.stopPropagation();" [matTooltip]="getDeleteTooltip(task.type)" [matTooltipPosition]="'above'"></i>
                    <i *ngIf="annotationGroup.selectable" [ngClass]="{'icon-chevron-down': annotationGroup.selected, 'icon-chevron-right': !annotationGroup.selected}" class="carret"></i>
                </div>
                <div *ngIf="annotationGroup.selected">
                    <div *ngFor="let reason of annotationGroup.conflictReasons()" class="conflict-reason padleft24">
                        <i class="conflict-icon icon-warning-sign text-warning" ></i>
                        <div class="mleft8 text-warning"> {{reason}} </div>
                    </div>
                </div>
                <annotation-list *ngIf="annotationGroup.selected && annotationGroup.annotations" (click)="$event.stopPropagation()" [conflicting]="annotationGroup.hasConflict()" [updating]="false"></annotation-list>
            </div>
        </ng-container>
        <p *ngIf="task.type === LabelingTaskType.OBJECT_DETECTION" class="empty-description mtop8">
            <ng-container *ngIf="!(hasSelectedAnnotationGroup$ | async)">
                Click and drag to add new objects
            </ng-container>
            <ng-container *ngIf="(hasSelectedAnnotationGroup$ | async)">
                Click and drag to override annotations
            </ng-container>
        </p>
    </div>
    <div *ngIf="
        (labelingReviewAnnotationGroupService.annotationGroupList$ | async) && 
        !(labelingReviewAnnotationGroupService.annotationGroupList$ | async)!.length && task.type === LabelingTaskType.OBJECT_DETECTION" 
        class="empty-list-cta empty-list-cta--small">
        <h1>No labels yet</h1>
        <p>Draw a box around the target and choose a category</p>
    </div>
</ng-container>    
