import type {ACF} from './timeseries/acf';
import type {Chi2IndTest} from './bivariate/chi2-ind-test';
import type {CorrelationFunction} from './timeseries/correlation-function';
import type {CountDistinct} from './univariate/count-distinct';
import type {Count} from './common/count';
import type {Covariance} from './bivariate/covariance';
import type {DummyComputation} from './common/dummy-computation';
import type {DurbinWatson} from './timeseries/durbin-watson';
import type {EmpiricalCDF} from './univariate/empirical-cdf';
import type {Entropy} from './univariate/entropy';
import type {FetchValues} from './multivariate/fetch-values';
import type {Fit2DDistribution} from './bivariate/fit2-ddistribution';
import type {FitCurve} from './bivariate/fit-curve';
import type {FitDistribution} from './univariate/fit-distribution';
import type {GroupedComputation} from './common/grouped-computation';
import type {GuessTimeStep} from './univariate/guess-time-step';
import type {KendallTau} from './bivariate/kendall-tau';
import type {KsTest2Samp} from './univariate/ks-test2-samp';
import type {Kurtosis} from './univariate/kurtosis';
import type {MannKendallTest} from './timeseries/mann-kendall-test';
import type {MatchTimeStep} from './univariate/match-time-step';
import type {MaxTime} from './univariate/max-time';
import type {Max} from './univariate/max';
import type {Mean} from './univariate/mean';
import type {MinTime} from './univariate/min-time';
import type {Min} from './univariate/min';
import type {MoodTestNSamp} from './univariate/mood-test-nsamp';
import type {MultiComputation} from './common/multi-computation';
import type {MutualInformation} from './bivariate/mutual-information';
import type {OneWayAnova} from './univariate/one-way-anova';
import type {PACF} from './timeseries/pacf';
import type {PCA} from './multivariate/pca';
import type {PairwiseMoodTest} from './univariate/pairwise-mood-test';
import type {PairwiseTTest} from './univariate/pairwise-ttest';
import type {Pearson} from './bivariate/pearson';
import type {Quantiles} from './univariate/quantiles';
import type {ResampledComputation} from './common/resampled-computation';
import type {Sem} from './univariate/sem';
import type {Shapiro} from './univariate/shapiro';
import type {SignTest1Samp} from './univariate/sign-test1-samp';
import type {Skewness} from './univariate/skewness';
import type {Spearman} from './bivariate/spearman';
import type {StdDev} from './univariate/std-dev';
import type {Sum} from './univariate/sum';
import type {TTest1Samp} from './univariate/ttest1-samp';
import type {TestDistribution} from './univariate/test-distribution';
import type {TimeSeriesComputation} from './timeseries/time-series-computation';
import type {UnitRootTestADF} from './timeseries/unit-root-test-adf';
import type {UnitRootTestKPSS} from './timeseries/unit-root-test-kpss';
import type {UnitRootTestZA} from './timeseries/unit-root-test-za';
import type {UnitRootTest} from './timeseries/unit-root-test';
import type {Variance} from './univariate/variance';
import type {ZTest1Samp} from './univariate/ztest1-samp';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.Computation
 */
export interface _Computation {}

export type Computation = Chi2IndTest | Covariance | Fit2DDistribution | FitCurve | KendallTau | MutualInformation | Pearson | Spearman | Count | DummyComputation | GroupedComputation | MultiComputation | ResampledComputation | FetchValues | PCA | ACF | DurbinWatson | MannKendallTest | PACF | UnitRootTestADF | UnitRootTestKPSS | UnitRootTestZA | CountDistinct | EmpiricalCDF | Entropy | FitDistribution | GuessTimeStep | KsTest2Samp | Kurtosis | MatchTimeStep | Max | MaxTime | Mean | Min | MinTime | MoodTestNSamp | OneWayAnova | PairwiseMoodTest | PairwiseTTest | Quantiles | Sem | Shapiro | SignTest1Samp | Skewness | StdDev | Sum | TTest1Samp | TestDistribution | Variance | ZTest1Samp;

export namespace Computation {
    export const type = ['chi2_ind_test', 'covariance', 'fit_2d_distribution', 'fit_curve', 'kendall_tau', 'mutual_information', 'pearson', 'spearman', 'count', 'dummy', 'grouped', 'multi', 'resampled', 'fetch_values', 'pca', 'acf', 'durbin_watson', 'mann_kendall', 'pacf', 'unit_root_adf', 'unit_root_kpss', 'unit_root_za', 'count_distinct', 'empirical_cdf', 'entropy', 'fit_distribution', 'guess_time_step', 'ks_test_2samp', 'kurtosis', 'match_time_step', 'max', 'max_time', 'mean', 'min', 'min_time', 'mood_test_nsamp', 'one_way_anova', 'pairwise_mood_test', 'pairwise_ttest', 'quantiles', 'sem', 'shapiro', 'sign_test_1samp', 'skewness', 'std_dev', 'sum', 'ttest_1samp', 'test_distribution', 'variance', 'ztest_1samp'] as const;
    export function isBivariateComputation(toTest: Computation): toTest is Computation.BivariateComputation { return ['covariance', 'fit_2d_distribution', 'fit_curve', 'kendall_tau', 'mutual_information', 'pearson', 'spearman'].includes(toTest['type']); }
    export function isMultivariateComputation(toTest: Computation): toTest is Computation.MultivariateComputation { return ['fetch_values', 'pca'].includes(toTest['type']); }
    export function isUnivariateComputation(toTest: Computation): toTest is Computation.UnivariateComputation { return ['count_distinct', 'empirical_cdf', 'entropy', 'fit_distribution', 'guess_time_step', 'ks_test_2samp', 'kurtosis', 'match_time_step', 'max', 'max_time', 'mean', 'min', 'min_time', 'mood_test_nsamp', 'one_way_anova', 'pairwise_mood_test', 'pairwise_ttest', 'quantiles', 'sem', 'shapiro', 'sign_test_1samp', 'skewness', 'std_dev', 'sum', 'ttest_1samp', 'test_distribution', 'variance', 'ztest_1samp'].includes(toTest['type']); }
    export function isChi2IndTest(toTest: Computation): toTest is Chi2IndTest { return toTest['type'] === 'chi2_ind_test'; }
    export function isCovariance(toTest: Computation): toTest is Covariance { return toTest['type'] === 'covariance'; }
    export function isFit2DDistribution(toTest: Computation): toTest is Fit2DDistribution { return toTest['type'] === 'fit_2d_distribution'; }
    export function isFitCurve(toTest: Computation): toTest is FitCurve { return toTest['type'] === 'fit_curve'; }
    export function isKendallTau(toTest: Computation): toTest is KendallTau { return toTest['type'] === 'kendall_tau'; }
    export function isMutualInformation(toTest: Computation): toTest is MutualInformation { return toTest['type'] === 'mutual_information'; }
    export function isPearson(toTest: Computation): toTest is Pearson { return toTest['type'] === 'pearson'; }
    export function isSpearman(toTest: Computation): toTest is Spearman { return toTest['type'] === 'spearman'; }
    export function isCount(toTest: Computation): toTest is Count { return toTest['type'] === 'count'; }
    export function isDummyComputation(toTest: Computation): toTest is DummyComputation { return toTest['type'] === 'dummy'; }
    export function isGroupedComputation(toTest: Computation): toTest is GroupedComputation { return toTest['type'] === 'grouped'; }
    export function isMultiComputation(toTest: Computation): toTest is MultiComputation { return toTest['type'] === 'multi'; }
    export function isResampledComputation(toTest: Computation): toTest is ResampledComputation { return toTest['type'] === 'resampled'; }
    export function isFetchValues(toTest: Computation): toTest is FetchValues { return toTest['type'] === 'fetch_values'; }
    export function isPCA(toTest: Computation): toTest is PCA { return toTest['type'] === 'pca'; }
    export function isACF(toTest: Computation): toTest is ACF { return toTest['type'] === 'acf'; }
    export function isCorrelationFunction(toTest: Computation): toTest is CorrelationFunction { return ['acf', 'pacf'].includes(toTest['type']); }
    export function isDurbinWatson(toTest: Computation): toTest is DurbinWatson { return toTest['type'] === 'durbin_watson'; }
    export function isMannKendallTest(toTest: Computation): toTest is MannKendallTest { return toTest['type'] === 'mann_kendall'; }
    export function isPACF(toTest: Computation): toTest is PACF { return toTest['type'] === 'pacf'; }
    export function isTimeSeriesComputation(toTest: Computation): toTest is TimeSeriesComputation { return ['acf', 'durbin_watson', 'mann_kendall', 'pacf', 'unit_root_adf', 'unit_root_kpss', 'unit_root_za'].includes(toTest['type']); }
    export function isUnitRootTest(toTest: Computation): toTest is UnitRootTest { return ['unit_root_adf', 'unit_root_kpss', 'unit_root_za'].includes(toTest['type']); }
    export function isUnitRootTestADF(toTest: Computation): toTest is UnitRootTestADF { return toTest['type'] === 'unit_root_adf'; }
    export function isUnitRootTestKPSS(toTest: Computation): toTest is UnitRootTestKPSS { return toTest['type'] === 'unit_root_kpss'; }
    export function isUnitRootTestZA(toTest: Computation): toTest is UnitRootTestZA { return toTest['type'] === 'unit_root_za'; }
    export function isCountDistinct(toTest: Computation): toTest is CountDistinct { return toTest['type'] === 'count_distinct'; }
    export function isEmpiricalCDF(toTest: Computation): toTest is EmpiricalCDF { return toTest['type'] === 'empirical_cdf'; }
    export function isEntropy(toTest: Computation): toTest is Entropy { return toTest['type'] === 'entropy'; }
    export function isFitDistribution(toTest: Computation): toTest is FitDistribution { return toTest['type'] === 'fit_distribution'; }
    export function isGuessTimeStep(toTest: Computation): toTest is GuessTimeStep { return toTest['type'] === 'guess_time_step'; }
    export function isKsTest2Samp(toTest: Computation): toTest is KsTest2Samp { return toTest['type'] === 'ks_test_2samp'; }
    export function isKurtosis(toTest: Computation): toTest is Kurtosis { return toTest['type'] === 'kurtosis'; }
    export function isMatchTimeStep(toTest: Computation): toTest is MatchTimeStep { return toTest['type'] === 'match_time_step'; }
    export function isMax(toTest: Computation): toTest is Max { return toTest['type'] === 'max'; }
    export function isMaxTime(toTest: Computation): toTest is MaxTime { return toTest['type'] === 'max_time'; }
    export function isMean(toTest: Computation): toTest is Mean { return toTest['type'] === 'mean'; }
    export function isMin(toTest: Computation): toTest is Min { return toTest['type'] === 'min'; }
    export function isMinTime(toTest: Computation): toTest is MinTime { return toTest['type'] === 'min_time'; }
    export function isMoodTestNSamp(toTest: Computation): toTest is MoodTestNSamp { return toTest['type'] === 'mood_test_nsamp'; }
    export function isOneWayAnova(toTest: Computation): toTest is OneWayAnova { return toTest['type'] === 'one_way_anova'; }
    export function isPairwiseMoodTest(toTest: Computation): toTest is PairwiseMoodTest { return toTest['type'] === 'pairwise_mood_test'; }
    export function isPairwiseTTest(toTest: Computation): toTest is PairwiseTTest { return toTest['type'] === 'pairwise_ttest'; }
    export function isQuantiles(toTest: Computation): toTest is Quantiles { return toTest['type'] === 'quantiles'; }
    export function isSem(toTest: Computation): toTest is Sem { return toTest['type'] === 'sem'; }
    export function isShapiro(toTest: Computation): toTest is Shapiro { return toTest['type'] === 'shapiro'; }
    export function isSignTest1Samp(toTest: Computation): toTest is SignTest1Samp { return toTest['type'] === 'sign_test_1samp'; }
    export function isSkewness(toTest: Computation): toTest is Skewness { return toTest['type'] === 'skewness'; }
    export function isStdDev(toTest: Computation): toTest is StdDev { return toTest['type'] === 'std_dev'; }
    export function isSum(toTest: Computation): toTest is Sum { return toTest['type'] === 'sum'; }
    export function isTTest1Samp(toTest: Computation): toTest is TTest1Samp { return toTest['type'] === 'ttest_1samp'; }
    export function isTestDistribution(toTest: Computation): toTest is TestDistribution { return toTest['type'] === 'test_distribution'; }
    export function isVariance(toTest: Computation): toTest is Variance { return toTest['type'] === 'variance'; }
    export function isZTest1Samp(toTest: Computation): toTest is ZTest1Samp { return toTest['type'] === 'ztest_1samp'; }
}

export namespace Computation {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.Computation$UnivariateComputation
     */
    export interface _UnivariateComputation extends _Computation {
        column: string;
    }

    export type UnivariateComputation = CountDistinct | EmpiricalCDF | Entropy | FitDistribution | GuessTimeStep | KsTest2Samp | Kurtosis | MatchTimeStep | Max | MaxTime | Mean | Min | MinTime | MoodTestNSamp | OneWayAnova | PairwiseMoodTest | PairwiseTTest | Quantiles | Sem | Shapiro | SignTest1Samp | Skewness | StdDev | Sum | TTest1Samp | TestDistribution | Variance | ZTest1Samp;

    export namespace UnivariateComputation {
        export const type = ['count_distinct', 'empirical_cdf', 'entropy', 'fit_distribution', 'guess_time_step', 'ks_test_2samp', 'kurtosis', 'match_time_step', 'max', 'max_time', 'mean', 'min', 'min_time', 'mood_test_nsamp', 'one_way_anova', 'pairwise_mood_test', 'pairwise_ttest', 'quantiles', 'sem', 'shapiro', 'sign_test_1samp', 'skewness', 'std_dev', 'sum', 'ttest_1samp', 'test_distribution', 'variance', 'ztest_1samp'] as const;
    }



    /**
     * Generated from com.dataiku.dip.eda.compute.computations.Computation$BivariateComputation
     */
    export interface _BivariateComputation extends _Computation {
        xColumn: string;
        yColumn: string;
    }

    export type BivariateComputation = Covariance | Fit2DDistribution | FitCurve | KendallTau | MutualInformation | Pearson | Spearman;

    export namespace BivariateComputation {
        export const type = ['covariance', 'fit_2d_distribution', 'fit_curve', 'kendall_tau', 'mutual_information', 'pearson', 'spearman'] as const;
    }



    /**
     * Generated from com.dataiku.dip.eda.compute.computations.Computation$MultivariateComputation
     */
    export interface _MultivariateComputation extends _Computation {
        columns: string[];
    }

    export type MultivariateComputation = FetchValues | PCA;

    export namespace MultivariateComputation {
        export const type = ['fetch_values', 'pca'] as const;
    }


}