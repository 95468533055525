import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ModalShape, ModalsService } from "@shared/modals/modals.service";
import { BehaviorSubject, debounceTime, distinctUntilChanged, finalize, Subject, switchMap } from "rxjs";
import { ResolutionDialogComponent } from "../resolution-dialog/resolution-dialog.component";
import { LabelingReviewImagePathService, ReviewFetchType } from "../services/labeling-review-image-path.service";

@UntilDestroy()
@Component({
    selector: 'review-image-feed-header',
    templateUrl: './review-image-feed-header.component.html',
    styleUrls: ['./review-image-feed-header.component.less'],

})
export class ReviewImageFeedHeaderComponent implements OnInit {
    resolvingConsensualRecords$ = new BehaviorSubject<boolean>(false);
    resolutionTrigger$ = new Subject<void>();
    resolutionDialogTrigger$ = new Subject<void>();
    defaultAutoResolveClass = 'icon-dku-label-validate';
    resolveClass$ = new BehaviorSubject<string>(this.defaultAutoResolveClass);
    nbLabeledImages$ = new BehaviorSubject<number>(0);

    reviewForm = this.fb.group({
        reviewFetchType: this.fb.control(ReviewFetchType.RECORDS_TO_REVIEW, [Validators.required]),
        searchQuery: this.fb.control('')
    })

    get ReviewFetchType() {
        return ReviewFetchType;
    }

    constructor(
        private fb: FormBuilder,
        public labelingReviewImagePathService: LabelingReviewImagePathService,
        public labelingService: LabelingService,
        private modalsService: ModalsService,
        private viewContainerRef: ViewContainerRef
    ) { }

    ngOnInit(): void {
        this.labelingReviewImagePathService.itemPaths$.pipe(
            untilDestroyed(this)
        ).subscribe((paths) => {
            this.nbLabeledImages$.next(paths != null ? paths.length : 0);
        });

        this.resolutionTrigger$.pipe(
            switchMap(() => this.labelingService.resolveConsensualRecords()),
            untilDestroyed(this),
            finalize(() => this.resolvingConsensualRecords$.next(false))
        ).subscribe({
            next: () => {
                this.resolvingConsensualRecords$.next(false);
                this.resolveClass$.next('icon-ok');
                setTimeout(() => this.resolveClass$.next(this.defaultAutoResolveClass), 3000);
                this.labelingReviewImagePathService.first();
            },
        });

        this.resolutionDialogTrigger$.pipe(
            untilDestroyed(this)
        ).subscribe(_ => {
            this.modalsService.open(
                ResolutionDialogComponent, 
                undefined,
                ModalShape.NONE,
                this.viewContainerRef,
                {
                    restoreFocus: false,
                    panelClass: ['modal', 'modal3', 'dku-modal-panel-narrow']
                }
            ) .then((confirm)  => {
                if (confirm) {
                    this.resolveConsensualRecords();
                }
            }, () => {});
        });

        this.reviewForm.get('reviewFetchType')?.valueChanges
            .pipe(
                untilDestroyed(this)
            ).subscribe(formValue => {
                this.labelingReviewImagePathService.toggleReviewFetchType(formValue);
            }
        );

        this.reviewForm.get('searchQuery')?.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            untilDestroyed(this)
        ).subscribe(
            (formValue) => {
                this.labelingReviewImagePathService.filterImagePaths(formValue); 
            }
        )

        this.labelingReviewImagePathService.filterQuery$.pipe(
            untilDestroyed(this)
        ).subscribe(query => {
            this.reviewForm.get('searchQuery')?.patchValue(query, {
                emitEvent: false
            });
        });
    }

    openResolveDialog() {
        this.resolutionDialogTrigger$.next();
    }

    resolveConsensualRecords() {
        this.resolvingConsensualRecords$.next(true);
        this.resolutionTrigger$.next();
    }
}
