<ng-container *ngIf="annotations && labelingService.labelingTaskInfo$ | async as task">
    <ng-container *ngFor="let annotation of annotations; let index = index">
        <div class="labeling-annotation" [ngClass]="{ 'selected': annotation.selected, 'labeling-annotation--is-selectable': annotation.isSelectable }" (click)="annotation.isSelectable && selectAnnotation(index)">
            <div *ngIf="task.type === LabelingTaskType.OBJECT_DETECTION && (annotation | instanceOf: UIBoundingBox)" class="thumb-container">
                <bbox-thumb [bboxHeight]="annotation.height" [bboxWidth]="annotation.width" [color]="color(annotation)"></bbox-thumb>
            </div>
            <div
                *ngIf="task.type === LabelingTaskType.IMAGE_CLASSIFICATION && (annotation | instanceOf: UIClassificationAnnotation)"
                [ngStyle]="{
                    'backgroundColor': labelingColorService.categoryToColorString(annotation.category, 0.5), 
                    'borderColor': labelingColorService.categoryToColorString(annotation.category, 0.5)
                }"
                class="color-box">
            </div>
            
            <div class="labeling-annotation__info mright8">
                <ng-container *ngIf="reviewMode === ReviewMode.INITIAL">
                    <ng-container *ngIf="labelingService.allUsersByLogin$ | async as users">
                        <div class="mx-textellipsis" title="{{annotation.category}}">{{annotation.category}}</div>
                        <div class="text-weak mx-textellipsis" *ngIf="annotation.annotator">{{ annotation.annotator | formattedAnnotatorName: users }}</div>
                    </ng-container>
                </ng-container>
                <mat-form-field *ngIf="!annotation.annotator || reviewMode === ReviewMode.UPDATE" (click)="$event.stopPropagation()" class="category-select">
                    <mat-select matInput type="text" name="category" [(ngModel)]="annotation.category" placeholder="Assign a category" (selectionChange)="emitNewAnnotations()">
                        <mat-option *ngFor="let class of classes" [value]="class"> {{class}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="canResolve(annotations.length)" class="accept-answer-wrapper icon-wrapper" [matTooltip]="'Accept this annotation and delete other annotations'" [matTooltipPosition]="'above'">
                <i class="icon-trophy pad8" (click)="resolveAnnotation(index, task.type); $event.stopPropagation()"></i>
            </div>
            <div *ngIf="canDelete(annotation, task.type)" class="delete-box-wrapper icon-wrapper mleft16">
                <i class="icon-trash pad8" (click)="deleteAnnotation(index, task.type)"></i>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="showEmptyState(annotations.length, task.type)">
        <div class="empty-list-cta empty-list-cta--small">
            <h1>No labels</h1>
            <p>Draw a box around the target and choose a category</p>
        </div>
    </ng-container>
</ng-container>