import { Injectable } from '@angular/core';
import { DkuHttpService, FutureWatcherService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LabelingAnswer, LabelingRecord, LabelingRecordToAnnotate, LabelingTask, LabelingTaskStats, ReviewRecordInfo, SerializedMemTableV2, SerializedTableChunk, VerifiedLabelingAnswer } from 'src/generated-sources';

@Injectable({providedIn: 'root'})
export class PrivateLabelingTasksAPI {
    constructor(private dkuHttp: DkuHttpService, private futureWatcherService: FutureWatcherService) {
    }

    get(projectKey: string, labelingTaskId: string): Observable<LabelingTask> {
        return this.dkuHttp.request('GET', '/labelingtasks/get', { projectKey, id: labelingTaskId });
    }

    saveAnswer(projectKey: string, labelingAnswer: Partial<LabelingAnswer>): Observable<LabelingAnswer> {
        return this.dkuHttp.request('POST', '/labelinganswers/save', { projectKey, labelingAnswer });
    }

    resolveRecord(projectKey: string, labelingTaskId: string, labelingAnswer: Partial<LabelingAnswer>): Observable<VerifiedLabelingAnswer> {
        return this.dkuHttp.request('POST', '/labelingtasks/resolve-record', { projectKey, labelingTaskId, labelingAnswer});
    }

    resolveConsensualRecords(projectKey: string, labelingTaskId: string): Observable<boolean> {
        return this.futureWatcherService.requestFuture<boolean>(
            'POST',
             '/labelingtasks/resolve-consensual-records',
            { projectKey, labelingTaskId}
        ).pipe(this.futureWatcherService.waitForResult());
    }
    getAnswerFromAnnotator(projectKey: string, labelingTaskId: string, recordId: string): Observable<LabelingAnswer | null> {
        return this.dkuHttp.request('GET', '/labelinganswers/get-annotation-from-annotator', { projectKey, labelingTaskId, recordId }).pipe(
            map((answer) => answer ? answer as LabelingAnswer : null)
        );
    }

    listReviewRecordInfo(projectKey: string, labelingTaskId: string, recordIds: string[]): Observable<ReviewRecordInfo[]> {
        return this.dkuHttp.request('POST', '/labelinganswers/list-review-record-info', { projectKey, labelingTaskId, recordIds });
    }

    getRecordToAnnotate(projectKey: string, labelingTaskId: string, currentRecordId?: string): Observable<LabelingRecordToAnnotate> {
        return this.dkuHttp.request('GET', '/labelingtasks/get-next-record-to-annotate', { projectKey, labelingTaskId, currentRecordId});
    }

    getRecordsToReview(projectKey: string, labelingTaskId: string): Observable<LabelingRecord[]> {
        return this.dkuHttp.request('GET', '/labelingtasks/get-records-to-review', { projectKey, labelingTaskId });
    }

    getReviewedRecords(projectKey: string, labelingTaskId: string): Observable<LabelingRecord[]> {
        return this.dkuHttp.request('GET', '/labelingtasks/get-reviewed-records', { projectKey, labelingTaskId });
    }

    refreshImagesDataSample(projectKey: string, labelingTaskId: string, nbRows: number) : Observable<SerializedMemTableV2>{
        return this.dkuHttp.request('GET', '/labelingtasks/refresh-images-data-sample', {projectKey, labelingTaskId, nbRows});
    }

    getImagesDataChunk(projectKey: string, labelingTaskId: string, nbRows: number, offset: number) : Observable<SerializedTableChunk>{
        return this.dkuHttp.request('GET', '/labelingtasks/get-images-data-chunk', { projectKey, labelingTaskId, offset, nbRows });
    }

    getStats(projectKey: string,  labelingTaskId: string): Observable<LabelingTaskStats> {
        return this.dkuHttp.request('GET', '/labelingtasks/get-stats', { projectKey, labelingTaskId });
    }

    listAnnotatorIds(projectKey: string,  labelingTaskId: string): Observable<string[]> {
        return this.dkuHttp.request('GET', '/labelingtasks/list-annotator-ids', { projectKey, labelingTaskId });
    }

}

