export const routes = [
    {
        path: 'workspaces',
        options: {
            url: '/workspaces',
            abstract: true,
            template: '<ng2-workspaces-index-page></ng2-workspaces-index-page>'
        }
    },
    {
        path: 'workspaces.home',
        options: {
            url: '',
            template: '<ng2-workspaces-home-page></ng2-workspaces-home-page>',
            pageTitle: () => {
                return 'DSS workspaces';
            }
        }
    },
    {
        path: 'workspaces.workspace',
        options: {
            url: '/:workspaceKey?discussionId',
            template: '<ng2-workspace-page></ng2-workspace-page>',
            pageTitle: () => {
                return 'Workspace';
            }
        }
    },
    {
        path: 'workspaces.object',
        options: {
            url: '/:workspaceKey/:projectKey/:objectType/:objectId?discussionId',
            template: '<ng2-workspace-object-page></ng2-workspace-object-page>',
            pageTitle: () => {
                return 'Workspace Object';
            }
        }
    },
    {
        path: 'workspaces.object.insight',
        options: {
            url: '/insight/:insightId'
        }
    },
    {
        path: 'workspaces.app',
        options: {
            url: '/:workspaceKey/:appId',
            template: '<ng2-workspace-object-page></ng2-workspace-object-page>',
            pageTitle: () => {
                return 'Workspace App';
            }
        }
    }
];
