/**
 * Generated from com.dataiku.dip.pivot.frontend.model.ChartVariant
 */
export enum ChartVariant {
    normal = 'normal',
    stacked_100 = 'stacked_100',
    binned_xy_rect = 'binned_xy_rect',
    binned_xy_hex = 'binned_xy_hex',
    filled_map = 'filled_map',
    donut = 'donut',
    colored = 'colored'
}