<div *ngIf="nav$ | async as nav" class="object-nav horizontal-flex">
    <div class="flex oh">
        <div class="object-breadcrumb">
            <a class="item insight">
                <i class="icon" [ngClass]="[objectColor$ | async, objectType$ | async]"></i>
                <span class="title ellipsis" textOverflowTooltip [textTooltip]="objectName$ | async" tooltipDirection="tooltip-bottom"></span>
            </a>
        </div>
    </div>
    <div class="workspace-object-nav noflex">
        <a class="btn btn--contained btn--primary" *ngIf="nav.back" [ngClass]="{ disabled: nav.back.disabled }"
            [attr.href]="nav.back.href">
            {{nav.back.label}}
        </a>
        <a class="btn btn--outline btn--primary" *ngIf="nav.source" [ngClass]="{ disabled: nav.source.disabled }"
            [attr.href]="nav.source.href">
            {{nav.source.label}}
        </a>
    </div>
</div>
