<div class="review-image-container h100" *ngIf="(labelingReviewImagePathService.finishedReview$ | async) === false && (labelingReviewImagePathService.isEmpty$ | async) === false">
    <div class="image-container">
        <labeling-error></labeling-error>
        <ng-container *ngIf="{
            currentReview: labelingReviewUpdateService.currentReview$ | async,
            updatingReview: labelingReviewUpdateService.updatingReview$ | async,
            imageURL: imageURL$ | async
        } as ctx">
            <ng-container
                *ngIf="labelingService.labelingTaskInfo$ | async as task"
                [ngSwitch]="task.type"
            >
                <ng-container *ngSwitchCase="LabelingTaskType.OBJECT_DETECTION">
                    <object-detection-image-canvas
                        *ngIf="ctx.updatingReview && (ctx.currentReview | instanceOf: ObjectDetectionUILabel) && ctx.imageURL"
                        class="w100 h100"
                        [imagePath]="ctx.imageURL"
                        [label]="ctx.currentReview"
                        (labelChange)="labelingReviewUpdateService.currentReview$.next($event)">
                    </object-detection-image-canvas>
                    <object-detection-region-selector
                        *ngIf="!ctx.updatingReview && ctx.imageURL"
                        class="w100 h100"
                        [imagePath]="ctx.imageURL">
                    </object-detection-region-selector>
                </ng-container>
                <ng-container *ngSwitchCase="LabelingTaskType.IMAGE_CLASSIFICATION">
                    <image-container class="image-container__classification" *ngIf="ctx.imageURL" [src]="ctx.imageURL" [scale]="3" [scaleOrigin]="'center'" [placeholderSize]="'large'"></image-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div class="annotation-group-list__container h100">
        <div class="annotation-group-list__scroll">
            <annotation-group-list *ngIf="(labelingReviewUpdateService.updatingReview$ | async) === false"></annotation-group-list>
            <annotation-list *ngIf="(labelingReviewUpdateService.updatingReview$ | async)" [updating]="true"></annotation-list>
        </div>
    </div>
</div>
<div *ngIf="labelingReviewImagePathService.finishedReview$ | async" class="empty-list-cta">
    <div>
        <h1>
            All good!
        </h1>
        <p class="small">
                The review session is finished
        </p>
        <p class="small" *ngIf="privilegesOnTask.canReadConf && (labelingService.labelingTaskInfo$ | async)?.labelsDataset as datasetName">
            Browse the
            <a href="{{$state.href('projects.project.datasets.dataset.explore', {datasetName: datasetName})}}">
            output labels dataset
            </a>
        </p>
    </div>
</div>
<div *ngIf="(labelingReviewImagePathService.isFiltered$ | async) === false && (labelingReviewImagePathService.isEmpty$ | async) === true" class="empty-list-cta">
    <h1>
        <review-image-empty></review-image-empty>
    </h1>
    <p class="small" *ngIf="privilegesOnTask.canReadConf">
        Go to <a  href="{{$state.href('projects.project.labelingtasks.labelingtask.overview')}}"> overview</a>
    </p>
</div>
