import { Injectable } from "@angular/core";

export enum ShortcutAction {
    DELETE="DELETE",
    BACK="BACK",
    NEXT="NEXT",
    SKIP="SKIP",
    FIRST="FIRST",
    LAST="LAST",
    MULTI_SELECTION="MULTI_SELECTION"
};
@Injectable()
export class LabelingShortcutService {
    
    isShortcut(event: KeyboardEvent, action: ShortcutAction): boolean {
        const shortcuts = {
            MULTI_SELECTION: [event.key === "Shift"],
            DELETE: [event.key === "Delete", event.key === "Backspace"],
            BACK: [event.code === 'ArrowLeft', event.key === 'a', event.key === 'q'],
            NEXT: [event.code === 'ArrowRight', event.key === 'd'],
            SKIP: [event.key === 'x'],
            FIRST: [event.code === 'ArrowUp', event.key === 'w', event.key === 'z'],
            LAST: [event.code === 'ArrowDown', event.key === 's'],
        }
        return shortcuts[action].some((x) => x);
    }
}