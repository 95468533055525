import { Inject, Injectable } from '@angular/core';
import { EChartsOption, TitleComponentOption, PieSeriesOption } from 'echarts';
import { isNumber } from 'lodash';
import { EChartSeriesContext, EChartOptionsContext, EChartMatrixPoint, EChartPrepareDataContext, EChartDef, EChartLegendContext, EChartDrawContext } from '../../echarts';
import { ChartAxisTypes, ChartLabels } from '../../../enums';
import { ChartCoordinates, FrontendChartDef } from '../../../interfaces';
import { ChartIAE, ChartTensorDataWrapper } from '../../../models';

@Injectable({
    providedIn: 'root'
})
export class PieEChartDefService extends EChartDef {
    onLegendHover = (legendContext: EChartLegendContext): void => {
        const actionType = legendContext.mouseEnter ? 'highlight' : 'downplay';
        legendContext.echartInstance.dispatchAction({
            type: actionType,
            name: legendContext.itemLabel
        });
    };

    constructor(
        @Inject('ChartFormatting') private chartFormattingService: any,
        @Inject('CHART_FORMATTING_OPTIONS') private CHART_FORMATTING_OPTIONS: any
    ) {
        super();
    }

    public getColorSpec(chartDef: FrontendChartDef) {
        return { type: ChartAxisTypes.DIMENSION, name: 'color', dimension: chartDef.genericDimension0[0] };
    }

    protected mapValue(value: number): number | null {
        if (value < 0) {
            throw new ChartIAE('Cannot represent negative values on a pie chart. Please use another chart type.');
        }

        return value > 0 ? value : null;
    }

    protected getSeriesId(coord: ChartCoordinates): string {
        const frameIndex = coord.animation;
        const facetIndex = coord.facet;
        const measureIndex = coord.measure;
        const seriesId = `animation${frameIndex}-facet${facetIndex}-measure${measureIndex}`;
        return seriesId;
    }

    protected getSeries({
        chartDef,
        chartData,
        chartWidth,
        chartHeight,
        colorScale,
        chartPoints,
        frameIndex
    }: EChartSeriesContext): Array<PieSeriesOption> {
        if (chartDef && chartData && chartWidth && chartHeight && colorScale) {
            return this.buildSeries(
                chartDef,
                chartData,
                colorScale,
                chartPoints as Array<EChartMatrixPoint>,
                frameIndex
            );
        } else {
            throw new Error('Missing properties to build series for pie echart');
        }
    }

    private buildOneSeries(
        seriesId: string,
        chartDef: FrontendChartDef
    ) {
        const seriesItem: PieSeriesOption = {
            id: seriesId,
            type: 'pie',
            emphasis: {
                labelLine: {
                    show: chartDef.showInChartValues || chartDef.showInChartLabels
                },
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            },
            animationDuration: 300,
            labelLine: {
                show: chartDef.showInChartValues || chartDef.showInChartLabels
            },
            data: []
        };

        if (chartDef.variant === 'donut') {
            /*
             * When putting 100% as outer radius, the labels do not have room to be displayed thus are overlapping with the donut.
             * So instead we go up to 80 but no more so that the labels can be displayed. Hole size should be adapted consequently.
             */
            seriesItem.radius = [chartDef.pieOptions.donutHoleSize * 0.8 + '%', '80%'];
        }

        return seriesItem;
    }

    protected buildSeries(
        chartDef: FrontendChartDef,
        chartData: ChartTensorDataWrapper,
        colorScale: ((index: number) => string) | undefined,
        chartPoints: Array<EChartMatrixPoint>,
        frameIndex: number
    ): Array<PieSeriesOption> {
        let seriesMap: Record<string, PieSeriesOption> = {};

        seriesMap = chartPoints.reduce((acc, point) => {
            if (frameIndex === point.coord.animation) {
                const measureIndex = point.coord.measure;
                const colorIndex = isNumber(point.coord.color) ? point.coord.color : 0;

                const measure = chartDef.genericMeasures[measureIndex];
                const color = colorScale && colorScale(colorIndex + measureIndex);
                const formatValue = this.chartFormattingService.createNumberFormatter(measure, chartData.getAggrExtent(measureIndex), 1000);

                const seriesId = this.getSeriesId(point.coord);

                if (!acc[seriesId]) {
                    acc[seriesId] = this.buildOneSeries(
                        seriesId,
                        chartDef
                    ) ;
                }

                const dataItem = {
                    value: point.value,
                    name: point.colorLabel,
                    itemStyle: {
                        color: color,
                        opacity: chartDef.colorOptions.transparency
                    },
                    label: {
                        fontFamily: this.CHART_FORMATTING_OPTIONS.FONT_FAMILY,
                        fontSize: this.CHART_FORMATTING_OPTIONS.LABELS_FONT_SIZE,
                        color: this.CHART_FORMATTING_OPTIONS.COLOR,
                        formatter: (params: any) => {
                            let displayedText = '';
                            if (chartDef.showInChartLabels) {
                                displayedText = params.name;
                                if (chartDef.showInChartValues) {
                                    displayedText += ' - ' + formatValue(params.value);
                                }
                            } else {
                                if (chartDef.showInChartValues) {
                                    displayedText = formatValue(params.value);
                                }
                            }
                            return displayedText.replace(new RegExp(`\\b${ChartLabels.NO_VALUE}\\b`, 'gi'), 'No value');
                        }
                    }
                };

                if (Array.isArray(acc[seriesId].data)) {
                    acc[seriesId].data?.push(dataItem);
                }
            }

            return acc;
        }, seriesMap);

        return Object.values(seriesMap);
    }

    protected getTitle(chartPoints: Array<EChartMatrixPoint>): TitleComponentOption | undefined {
        if (chartPoints?.length === 0) {
            return {
                subtextStyle: {
                    fontSize: this.CHART_FORMATTING_OPTIONS.LABELS_FONT_SIZE,
                    fontFamily: this.CHART_FORMATTING_OPTIONS.FONT_FAMILY,
                    color: this.CHART_FORMATTING_OPTIONS.COLOR
                },
                subtext: '(No data)'
            };
        }

        return;
    }

    protected getOptions({ series, gridOptions, title }: EChartOptionsContext): EChartsOption {
        const options: EChartsOption = {
            grid: gridOptions,
            series
        };

        if (title) {
            options.title = title;
        }

        return options;
    }

    draw(drawContext: EChartDrawContext): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        const gridOptions = drawContext.chartBase.margins;

        const prepareDataContext: EChartPrepareDataContext = {
            chartDef: drawContext.chartDef,
            chartData: drawContext.chartData,
            legends: drawContext.legends,
            colorScale: drawContext.chartBase.colorScale,
            mapper: value => this.mapValue(value)
        };

        const chartPoints = this.prepareData(prepareDataContext);

        const chartWidth = drawContext.chartBase.width - (gridOptions.left || 0) - (gridOptions.right || 0);
        const chartHeight = drawContext.chartBase.height - (gridOptions.top || 0) - (gridOptions.bottom || 0);

        const seriesContext: EChartSeriesContext = {
            chartDef: drawContext.chartDef,
            chartData: drawContext.chartData,
            chartWidth,
            chartHeight,
            colorScale: drawContext.chartBase.colorScale,
            chartPoints,
            frameIndex: drawContext.frameIndex || 0
        };

        const series = this.getSeries(seriesContext);

        const title = this.getTitle(chartPoints);

        const optionsContext: EChartOptionsContext = {
            series,
            gridOptions,
            title
        };

        const options = this.getOptions(optionsContext);

        const allCoords = this.getAllCoords(chartPoints);

        return {
            options,
            allCoords
        };
    }
}
