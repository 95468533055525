import { Injectable } from '@angular/core';
import { PrivateDatasetsAPI } from './definitions/private-datasets-api';
import { PrivateStatisticsAPI } from './definitions/private-statistics-api';
import { PrivateProjectsAPI } from './definitions/private-projects-api';
import { PrivateDashboardsAPI } from './definitions/private-dashboards-api';
import { PrivateTaggableObjectsAPI } from './definitions/private-taggable-objects-api';
import { PrivateAnalysisAPI } from './definitions/private-analysis-api';
import { PrivateFlowAPI } from './definitions/private-flow-api';
import { PrivateLabelingTasksAPI } from './definitions/private-labeling-tasks-api';
import { PrivateWorkspacesAPI } from './definitions/private-workspaces-api';
import { PrivateAppsAPI } from './definitions/private-apps-api';
import { PrivateSecurityAPI } from './definitions/private-security-api';
import { PrivateExperimentTrackingAPI } from './definitions/private-experiment-tracking-api';
import { PrivateManagedFoldersAPI } from './definitions/private-managed-folders-api';
import { PrivateCodeEnvsAPI } from './definitions/private-code-envs-api';
import { PrivateSavedModelsAPI } from './definitions/private-saved-models-api'
import { PrivateFeatureStoresAPI } from './definitions/private-feature-store-api';
import { PrivateCatalogAPI } from './definitions/private-catalog-api';
import { PrivateInterestsAPI } from './definitions/private-interests-api';
import { PrivateWikisAPI } from './definitions/private-wikis-api';
import { PrivateWebappsAPI } from './definitions/private-webapps-api';
import { PrivateExploresAPI } from './definitions/private-explores-api';

@Injectable({
    providedIn: 'root'
})
export class DataikuAPIService {
    constructor(
        public datasets: PrivateDatasetsAPI,
        public statistics: PrivateStatisticsAPI,
        public taggableObjects: PrivateTaggableObjectsAPI,
        public projects: PrivateProjectsAPI,
        public flow: PrivateFlowAPI,
        public dashboards: PrivateDashboardsAPI,
        public analysis: PrivateAnalysisAPI,
        public labelingTasks: PrivateLabelingTasksAPI,
        public workspaces: PrivateWorkspacesAPI,
        public apps: PrivateAppsAPI,
        public security: PrivateSecurityAPI,
        public experiments: PrivateExperimentTrackingAPI,
        public managedFolders: PrivateManagedFoldersAPI,
        public codeEnvs: PrivateCodeEnvsAPI,
        public savedModels: PrivateSavedModelsAPI,
        public featureStore: PrivateFeatureStoresAPI,
        public catalog: PrivateCatalogAPI,
        public interests: PrivateInterestsAPI,
        public wikis: PrivateWikisAPI,
        public webapps: PrivateWebappsAPI,
        public explores: PrivateExploresAPI
    ) { }
}
