import { Injectable } from "@angular/core";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Chunk } from "@shared/components/infinite-scroll/infinite-scroll.component";
import { CellData, ImagesDataFetcherService } from "@shared/services/image-feed/images-data-fetcher.service";
import { deepDistinctUntilChanged } from "dku-frontend-core";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ReviewRecordInfo } from "src/generated-sources";
import { LabelingReviewImagePathService } from "../labeling-task-review/services/labeling-review-image-path.service";
import { getImageURL } from "../utils";
import { LabelingService } from "./labeling.service";

export enum ReviewStatus {
    CONFLICTING = 'CONFLICTING',
    CONSENSUS = 'CONSENSUS',
    VALIDATED = 'VALIDATED'
}

export class LabelingReviewCellData implements CellData {
    itemPath: string;
    imageId: number;
    status: ReviewStatus;
}

export type LabelingReviewRecord = LabelingReviewCellData & ReviewRecordInfo;

@UntilDestroy()
@Injectable()
export class LabelingReviewDataFetcherService extends ImagesDataFetcherService {
    labelingTaskId: string;
    CHUNK_SIZE = 10;

    constructor(private labelingService: LabelingService, private DataikuAPI: DataikuAPIService, private labelingReviewImagePathService: LabelingReviewImagePathService) {
        super();

        this.labelingService.labelingTaskInfo$.pipe(untilDestroyed(this)).subscribe((info) => {
            this.refetchData();
            this.labelingTaskId = info.labelingTaskId;
            this.projectKey = info.projectKey;
            this.managedFolderLoc = info.getManagedFolderLoc();
        })

        this.labelingReviewImagePathService.itemPaths$.pipe(untilDestroyed(this)).subscribe(() => {
            this.refetchData();
        });
    }

    getImagePath(itemPath: string): string {
        return getImageURL(this.projectKey, this.labelingTaskId, this.managedFolderLoc, itemPath);
    }

    updateItem(index: number, item: any): void {
        super.updateItem(index, item);
        this.labelingReviewImagePathService.updateCurrentReviewRecord(item);
    }

    getChunk(offset: number): Observable<Chunk> {
        return this.labelingReviewImagePathService.itemPaths$.pipe(
            deepDistinctUntilChanged(),
            switchMap(paths => {
                const chunkedPaths = paths.slice(offset, offset + this.CHUNK_SIZE);
                return this.labelingService.listReviewRecordInfo(chunkedPaths).pipe(
                    map((recordReviewInfo) => {
                        return {
                            chunkItems: chunkedPaths.map((path, index) => {
                                return {
                                    imageId: index + offset,
                                    itemPath: path,
                                    status: this.getRecordStatus(recordReviewInfo[index]),
                                    ...recordReviewInfo[index]
                                }
                            }),
                            totalItems: paths.length,
                        }
                    })
                )
            }),
        );
    }

    getRecordStatus(recordReviewInfo: ReviewRecordInfo): ReviewStatus {
        if (recordReviewInfo.verifiedAnswer) {
            return ReviewStatus.VALIDATED;
        } else if (recordReviewInfo.regions.some(region => region.conflicting)) {
            return ReviewStatus.CONFLICTING;
        }
        
        return ReviewStatus.CONSENSUS;
    }
}
