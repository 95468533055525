import { DOCUMENT } from '@angular/common';
import { Component, ChangeDetectionStrategy, ElementRef, AfterViewInit, ViewChild, OnDestroy, Inject, Input } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ControlKeyCodes, NgSelectMonkeyPatchDirective } from '@shared/directives/ng-select-monkey-patch.directive';

/**
 * Custom search input for <ng-select>. Usage:
 * - Set [searchable]="false" on <ng-select> to disable default search behavior
 * - Add <ng-select-search-input> to <ng-template ng-header-tmp>
 */
@Component({
    selector: 'ng-select-search-input',
    templateUrl: './ng-select-search-input.component.html',
    styleUrls: ['./ng-select-search-input.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgSelectSearchInputComponent implements AfterViewInit, OnDestroy {
    @ViewChild('searchInput') searchInput: ElementRef;

    @Input()
    placeholder: string = 'Filter...';

    constructor(public ngSelect: NgSelectComponent, private ngSelectMonkeyPatchDirective: NgSelectMonkeyPatchDirective, @Inject(DOCUMENT) private document: Document) { }

    ngAfterViewInit() {
        // Steal the focus to allow the user to start typing as soon as the dropdown is opened
        this.searchInput.nativeElement.focus();
    }

    ngOnDestroy() {
        // Save the focus and gently give it to <ng-select> when the dropdown gets closed
        if (this.document.activeElement?.contains(this.searchInput.nativeElement)) {
            this.ngSelect.focus();
        }
    }

    // Forward keyboard events to the <ng-select> to preserve keyboard navigation
    handleKeydown(event: KeyboardEvent) {
        // Only control keys
        if (ControlKeyCodes[event.which]) {
            // Re-dispatch our new synthetic event on the <ng-select> element via NgSelectMonkeyPatchDirective
            this.ngSelectMonkeyPatchDirective.dispatchSyntheticKeyboardEvent(event);

            // Stop the original event
            event.stopPropagation();
        }
    }
}
