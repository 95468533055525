<div class="header">
    <span class="header-title">Categories</span>
    <span class="header-subtitle">Select category to apply</span>
</div>

<div class="categories-container">
    <button *ngFor="let clazz of (classes$ | async); let index = index;" class="btn btn--secondary labeling__right-panel-button category-button"
            [ngClass]="{ 'active': categoryIsActive((selectedCategory$ | async), clazz.key) }" (click)="categoryClicked($event, clazz.key)">
        <div [ngStyle]="{'backgroundColor': colorStr(clazz.key), 'borderColor': colorStr(clazz.key)}" class="color-box"> </div>
        <span class="category" title="{{clazz.key}}">{{clazz.key}} </span>
        <i class="category-info icon-info-sign" *ngIf="clazz.value" [matTooltip]="clazz.value"></i>
        <span class="keybind keybind--digit mleft4" *ngIf="index<=9">{{index === 9 ? 0 : index + 1}}</span>
    </button>
</div>
