<div class="h100 oa row-fluid">
    <div class="h100">
        <form class="fh oa dkuform-horizontal" [formGroup]="settingsForm">
            <sidebar-nav>
                <sidebar-nav-item label="Data" id="data">
                    <labeling-settings-data (partialTaskChange)="partialTaskChange.emit($event)"></labeling-settings-data>
                </sidebar-nav-item>
                <sidebar-nav-item label="Classes" id="classes">
                    <div class="h100 vertical-flex">
                        <labeling-error></labeling-error>
                        <h1>Classes</h1>
                        <div class="section flex">
                            <div class="alert alert-info">
                                Add the classes you want to make available for labelers. You can also add instructions to help labelers select the correct classes, which will be displayed when hovering over <i class="category-info icon-info-sign"></i> on the class button.
                            </div>
                            <key-values-list formControlName="classes" [required]="false" [addLabel]="'Add class'" [keyPlaceholder]="'Class'" [valuePlaceholder]="'Instructions'" [disableRawEdit]="false" [fullHeightList]="true" [useVirtualScroll]="true" [virtualScrollItemSize]="33"></key-values-list>
                        </div>
                    </div>
                </sidebar-nav-item>
                <sidebar-nav-item label="Task Setup" id="task-setup">
                    <labeling-error></labeling-error>
                    <h1>Task Setup</h1>
                    <div class="section">
                        <h2>Instructions</h2>
                        <div class="dkuform-horizontal">
                            <div class="control-group">
                                <label for="" class="control-label">Main Instructions</label>
                                <div class="controls">
                                    <textarea formControlName="instructions" class="labeling-settings-form__instructions" placeholder="Enter instructions to help labelers annotate"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section">
                        <h2>Annotation process</h2>
                        <div class="dkuform-horizontal">
                            <div class="control-group">
                                <label for="" class="control-label">Number of labelers per image</label>
                                <div class="controls">
                                    <input type="number" formControlName="minNbAnnotatorsPerRecord" class="labeling-settings-form__one-digit-input"/>
                                    <div class="help-inline">Each image should be annotated by a specific number of labelers</div>
                                </div>
                            </div>
                        </div>
                        <div class="dkuform-horizontal">
                            <div class="control-group" *ngIf="labelingTask.type === LabelingTaskType.OBJECT_DETECTION">
                                <label for="" class="control-label">IoU threshold</label>
                                <div class="controls">
                                    <input type="number" step="0.1" min="0" max="1" formControlName="objectDetectionIOUConflictThreshold" class="labeling-settings-form__one-digit-input"/>
                                    <div class="help-inline">If two annotators label an object and their bounding box have a lower IoU (Intersection over Union) than this threshold, they will be considered as conflicting</div>
                                </div>
                            </div>
                        </div>
                        <div class="control-group">
                            <label for="autoValidateAnswers" class="control-label">Auto validation</label>
                            <div class="controls">
                                <input id="autoValidateAnswers" type="checkbox" formControlName="autoValidateAnswers"/>
                                <div class="help-inline">Automatically validate answers done by reviewers</div>
                            </div>
                        </div>
                    </div>
                </sidebar-nav-item>
                <sidebar-nav-item label="Permissions" id="permissions">
                    <labeling-error></labeling-error>
                    <labeling-permissions [labelingTask]="labelingTask" (partialTaskChange)="partialTaskChange.emit($event)"></labeling-permissions>
                </sidebar-nav-item>
            </sidebar-nav>
        </form>
    </div>
</div>
