import { Injectable } from "@angular/core";
import { createCustomArtifact, PainterImage } from '@shared/models/painter';
import { PainterService } from "@shared/services/image-feed/painter.service";
import { LabelingReviewCellData, ReviewStatus } from "../../services/labeling-review-data-fetcher.service";

@Injectable()
export class LabelingReviewImagePainterService extends PainterService {
    paintForFeed(cellData: LabelingReviewCellData, image: PainterImage): void {
        let color, icon;

        switch (cellData.status) {
            case ReviewStatus.VALIDATED:
                color = '#4CAF50';
                icon = 'icon-dku-label-validate';
                break;

            case ReviewStatus.CONFLICTING:
                color = '#F28C37';
                icon = 'icon-dku-label-conflict';
                break;

            case ReviewStatus.CONSENSUS:
                color = '#3B99FC';
                icon = 'icon-dku-label-review';
                break;
        }
        
        const box = createCustomArtifact({
            backgroundColor: color,
            color: '#fff',
            fontSize: '16px',
            top: '8px',
            right: '8px',
            borderRadius: '20px',
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        });
        box.icon = icon;
        image.addArtifact(box);
    }
}
