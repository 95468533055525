import { isNumber } from 'lodash';
import { ChartVariant } from '@model-main/pivot/frontend/model/chart-variant';
import { BarSeriesOption, EChartsOption, GridComponentOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { ChartAxisTypes, ChartModes } from '../../../../enums';
import { EChartAxis } from '../../../echarts';
import { BarsEChartDef } from '../bars-echart-def.model';

export abstract class VerticalBarsEChartDef extends BarsEChartDef {

    constructor(
        protected chartStoreFactory: any,
        protected chartFormattingService: any,
        protected chartLabelsService: any,
        protected chartDimensionService: any,
        protected echartsAxesService: any
    ) {
        super(chartStoreFactory, chartFormattingService, chartLabelsService, echartsAxesService);
    }

    /*
     *  Mandatory to avoid bars overflow on left/right side of the chart
     *  https://app.shortcut.com/dataiku/story/81990/fix-binning-none-use-raw-values-when-displaying-a-numeric-value-in-x-axis
     */
    protected addSafeguards(series: Array<BarSeriesOption>, xAxes: Array<XAXisComponentOption>): Array<BarSeriesOption> {
        let updatedSeries = series;
        if (isNumber(xAxes[0].min) && isNumber(xAxes[0].max)) {
            let interval = (xAxes[0] as any).interval;
            if (xAxes[0].min === xAxes[0].max) {
                interval = 1;
                xAxes[0].min = xAxes[0].min - 1;
                xAxes[0].max = xAxes[0].max + 1;
            }
            const safeguardMinValue = xAxes[0].min - interval;
            const safeguardMaxValue = xAxes[0].max + interval;

            updatedSeries = series.map((serie) => {
                if (serie.data && serie.data.length) {
                    serie.data = serie.data.reduce((acc: any, item: any) => {
                        if (item[0] !== safeguardMinValue && item[0] !== safeguardMaxValue) {
                            acc.push(item);
                        }
                        return acc;
                    }, []);
                    serie.data!.push([safeguardMinValue, null as any]);
                    serie.data!.push([safeguardMaxValue, null as any]);
                }
                return serie;
            });
        }

        return updatedSeries;
    }

    //  Sets an additional interval on time axis at the left and right sides to add spacing between y axes and bars
    protected addIntervalToTimeAxes(xAxes: Array<XAXisComponentOption>): Array<XAXisComponentOption> {
        return xAxes.map((xAxis) => {
            if (xAxis.type === 'time' && isNumber(xAxis.min) && isNumber(xAxis.max)) {
                const range = xAxis.max - xAxis.min;
                const gap = (range * 3) / 100;
                xAxis.min = xAxis.min - gap;
                xAxis.max = xAxis.max + gap;
                //  We remove 6% of available axis width, so we need 6% less splits
                (xAxis as any).maxInterval = Math.floor((xAxis as any).maxInterval + ((xAxis as any).maxInterval * 6) / 100);
            }

            return xAxis;
        });
    }

    protected buildAxesSpecs(
        measures: Array<any>,
        variant: string,
        xCustomExtent: any,
        yCustomExtent: any,
        xDimension: any,
        colorDimension: any,
        isLogScale: boolean,
        matrixPoints: Array<any>,
        stacked = false
    ): any {
        //  If one measure belongs to the same axis and is not a percent scale, we set a normal scale
        const isPercentScale = this.chartDimensionService.isPercentScale(measures) || variant === ChartVariant.stacked_100;

        let defaultYDomain: [number, number];

        if (stacked) {
            const stacks: Record<string, Record<number, number>> = this.stackAxisValues(EChartAxis.X, matrixPoints);
            const defaultYMax = Object.values(stacks).reduce((acc, stack) => {
                return Math.max(acc, Math.max(...Object.values(stack)));
            }, -Infinity);
            // Hack: by design bar charts start at 0, but if log scale is checked start at 1 (to make it possible)
            defaultYDomain = [isLogScale ? 1 : 0, defaultYMax];
        }

        if (variant === ChartVariant.stacked_100) {
            defaultYDomain = [0, 1];
        }

        const ySpecs = measures.reduce((acc, measure, measureIndex) => {
            const axis = measure.displayAxis === 'axis1' ? 'y1' : 'y2';

            if (!acc[axis]) {
                acc[axis] = {
                    type: ChartAxisTypes.MEASURE,
                    domain: [Infinity, -Infinity],
                    isPercentScale,
                    customExtent: yCustomExtent
                };
            }

            if (defaultYDomain) {
                acc[axis].domain = defaultYDomain;
            } else {
                const measureExtent = matrixPoints.reduce(
                    (range, point) => {
                        if (point.coord.measure === range.measureIndex) {
                            range.min = Math.min(range.min, point.value);
                            range.max = Math.max(range.max, point.value);
                        }

                        return range;
                    },
                    { measureIndex, min: acc[axis].domain[0], max: acc[axis].domain[1] }
                );

                acc[axis].domain[0] = measureExtent.min;
                acc[axis].domain[1] = measureExtent.max;
            }

            return acc;
        }, {});

        return {
            xSpec: {
                type: ChartAxisTypes.DIMENSION,
                mode: ChartModes.COLUMNS,
                dimension: xDimension,
                name: 'x',
                customExtent: xCustomExtent
            },
            ySpec: ySpecs.y1,
            y2Spec: ySpecs.y2,
            colorSpec: {
                type: ChartAxisTypes.DIMENSION,
                name: 'color',
                dimension: colorDimension
            }
        };
    }

    protected buildOptions(xAxes: Array<XAXisComponentOption>, yAxes: Array<YAXisComponentOption>, series: Array<BarSeriesOption>, grid: GridComponentOption): EChartsOption {
        xAxes = this.addIntervalToTimeAxes(xAxes);

        const options = {
            grid,
            xAxis: xAxes,
            yAxis: yAxes,
            series
        };

        return options;
    }
}
