import { Component, ChangeDetectionStrategy, OnChanges, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Workspace } from '@model-main/workspaces/workspace';
import { ITaggingService } from '@model-main/server/services/itagging-service';
import { WorkspaceObjectType, WorkspaceDisplayService, WorkspaceNonTaggableType } from '../../../../shared';

enum View {
    Favicon = 'favicon',
    Image = 'image',
    Description = 'description',
    DatasetIcon = 'dataset-icon',
    Icon = 'icon',
}

@Component({
    selector: 'workspace-object-thumbnail',
    templateUrl: './workspace-object-thumbnail.component.html',
    styleUrls: ['./workspace-object-thumbnail.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceObjectThumbnailComponent implements OnChanges {
    @Input() object: Workspace.WorkspaceObject | null;

    readonly View = View;

    type: WorkspaceObjectType;
    view: View;
    safeImage: SafeUrl;


    constructor(
        private sanitizer: DomSanitizer,
        private workspaceDisplayService: WorkspaceDisplayService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.object && this.object) {
            this.type = this.workspaceDisplayService.getObjectType(this.object);
            this.view = this.getViewType(this.object, this.type);

            if (this.view === View.Image) {
                const url = this.object.appId ? this.workspaceDisplayService.getAppImageURL(this.object.appId) : this.object.thumbnailData;
                this.safeImage = this.sanitizer.bypassSecurityTrustUrl(url);
            }
        }
    }

    getViewType(object: Workspace.WorkspaceObject, type: WorkspaceObjectType): View {
        if (type === WorkspaceNonTaggableType.LINK) {
            return View.Favicon;
        } else if (object.thumbnailData || object.appId) {
            return View.Image;
        } else if (object.shortDesc) {
            return View.Description;
        } else if (type === ITaggingService.TaggableType.DATASET) {
            return View.DatasetIcon;
        }
        return View.Icon;
    }
}
