import { ChartVariant } from '@model-main/pivot/frontend/model/chart-variant';
import { EChartsOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { isNumber } from 'lodash';
import { ChartAxisTypes, ChartSortTypes } from '../../../enums';
import { ChartCoordinates, FrontendChartDef, FrontendMeasureDef } from '../../../interfaces';
import { EChartsAxesService } from '../echarts-axes.service';
import { EChartAxisTypes, EChartLabelPosition, EChartBarLabelPosition, EChartAxis } from '../enums';
import { EChartMatrixPoint, EChartOptionsContext, EChartSeriesContext, Cartesian2DSeries, EChartDrawContext } from '../interfaces';
import { EChartDef } from './echart-def.model';
import { EChartPrepareDataContext } from './echart-prepare-data-context.model';

export abstract class Cartesian2DEChartDef extends EChartDef {
    mainAxis: EChartAxis = EChartAxis.X;

    constructor(
        protected chartStoreFactory: any,
        protected chartFormattingService: any,
        protected chartLabelsService: any,
        protected echartsAxesService: EChartsAxesService
    ) {
        super();
    }

    protected getSeriesId(coord: ChartCoordinates): string {
        const frameIndex = coord.animation;
        const facetIndex = coord.facet;
        const colorIndex = coord.color;
        const measureIndex = coord.measure;
        const coordinatesId = `animation${frameIndex}-facet${facetIndex}-color${colorIndex}-measure${measureIndex}`;
        return coordinatesId;
    }

    protected buildOneSeries(
        seriesId: string,
        chartDef: FrontendChartDef,
        measure: FrontendMeasureDef,
        extent: any,
        labelsResolution: any,
        color: any,
        colorLabel: string,
        labelPosition: EChartLabelPosition | EChartBarLabelPosition,
        formatter: (params: any, value: any) => string
    ): Cartesian2DSeries {
        const builtSeries: Cartesian2DSeries = {
            id: seriesId,
            name: colorLabel || this.chartLabelsService.getLongMeasureLabel(measure), //  We use the color label if a color dimension is defined or else we get the measure label
            itemStyle: {
                opacity: chartDef.colorOptions.transparency
            },
            animationDuration: 300,
            data: [],
            color
        };

        if (chartDef.showInChartValues) {
            const isPercent = this.isMeasureComputedInPercentage(measure) || chartDef.variant === ChartVariant.stacked_100;
            const formatValue = this.chartFormattingService.createNumberFormatter(measure, extent, labelsResolution, isPercent);

            builtSeries.label = {
                show: true,
                position: labelPosition,
                fontFamily: 'SourceSansPro',
                color: '#333',
                fontSize: 12,
                formatter: (params: any) => {
                    return formatter(params, formatValue);
                }
            };

            builtSeries.labelLayout = {
                hideOverlap: true
            };
        }

        return builtSeries;
    }

    protected getAxisValue(axisValues: any, axisType: string): string | number {
        let axisValue: string | number = axisValues.label;

        switch (axisType) {
            case EChartAxisTypes.VALUE:
            case EChartAxisTypes.LOG:
                axisValue = axisValues.sortValue;
                break;
            case EChartAxisTypes.TIME:
                axisValue = axisValues.tsValue;
                break;
        }

        return axisValue;
    }

    protected abstract getAxesSpecs(chartDef: FrontendChartDef, matrixPoints: Array<EChartMatrixPoint>): any;

    protected abstract getSeries(seriesContext: EChartSeriesContext): Array<Cartesian2DSeries>;

    protected abstract getOptions(optionsContext: EChartOptionsContext): EChartsOption;

    /**
     * Used to retrieve each point value on x or y axis for sorting
     * @param {number} measureIndex
     * @param {echarts series} series
     * @param {boolean} invert default: x, else: y
     */
    private getWeights(measureIndex: number, series: any, invert: any) {
        return series.reduce((acc: any, serie: any) => {
            if (serie.id && serie.id.includes(`m${measureIndex}`)) {
                serie.data.forEach((point: any) => {
                    if (!acc[point[invert ? 1 : 0]]) {
                        acc[point[invert ? 1 : 0]] = point[invert ? 0 : 1];
                    } else {
                        acc[point[invert ? 1 : 0]] += point[invert ? 0 : 1];
                    }
                });
            }

            return acc;
        }, {});
    }

    /**
     * Used to sort axes by natural order (alphanumerical) or by measure value (asc or desc)
     * @param {echarts options} options
     */
    private sort(specs: Array<any>, options: EChartsOption) {
        const updatedOptions = { ...options };

        const dimensionSpec = Object.values(specs).find((spec) => spec.type === ChartAxisTypes.DIMENSION);

        let axes: Array<XAXisComponentOption> | Array<YAXisComponentOption> = options.xAxis as Array<XAXisComponentOption> | undefined || [];

        if (dimensionSpec.name === 'y') {
            axes = options.yAxis as Array<YAXisComponentOption> | undefined || [];
        }

        const sort = dimensionSpec && dimensionSpec.dimension && dimensionSpec.dimension.sort;
        const weights = this.getWeights(sort.measureIdx, options.series, dimensionSpec.name === 'y');

        axes.forEach((axis) => {
            if (axis.type === EChartAxisTypes.CATEGORY && sort && sort.type && 'data' in axis && axis.data) {
                axis.inverse = dimensionSpec.name === 'y';

                switch (sort.type) {
                    case ChartSortTypes.NATURAL:
                        axis.data = axis.data.sort((a: any, b: any) => a - b);
                        break;
                    case ChartSortTypes.AGGREGATION:
                        axis.data = axis.data.sort((a: any, b: any) => weights[a] - weights[b]);
                        break;
                }
            } else {
                axis.inverse = dimensionSpec.ascendingDown;

                /** When inverted, time y axis is not correctly computed by echarts, so we add a 5% gap at each edge of the chart */
                if (axis.type === EChartAxisTypes.TIME && dimensionSpec.name === 'y' && isNumber(axis.min) && isNumber(axis.max)) {
                    const gap = ((axis.max - axis.min) * 5) / 100;
                    axis.min = axis.min - gap;
                    axis.max = axis.max + gap;
                }
            }
        });

        return updatedOptions;
    }

    getColorSpec(chartDef: FrontendChartDef) {
        return {
            type: 'DIMENSION',
            name: 'color',
            dimension: chartDef.genericDimension1[0]
        };
    }

    draw(drawContext: EChartDrawContext): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        let gridOptions = drawContext.chartBase.margins;

        const xAxes = [];
        const yAxes = [];

        const prepareDataContext: EChartPrepareDataContext = {
            axis: this.mainAxis,
            chartDef: drawContext.chartDef,
            chartData: drawContext.chartData,
            legends: drawContext.legends,
            colorScale: drawContext.chartBase.colorScale,
            mapper: value => this.mapValue(value)
        };

        const chartPoints = this.prepareData(prepareDataContext);

        const specs = this.getAxesSpecs(drawContext.chartDef, chartPoints);

        this.chartStoreFactory.get(drawContext.chartId).setAxisSpecs(
            { xSpec: specs.xSpec, ySpec: specs.ySpec, y2Spec: specs.y2Spec },
            drawContext.frameIndex,
            drawContext.facetIndex
        );

        const axes = this.echartsAxesService.getAxesAndMargins(
            drawContext.chartData,
            drawContext.chartDef,
            drawContext.chartBase.width,
            drawContext.chartBase.height,
            drawContext.chartBase.margins,
            specs,
            drawContext.noXAxis,
            drawContext.noYAxis
        );

        axes.xAxisOptions && xAxes.push(axes.xAxisOptions);
        axes.yAxisOptions && yAxes.push(axes.yAxisOptions);
        axes.y2AxisOptions && yAxes.push(axes.y2AxisOptions);

        // Hide split lines if we already have the ones of the left y axis
        if (yAxes[1]) {
            yAxes[1].splitLine = { show: false };
        }

        gridOptions = axes.gridOptions;

        const chartWidth = drawContext.chartBase.width - (gridOptions.left || 0) - (gridOptions.right || 0);
        const chartHeight = drawContext.chartBase.height - (gridOptions.top || 0) - (gridOptions.bottom || 0);

        const seriesContext: EChartSeriesContext = {
            chartDef: drawContext.chartDef,
            chartData: drawContext.chartData,
            chartWidth,
            chartHeight,
            colorScale: drawContext.chartBase.colorScale,
            chartPoints,
            xAxes,
            yAxes,
            frameIndex: drawContext.frameIndex || 0
        };

        const series = this.getSeries(seriesContext);

        const optionsContext: EChartOptionsContext = {
            xAxes,
            yAxes,
            series,
            gridOptions
        };
        
        let options = this.getOptions(optionsContext);

        if (specs) {
            options = this.sort(specs, options);
        }

        const allCoords = this.getAllCoords(chartPoints);

        return {
            options,
            allCoords
        };
    }
}
