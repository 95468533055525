import { Inject, Injectable } from '@angular/core';
import { NiceConstService, ProjectInitialsService } from '@shared/services';
import { ITaggingService } from '@model-main/server/services/itagging-service';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceNonTaggableType, WorkspaceObjectType } from '../models';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceDisplayService {
    constructor(
        @Inject('StateUtils') private readonly StateUtils: any,
        @Inject('TypeMappingService') private readonly typeMappingService: any,
        private readonly niceConstService: NiceConstService,
        private readonly projectInitialsService: ProjectInitialsService
    ) {
    }

    getObjectLink(workspaceKey: string, object: Workspace.WorkspaceObject): { href: string; target: string } | undefined {
        if (object.htmlLink) {
            return { href: object.htmlLink.url, target: '_blank' };
        } else if (object.appId) {
            return { href: this.StateUtils.href.workspaceApp(workspaceKey, object.appId), target: '' };
        } else if (object.reference) {
            return {
                href: this.StateUtils.href.workspaceObject(
                    workspaceKey,
                    object.reference.projectKey,
                    object.reference.type,
                    object.reference.id
                ),
                target: ''
            };
        }
        return undefined;
    }

    getSourceObjectLink(object: Workspace.WorkspaceObject): string {
        if (object.appId) {
            return this.StateUtils.href.app(object.appId);
        } else if (object.reference) {
            switch (object.reference.type) {
                case ITaggingService.TaggableType.DASHBOARD:
                    return this.StateUtils.href.dashboard(object.reference.id, object.reference.projectKey, {
                        name: object.displayName
                    });
                case ITaggingService.TaggableType.DATASET:
                    return this.StateUtils.href.dataset(object.reference.id, object.reference.projectKey, {
                        name: object.displayName
                    });
                case ITaggingService.TaggableType.WEB_APP:
                    return this.StateUtils.href.webapp(object.reference.id, object.reference.projectKey, {
                        name: object.displayName
                    });
                case ITaggingService.TaggableType.ARTICLE:
                    return this.StateUtils.href.article(object.reference.id, object.reference.projectKey, {
                        name: object.displayName
                    });
                default:
                    return '';
            }
        }
        return '';
    }

    getObjectType(object: Workspace.WorkspaceObject): WorkspaceObjectType {
        if (object.reference) {
            return object.reference.type;
        } else if (object.htmlLink) {
            return WorkspaceNonTaggableType.LINK;
        } else if (object.appId) {
            return WorkspaceNonTaggableType.APP;
        }
        return WorkspaceNonTaggableType.UNKNOWN;
    }

    getObjectTypeUI(object: Workspace.WorkspaceObject): string {
        return this.niceConstService.transform(this.getObjectType(object));
    }

    getObjectColor(object: Workspace.WorkspaceObject, background: boolean): string {
        if (object.htmlLink) {
            return background ? 'universe-background' : 'universe-color';
        } else if (object.appId) {
            return this.typeMappingService.mapTypeToColor('app', background);
        }
        return this.typeMappingService.mapTypeToColor(object.reference?.type, background);
    }

    getWorkspaceInitials(workspace: Workspace): string {
        return this.projectInitialsService.getProjectInitials(workspace.displayName || 'Workspace');
    }

    getAppImageURL(appId: string): string {
        return `/dip/api/apps/get-template-image?appId=${encodeURIComponent(appId)}`;
    }

    transformTimeline(timeline?: { items: Array<{ objectType: WorkspaceObjectType }> }) {
        if (!timeline) {
            return timeline;
        }

        return {
            ...timeline, items: timeline.items.map(item => {
                if (item.objectType === 'PROJECT') {
                    return { ...item, objectType: WorkspaceNonTaggableType.APP };
                }
                return item;
            })
        };
    }

    shouldOpenExternally(object: Workspace.WorkspaceObject): boolean {
        return object.htmlLink !== undefined;
    }
}
