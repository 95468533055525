<form [formGroup]="configForm" id="config-form" class="dkuform-modal-horizontal">

    <mat-tab-group
        mat-align-tabs="center"
        mat-stretch-tabs
        animationDuration="0ms"
        qa="ts-config-tabs"
    >
        <mat-tab label="Settings">
            <div class="control-group" qa="ts-series-variable">
                <label class="control-label">Series variable</label>
                <variable-selector
                    [variables]="seriesVariables$ | async"
                    formControlName="seriesColumn"
                ></variable-selector>
            </div>

            <div class="control-group" qa="ts-time-variable">
                <label class="control-label">
                    Time variable
                    <i class="icon-info-sign mleft4" matTooltip="The time variable must hold ISO8601 formatted date times"></i>
                </label>
                <variable-selector
                    [variables]="timeVariables$ | async"
                    formControlName="timeColumn"
                ></variable-selector>
            </div>

            <ng-container [ngSwitch]="params.type">
                <ng-container *ngSwitchCase="'unit_root_test_adf'" formGroupName="adfOptions">
                    <div class="control-group">
                        <label class="control-label">Regression mode</label>
                        <basic-select
                            formControlName="regressionMode"
                            [items]="adfRegressionOptions"
                            bindLabel="name"
                            bindValue="value">
                        </basic-select>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'unit_root_test_za'" formGroupName="zaOptions">
                    <div class="control-group">
                        <label class="control-label">Regression mode</label>
                        <basic-select
                            formControlName="regressionMode"
                            [items]="zaRegressionOptions"
                            bindLabel="name"
                            bindValue="value">
                        </basic-select>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'unit_root_test_kpss'" formGroupName="kpssOptions">
                    <div class="control-group">
                        <label class="control-label">Regression mode</label>
                        <basic-select
                            formControlName="regressionMode"
                            [items]="kpssRegressionOptions"
                            bindLabel="name"
                            bindValue="value">
                        </basic-select>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'acf_plot'" formGroupName="acfPlotOptions">
                    <div class="control-group">
                        <label class="control-label">Show series summary</label>
                        <input type="checkbox" class="control" formControlName="showSummary" />
                    </div>

                    <div class="control-group">
                        <label class="control-label">Compute partial auto-correlation</label>
                        <input type="checkbox" class="control" formControlName="isPartial" />
                    </div>

                    <div class="control-group" *ngIf="isPartialACF">
                        <label class="control-label">Method</label>
                        <basic-select
                            formControlName="pacfMethod"
                            [items]="pacfMethodOptions"
                            bindLabel="name"
                            bindValue="value">
                        </basic-select>
                    </div>

                    <div class="control-group" *ngIf="!isPartialACF">
                        <label class="control-label">Adjust for the loss of data</label>
                        <input type="checkbox" class="control" formControlName="adjusted" />
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="isAutoComputeLagsEnabled">
                <div class="control-group">
                    <label class="control-label">Automatically compute the lags</label>
                    <input type="checkbox" class="control" formControlName="autoComputeLags" />
                </div>

                <div class="control-group" *ngIf="!autoComputeLags">
                    <label class="control-label">Number of lags</label>
                    <input type="number" class="control" formControlName="nLags" min="1" />
                </div>
            </ng-container>
        </mat-tab>

        <mat-tab label="Multiple series">
            <div class="control-group" qa="ts-use-long-format">
                <label class="control-label">Data has multiple series (long format)</label>
                <input type="checkbox" class="control" formControlName="useLongFormat" />
            </div>

            <ng-container *ngIf="useLongFormat">
                <div class="control-group" qa="ts-series-ids">
                    <label class="control-label">Series identifiers</label>
                    <variable-selector
                        [variables]="identifierVariables$ | async"
                        formControlName="identifierColumns"
                        [multiple]="true"
                    ></variable-selector>
                </div>

                <div
                    *ngFor="let identifier of selectedIdentifiers"
                    class="control-group"
                    [qa]="{ 'ts-series-id': { 'name': identifier.name } }"
                >
                    <label class="control-label identifier-name">
                        Filter values for
                        <u [matTooltip]="identifier.name">{{ identifier.name }}</u>
                    </label>
                    <ng-select
                        class="values-selector"
                        [formControl]="getIdentifierValuesControl(identifier)"
                        [items]="getSuggestions(identifier)"
                        [loading]="isLoadingSuggestions(identifier)"
                        [multiple]="true"
                        [virtualScroll]="true"
                        [clearable]="false"
                        [searchable]="false"
                        [closeOnSelect]="false"
                        placeholder="Use all values"
                        loadingText="Loading most common values..."
                        appendTo="body"
                    >
                        <ng-template ng-header-tmp *ngIf="!isLoadingSuggestions(identifier)">
                            <ng-select-search-input></ng-select-search-input>
                        </ng-template>

                        <ng-template ng-loadingspinner-tmp>
                            <i class="icon-spin icon-spinner"></i>
                        </ng-template>

                        <ng-template ng-multi-label-tmp let-items="items">
                            <!-- template to disaply when multiple values are selected -->
                            <ng-container *ngIf="items.length > 1">
                                {{ items.length }} values selected
                            </ng-container>
                            <ng-container *ngIf="items.length === 1">
                                {{ items[0] }}
                            </ng-container>
                        </ng-template>

                        <ng-template ng-footer-tmp *ngIf="!isLoadingSuggestions(identifier)">
                            <div class="use-all-values-container">
                                <button
                                    class="btn btn-small btn--text btn--secondary"
                                    (click.stop)="useAllValues(identifier)"
                                    [disabled]="hasNoSelectedValues(identifier)"
                                >
                                    Clear (Use all values)
                                </button>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </ng-container>
        </mat-tab>

        <mat-tab label="Resampling">
            <div class="control-group" qa="ts-use-resampling">
                <label class="control-label">Resample the series</label>
                <input type="checkbox" class="control" formControlName="useResampling" />
            </div>

            <ng-container *ngIf="useResampling" formGroupName="resamplerSettings">
                <div class="control-group" qa="ts-resampling-time-unit">
                    <label class="control-label">Time unit</label>
                    <basic-select
                        [items]="timeUnitOptions"
                        bindLabel="name"
                        bindValue="value"
                        formControlName="timeUnit">
                    </basic-select>
                </div>

                <div class="control-group" *ngIf="hasWeeklyResampling">
                    <label class="control-label">End of week</label>
                    <basic-select
                        [items]="dayOfWeekOptions"
                        bindLabel="name"
                        bindValue="value"
                        formControlName="timeUnitEndOfWeek">
                    </basic-select>
                </div>

                <div class="control-group" qa="ts-resampling-n-units">
                    <label class="control-label">Number of units</label>
                    <input type="number" class="control" formControlName="nUnits" min="1" step="1">
                </div>

                <div class="control-group" qa="ts-resampling-interpolation">
                    <label class="control-label">
                        Interpolation method
                        <i class="icon-info-sign mleft4"
                            matTooltip="Method to infer numerical values for missing timestamps that do not begin or end the series"
                        ></i>
                    </label>
                    <basic-select
                        [items]="interpolationOptions"
                        bindLabel="name"
                        bindValue="value"
                        formControlName="interpolationMethod">
                    </basic-select>
                </div>

                <div class="control-group" *ngIf="hasConstantInterpolation">
                    <label class="control-label">Value for interpolation</label>
                    <input type="number" class="control" formControlName="interpolationConstantValue">
                </div>

                <div class="control-group" qa="ts-resampling-extrapolation">
                    <label class="control-label">
                        Extrapolation method
                        <i class="icon-info-sign mleft4"
                            matTooltip="Method to infer numerical values for missing timestamps located before the first (or after the last) available timestamp, e.g. when the data contains several series of different sizes"
                        ></i>
                    </label>
                    <basic-select
                        [items]="extrapolationOptions"
                        bindLabel="name"
                        bindValue="value"
                        formControlName="extrapolationMethod">
                    </basic-select>
                </div>

                <div class="control-group" *ngIf="hasConstantExtrapolation">
                    <label class="control-label">Value for extrapolation</label>
                    <input type="number" class="control" formControlName="extrapolationConstantValue">
                </div>

                <div class="control-group">
                    <label class="control-label">
                        Duplicate timestamps handling
                        <i class="icon-info-sign mleft4"
                            matTooltip="Method to handle duplicate timestamps in the series"
                        ></i>
                    </label>
                    <basic-select
                        [items]="duplicateTimestampsHandlingOptions"
                        bindLabel="name"
                        bindValue="value"
                        formControlName="duplicateTimestampsHandlingMethod">
                    </basic-select>
                </div>
            </ng-container>
        </mat-tab>
    </mat-tab-group>

    <api-error-alert
        [apiError]="error"
        (resetError)="pushError(null)"
        [closable]="true"
    ></api-error-alert>
</form>
