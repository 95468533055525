import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NiceConstService {
    transform(input: string): string {
        if (!input || !input.length) {
            return '';
        }
        input = input.replace(/[\s_]+|([a-z])(?=[A-Z])/g, '$1 ').trim();
        let nice = input.charAt(0).toUpperCase() + input.substring(1).toLowerCase();
        if (nice == 'Lambda service') {
            nice = 'API service';
        }
        if (nice == 'Sql notebook') {
            nice = 'SQL notebook';
        }
        return nice;
    }
}
