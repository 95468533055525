import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { iso8601Duration } from "@features/eda/pipes/iso8601-duration.pipe";
import { ResamplerSettings, TimeSeriesCard } from "src/generated-sources";

const { TimeUnit } = ResamplerSettings;

const timeUnitLabels = {
    [TimeUnit.MILLISECOND]: 'millisecond',
    [TimeUnit.SECOND]: 'second',
    [TimeUnit.MINUTE]: 'minute',
    [TimeUnit.HOUR]: 'hour',
    [TimeUnit.DAY]: 'day',
    [TimeUnit.WEEK]: 'week',
    [TimeUnit.BUSINESS_DAY]: 'business day',
    [TimeUnit.MONTH]: 'month',
    [TimeUnit.QUARTER]: 'quarter',
    [TimeUnit.HALF_YEAR]: 'half year',
    [TimeUnit.YEAR]: 'year',
};

@Component({
    selector: 'time-series-summary',
    templateUrl: './time-series-summary.component.html',
    styleUrls: [
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './time-series-summary.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeSeriesSummaryComponent {
    @Input() params: TimeSeriesCard;
    @Input() results: TimeSeriesCard.TimeSeriesCardResult;

    get seriesColumnName(): string {
        return this.params.seriesColumn.name;
    }

    get timeColumnName(): string {
        return this.params.timeColumn.name;
    }

    get hasResampling(): boolean {
        return this.params.resamplerSettings != null;
    }

    get resamplingTimeStep(): string {
        const { nUnits, timeUnit } = this.params.resamplerSettings!;
        const label = timeUnitLabels[timeUnit];
        return nUnits > 1 ? `${nUnits} ${label}s` : `1 ${label}`;
    }

    get minTimestamp(): string | undefined {
        return this.results.timeInfo.min?.replace('+00:00', 'Z');
    }

    get maxTimestamp(): string | undefined {
        return this.results.timeInfo.max?.replace('+00:00', 'Z');
    }

    get nTimeSteps(): number {
        return this.results.timeInfo.stepInfo?.nSteps ?? 0;
    }

    get timeStepsLabel(): string {
        const { stepInfo } = this.results.timeInfo;
        if (stepInfo == null || stepInfo.steps.length === 0) {
            return "None";
        }

        return stepInfo.steps.map(info => {
            switch (info.type) {
                case 'business_days_step':
                    return this.formatBusinessDays(info.weekMask);
                case 'iso_duration_step':
                    return iso8601Duration(info.duration);
            }
        }).join(', ');
    }

    private formatBusinessDays(weekMask: string): string {
        const days: { [key: string]: string } = {
            "Mon": "Monday",
            "Tue": "Tuesday",
            "Wed": "Wednesday",
            "Thu": "Thursday",
            "Fri": "Friday",
            "Sat": "Saturday",
            "Sun": "Sunday",
        };

        const all_days = weekMask.split(" ").map(it => days[it]);
        const last_day = all_days.pop();
        return `Every ${all_days.join(', ')} and ${last_day}`;
    }

    get totalTimeStepsTooltip() {
        const { stepInfo } = this.results.timeInfo;
        if (stepInfo == null) {
            return '';
        }

        if (stepInfo.nSteps > stepInfo.steps.length) {
            return `A total of ${stepInfo.nSteps} time steps were detected`;
        }

        return '';
    }

    get showTimeStepsEllipsis(): boolean {
        const { stepInfo } = this.results.timeInfo;
        return stepInfo != null && stepInfo.nSteps > stepInfo.steps.length;
    }
}
