<ng-select
    [items]="groupedUiItems || ungroupedItems"
    [bindLabel]="'label'"
    [bindValue]="'value'"
    [searchable]="false"
    [placeholder]="placeholder || 'Nothing selected'"
    [virtualScroll]="true"
    class="ng-select--without-ng-option-padding ng-select--without-ng-option-disabled-opacity"
    [clearable]="false"
    [searchFn]="searchFn"
    [trackByFn]="trackByFn"
    [groupBy]="groupedUiItems ? 'items' : ''"
    [compareWith]="compareWith"
    [bufferAmount]="30"
    appendTo="body"
    [ngModel]="selectedValue"
    (ngModelChange)="modelChanged($event)">
    <ng-template ng-header-tmp>
        <ng-select-search-input *ngIf="searchable"></ng-select-search-input>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item">
        <!-- Weird: <ng-select> puts the group name in a property named after [bindLabel] -->
        <div class="group">{{ item.label }}</div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <div *ngIf="item.itemType === 'regular'" class="item" [class.item--grouped]="!!groupedUiItems">{{ item.label }}</div>
        <div class="separator" *ngIf="item.itemType === 'separator'"><hr></div>
    </ng-template>
    <ng-template ng-notfound-tmp>
        <div class="item">No items found</div>
    </ng-template>
</ng-select>
<!--
    [bufferAmount] was set to a value higher than default (=4) in order to work around a known bug of <ng-select>'s virtual scroll
    See https://github.com/ng-select/ng-select/issues/1994
-->
